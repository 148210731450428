import { get, post, put } from "../../network/apiService";
import { apiUrlHost } from "../servicerOnboarding/servicerOnboarding.action";

export const getLoanInfoForPL = (loanId: string) => {
  const url = `${apiUrlHost}/purchasedloans/aggregate/loanInfo?toorakLoanId=${loanId}`;
  return get(url);
};

export const updatePurchaseFlags = (requestBody: any) => {
  const url = `${apiUrlHost}/drawservice/assetmanagement/document/flag`;
  return put(url, requestBody);
};

export const updateDrawDocsTags = (documentId: string, requestBody: any[]) => {
  const url = `${apiUrlHost}/drawservice/assetmanagement/document/${documentId}/tags`;
  return put(url, requestBody);
};

export const updateDrawDocsAssets = (requestBody: any[],documentId:any) => {
  let url = `${apiUrlHost}/drawservice/assetmanagement/document/assets`;
  if (!requestBody ||  requestBody.length === 0){
    url = url + `?documentId=${documentId}`;
  }
  return put(url, requestBody);
};

export const updateDocsReviewStatus = (requestBody: any) => {
  const url = `${apiUrlHost}/purchasedloans/updateStatus`;
  return post(url, requestBody);
};

export const transferDocs = (documentId: string, requestBody: any) => {
  const url = `${apiUrlHost}/documentdetails/${documentId}/transfer`;
  return put(url, requestBody);
};

export const importDocs = (toorakLoanId: string, requestBody: any) => {
  const url = `${apiUrlHost}/purchasedloans/loan/${toorakLoanId}/import`;
  return put(url, requestBody);
};

export const startTaggingDocs = (toorakLoanId: string) => {
  const url = `${apiUrlHost}/purchasedloans/loan/${toorakLoanId}/tag`;
  return put(url);
};
