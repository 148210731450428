import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { ObjectType } from "../../masterView/common";
import { labelFieldMap } from "./common-guideline";

export const labelFieldName = (infoContentPath: string): labelFieldMap[] => [
  {
    label: "Max Initial Loan Proceeds LTV (As Is)",
    field: "maxInitialLoanProceedsLTV",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
    infoContent: `${infoContentPath}.maxInitialLoanProceedsLTV`
  },
  {
    label: "Max Proceeds ARV",
    field: "maxProceedsARV",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
    infoContent: `${infoContentPath}.maxProceedsARV`
  },
  {
    label: "Max Initial Loan Proceeds LTC",
    field: "maxInitialLoanProceedsLTC",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
    infoContent: `${infoContentPath}.maxInitialLoanProceedsLTC`
  },
  {
    label: "Initial Loan Funding",
    field: "initialLoanFunding",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
    infoContent: `${infoContentPath}.initialLoanFunding`
  },
  {
    label: "Rehab Loan Amount",
    field: "rehabAmount",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
    infoContent: `${infoContentPath}.rehabAmount`
  },
  {
    label: "Max Loan Proceeds Before First Loss",
    field: "maxLoanProceedsBeforeFirstLoss",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.maxLoanProceedsBeforeFirstLoss`
  },
  {
    label: "Originator Actual Initial Loan Funding",
    field: "originatorActualInitialLoanFunding",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.originatorActualInitialLoanFunding`
  },
  {
    label: "Originator Rehab Remaining to Date",
    field: "originatorRehabRemainingtoDate",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.originatorRehabRemainingtoDate`
  },
  {
    label: "Originator Max Loan Amount",
    field: "originatorMaxLoanAmount",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.originatorMaxLoanAmount`
  },
  {
    label: "Originator Proceeds to Date",
    field: "originatorProceedstoDate",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.originatorProceedstoDate`
  },
  {
    label: "Originator Original Subsequent or Escrowed Rehab",
    field: "originatorOriginalSubsequentorEscrowedRehab",
    fieldType: "currency",
    loanStage: [LoanStage.pre, LoanStage.post],
    infoContent: `${infoContentPath}.originatorOriginalSubsequentorEscrowedRehab`
  },
  {
    label: "Total Max Loan Amount",
    field: "totalMaxLoanAmount",
    fieldType: "currency",
    loanStage: [LoanStage.fes],
    infoContent: `${infoContentPath}.totalMaxLoanAmount`
  },
  {
    label: "Rehab Status",
    field: "rehabStatus",
    infoContent: `${infoContentPath}.rehabStatus`,
    loanStage: [LoanStage.pre, LoanStage.post],
    onlyLAT: true
  }
  /**
   * removed from this section ass a part of LPP-3728
   */
  // {
  //   label: "Toorak Interest Rate",
  //   field: "regularToorakYield",
  //
  //   fieldType: "percentage",
  //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
  //   // onlyLAT: true,
  //   infoContent: `${infoContentPath}.regularToorakYield`,
  // }
  // {
  //   label: "After Adjustment Max LTC",
  //   field: "afterAdjustmentMaxLTC",
  //
  //   fieldType: "percentage",
  //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
  //   infoContent: `fieldInfo.${loanTypeMapping(loanType)}.${loanStage.toLowerCase()}.result.loanResult.toorakProceedsTest.afterAdjustmentMaxLTC`
  // },
];
export const gUpFesOutputs = (): labelFieldMap[] => [
  {
    label: "Initial Loan Funding",
    isPropertyLevel: false,
    fieldType: "currency",
    field: "initialLoanFunding",
    loanStage: [LoanStage.fes],
    infoContent: `discounts.initialLoanFunding`
  },
  {
    label: "Rehab Loan Amount",
    isPropertyLevel: false,
    fieldType: "currency",
    field: "rehabAmount",
    loanStage: [LoanStage.fes],
    infoContent: `discounts.rehabAmount`
  },
  {
    label: "Interest Reserve",
    isPropertyLevel: false,
    fieldType: "currency",
    field: "interestReserveProceeds",
    loanStage: [LoanStage.fes],
    infoContent: `discounts.interestReserveProceeds`
  },
  {
    label: "Total Max Loan Amount",
    fieldType: "currency",
    field: "totalMaxLoanAmount",
    loanStage: [LoanStage.fes],
    isPropertyLevel: false,
    infoContent: `discounts.totalMaxLoanAmount`
  }
];

export const gupCommonFields = (): labelFieldMap[] => [
  {
    fieldType: "currency",
    field: "adjustedBlendedLTC",
    label: 'Blended LTC ("As Completed")',
    isPropertyLevel: false,
    infoContent: `toorakProceedTests.adjustedBlendedLTC`,
    loanStage: [LoanStage.pre, LoanStage.post]
  },
  {
    fieldType: "currency",
    field: "adjustedBlendedLTCWithIR",
    label: 'Blended LTC with Funded IR ("As Completed")',
    isPropertyLevel: false,
    infoContent: `toorakProceedTests.adjustedBlendedLTCWithIR`,
    loanStage: [LoanStage.pre, LoanStage.post]
  }
];

export const labelFieldName30year = (version: string) => {
  const versionToFieldsMap: ObjectType = {
    RATE: [
      {
        label: "Borrower FICO Pricing Bucket",
        field: "borrowerFico",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.borrowerFico`
      },
      {
        label: "Actual Leverage - LTV/LTC/LTP",
        field: "actualLeverage",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.actualLeverage`
      },
      {
        label: "Base FICO / Leverage Adjusted Rate",
        field: "baseFicoLeverageBucket",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.baseFicoLeverageBucket`
      },
      {
        label: "Rate Adj. - Prepayment Penalty",
        field: "prePaymentPenalty",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.prePaymentPenalty`
      },
      {
        label: "Rate Adj. - Interest Only",
        field: "interestOnlyRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.interestOnlyRate`
      },
      {
        label: "Rate Adj. - DSCR",
        field: "dscrRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.dscrRate`
      },
      {
        label: "Rate Adj. - Short Term Rental",
        field: "shortTermRentalRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.shortTermRentalRate`
      },
      {
        label: "Rate Adj. - Cash Out Refinance",
        field: "cashOutRefinanceRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.cashOutRefinanceRate`
      },
      {
        label: "Rate Adj. - Property Type",
        field: "propertyTypeRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.propertyTypeRate`
      },
      {
        label: "Rate Adj. - Loan Balance",
        field: "loanBalanceRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.loanBalanceRate`
      },
      {
        label: "Rate Adj. - ARM",
        field: "amortizationRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.amortizationRate`
      },
      {
        label: "Leverage Pricing Bucket",
        field: "leveragePricingBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.leveragePricingBucket`
      },
      {
        label: "Loan Balance Bucket",
        field: "loanBalanceBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`
      }
      // {
      //   label: "After Adjustment Min Proceeds",
      //   field: "afterAdjustmentMinProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.afterAdjustmentMinProceeds`
      // },
      // {
      //   label: "Originator Proceeds",
      //   field: "originatorProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.originatorProceeds`
      // },
      // {
      //   label: "After Adjustment Cost Proceeds",
      //   field: "afterAdjustmentCostProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.afterAdjustmentCostProceeds`
      // },

      // {
      //   label: "Balance Variance",
      //   field: "balanceVariance",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.balanceVariance`
      // },
    ],
    "": [
      {
        label: "Borrower FICO Pricing Bucket",
        field: "borrowerFico",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.borrowerFico`
      },
      {
        label: "Actual Leverage - LTV/LTC/LTP",
        field: "actualLeverage",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.actualLeverage`
      },
      {
        label: "Price Adj. - FICO",
        field: "ficoPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.ficoPriceAdjustment`
      },
      {
        label: "Price Adj. - Pre-payment Penalty Term",
        field: "penaltyTermPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.penaltyTermPriceAdjustment`
      },
      {
        label: "Price Adj. - Loan Exceptions",
        field: "loanExceptionsPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanExceptionsPriceAdjustment`
      },
      {
        label: "Price Adj. - DSCR",
        field: "dscrPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.dscrPriceAdjustment`
      },
      {
        label: "Price Adj. - Loan Term",
        field: "shortTermRentalRate",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.shortTermRentalRate`
      },
      {
        label: "Price Adj. - Loan Purpose",
        field: "loanPurposePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanPurposePriceAdjustment`
      },
      {
        label: "Price Adj. - Property Type",
        field: "propertyTypePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.propertyTypePriceAdjustment`
      },
      {
        label: "Price Adj. - FN/NPRA",
        field: "loanUserTypePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanUserTypePriceAdjustment`
      },
      {
        label: "Price Adj. - Loan Balance",
        field: "loanBalancePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalancePriceAdjustment`
      },
      {
        label: "Price Adj. - Cross Collateralized",
        field: "crossCollaterizedAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.crossCollaterizedAdjustment`
      },
      {
        label: "Price Adj. - Amortization",
        field: "amortizationPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.amortizationPriceAdjustment`
      },
      {
        label: "Leverage Pricing Bucket",
        field: "leveragePricingBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.leveragePricingBucket`
      },
      {
        label: "Loan Balance Bucket",
        field: "loanBalanceBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`
      },
      {
        label: "Price Adj. - Tier state",
        field: "propertyLocationPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`
      },
      {
        label: "FC/SS/DIL/BK7 36 - 47mo",
        field: "creditEventPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.creditEventPriceAdjustment`
      },
      {
        label: "Relock Adjustment",
        field: "reLockAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.reLockAdjustment`
      }
    ]
  };
  return versionToFieldsMap?.[version] ?? versionToFieldsMap?.[""];
};