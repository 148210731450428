/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useEffect, useMemo, useRef } from "react";
import queryString from "query-string";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Grid,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  MenuList,
  Avatar,
  Button
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import { useLocation, useParams } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import logo from "../../images/loanlistheader/group-3.svg";
import dashboardIconFilled from "../../icons/dashboardFilled.svg";
import dashboardIconOutlined from "../../icons/dashboardOutlined.svg";
import logoutIcon from "../../icons/logoutIcon.svg";
import bellIcon from "../../images/notification/notification.svg";
// import rcnCapital from "../../images/loanlistheader/rcn1x.png";
import { LinearProgressBar } from "../sub-headers/LinearProgresBar";
import { RootState } from "../../stores/rootReducer";
import {
  isLATUser,
  isILP,
  isRole,
  isPrivilege,
  isAssetManagement,
  isDrawAMRole,
  isOrigUser
} from "../../utils/AccessManagement";
import {
  getNotification
  //notificationClickedUpdate
} from "../../stores/dashboardSection/dashboard.action";
import { Notification } from "../dashboard/notification";
// import { updateSlectedFilters } from "../create-loan.action";
import { getCookie, setCookie } from "../../utils/cookies";
import { HamBurger } from "./hamburger";
import {
  removeSearched
  // deselectActiveBundle,
} from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { resetAllStores, updateSearchValue } from "../create-loan.action";
import {
  InternalRolesEnum,
  DrawRolesEnum,
  AssetManagementRolesEnum
} from "../../utils/constants";
import { getConfig } from "../../config/config";
// import { setViewType } from "../../stores/ILP/ILP.action";
import { useStylesHeader } from "./Header.style";
import { UserRole } from "@toorak/tc-common-fe-sdk";
import { ScrollingBanner } from "../../ui-components/ScrollingBanner";
import { useNavigate } from "react-router-dom";
import { notificationClickedUpdate } from "../../stores/dashboardSection/dashboard.reducer";
import { setViewType } from "../../stores/ILP/ILP.reducer";
import { SearchBar } from "./SearchBar";
import { isAnyLATAMRole, isBetweenDesiredRange } from "../../utils/utils";

interface CustomTabProps {
  title: string;
  isSelected: boolean;
  onClick: Function;
  id: string;
}

const CustomTab = (props: CustomTabProps) => {
  const classes = useStylesHeader();
  const { title, isSelected, onClick, id } = props;
  return (
    <Grid key={id} className={classes.root}>
      <Grid style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          onClick={() => {
            onClick(id);
          }}
          style={{
            cursor: "pointer",
            color: "##ffffff",
            fontSize: 14,
            textTransform: "uppercase",
            fontWeight: isSelected ? 700 : 400,
            width: "max-content"
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        style={{
          height: 0,
          borderRadius: 6,
          borderStyle: "solid",
          borderColor: "#ffffff",
          background: "#ffffff",
          margin: "12px 8px 2px 8px",
          borderWidth: 2,
          opacity: isSelected ? 1 : 0,
          padding: "0px 16px"
        }}
      />
    </Grid>
  );
};

interface Props {
  value: number;
  setTabValue?: any;
  showTab?: boolean;
  showNotificationIcon?: boolean;
}

export const Header = (props: Props) => {
  const token = getCookie("id_token") || "";
  const { name, email, roles } = JSON.parse(atob(token.split(".")[1]));
  const path = useLocation();
  const navigate = useNavigate();
  const isLAT = isLATUser();
  const isCounsel_Member = getCookie("roles")?.includes("COUNSEL_MEMBER");

  const classes = useStylesHeader();
  const { value, setTabValue, showTab, showNotificationIcon = true } = props;
  const dispatch = useDispatch();
  const tabView = useSelector<RootState, any>((state) => state.spStore.tabView);
  const getLoan = useSelector<RootState, any>(
    (state) => state.createLoanStore.getLoan
  );
  const isPageBlocked = useSelector<RootState, any>(
    (state) => state.createLoanStore.isPageBlocked
  );
  const unReadCount = useSelector<RootState, any>(
    (state) => state.dashboardStore.unReadCount
  );
  const notificationClicked = useSelector<RootState, any>(
    (state) => state.dashboardStore.notificationClicked
  );
  const urlParams: any = queryString.parse(
    new URL(window.location.href).search
  );

  useEffect(() => {
    // Create a <link> element
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image"; // Specify the type of resource to preload, e.g., 'image', 'script', 'style', etc.
    link.href = logo;

    // Append the <link> element to the <head> of the document
    document.head.appendChild(link);

    // Clean up function to remove the <link> element when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  const showGlobalSearch = useMemo(() => {
    return !(
      window.location.pathname === "/home" ||
      window.location.pathname === "/notification-settings" ||
      window.location.pathname.includes("/counsel") ||
      isAssetManagement() ||
      isOrigUser()
    );
  }, []);

  const canClick = () => {
    if (path.pathname.startsWith("/home")) return false;
    return true;
  };
  const { drawId } = useParams<any>();
  const handleLogoClick = () => {
    // Routes to Dashboard page for originator platform
    // Routes to loans page for ilp platform as there is no dashboard
    handleChangeHeaderTabs(0);
  };
  const handleChangeHeaderTabs = (
    // event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setCookie("view", "Detailed");

    clearFilters();
    dispatch(resetAllStores());
    if (!isILP() && !isAssetManagement()) {
      dispatch(notificationClickedUpdate(false));
      if (roles === undefined || roles?.length === 0) {
        navigate(`/loans/list?tab=3`);
        dispatch(setTabValue(3));
        return;
      }
      if (newValue === 2) {
        removeSearched(dispatch);
        // dispatch(deselectActiveBundle());
      }
      navigate(`/loans/list?tab=${newValue}`);
      if (tabView === newValue && !isPageBlocked) window.location.reload();
    } else if (isAssetManagement()) {
      navigate(`/asset/home?tab=${newValue}`);
    } else {
      navigate(`/internal/loans/list?tab=${newValue}`);
    }
    dispatch(setTabValue(newValue));
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutsideHamburger, false);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutsideHamburger, false);
  //   };
  // }, []);
  // const wrapperRefHamburgererRef = useRef<HTMLDivElement>(null);

  // const handleClickOutsideHamburger = (event: any) => {
  //   const node = wrapperRefHamburgererRef.current;
  //   if (wrapperRefHamburgererRef && !node?.contains(event.target)) {
  //     setShowMenu(false);

  //   }
  // };

  useEffect(() => {
    if (!isILP()) {
      dispatch(getNotification(0));
      const interval = setInterval(() => {
        dispatch(getNotification(0));
      }, 300000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const firstName = getCookie("firstName") || "";
  const lastName = getCookie("lastName") || "";

  const isAMRoleDrawDetails = () => {
    return isDrawAMRole() && drawId;
  };

  const MIN_TABS =
    ((window.location.hostname === "www.gcp.toorakcapital.info" ||
      window.location.hostname === "www.toorakcapital.info") &&
      !isRole(AssetManagementRolesEnum.AM_SO)) ||
    !(isAssetManagement() || isAMRoleDrawDetails())
      ? 3
      : 4;
  let initials = "";
  if (firstName !== null && firstName !== "null" && firstName !== "") {
    initials += firstName.charAt(0);
  }
  if (lastName !== null && lastName !== "null" && lastName !== "") {
    initials += lastName.charAt(0);
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNot, setAnchorElNot] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  let tabs: any[] = [];
  const roleName =
    roles && roles?.filter((item: any) => item === "LAT_BULK_UPLOAD");
  const adminSupport =
    roles && roles?.filter((item: any) => item === "ADMIN_SUPPORT");

  if (roles === undefined || roles?.length === 0) {
    dispatch(setTabValue(3));
    tabs = [
      {
        id: 3,
        title: `Documents`
      }
    ];
  } else if (roleName && roleName[0] === "LAT_BULK_UPLOAD") {
    tabs = [
      {
        id: 0,
        title: `Dashboard`
      },
      {
        id: 1,
        title: `Loans`
      },
      {
        id: 2,
        title: `Purchase And Settlement`
      },
      {
        id: 3,
        title: `Documents`
      },
      {
        id: 4,
        title: `Scenario`
      },
      {
        id: 5,
        title: "Appraiser Search"
      },
      {
        id: 6,
        title: `Bulk Evaluation`
      }
    ];
  } else if (adminSupport && adminSupport[0] === "ADMIN_SUPPORT") {
    tabs = [
      {
        id: 0,
        title: `Dashboard`
      },
      {
        id: 1,
        title: `Loans`
      },
      {
        id: 2,
        title: `Purchase And Settlement`
      },
      {
        id: 3,
        title: `Documents`
      },
      {
        id: 4,
        title: `Scenario`
      },

      {
        id: 5,
        title: "Appraiser"
      },
      {
        id: 6,
        title: `Bulk Evaluation`
      },
      {
        id: 7,
        title: `Admin Screen`
      }
    ];
  } else {
    tabs = [
      {
        id: 0,
        title: `Dashboard`
      },
      {
        id: 1,
        title: `Loans`
      },
      {
        id: 2,
        title: `Purchase And Settlement`
      },
      {
        id: 3,
        title: `Documents`
      },
      {
        id: 4,
        title: `Scenario`
      },
      {
        id: 5,
        title: "Appraiser Search"
      }
    ];

    if (isRole("APPRAISER")) {
      tabs.push({
        id: 9,
        title: "Toorval Appraisal"
      });
    }
    if (
      isRole(DrawRolesEnum.ORIGINATOR_DRAW) ||
      isRole(DrawRolesEnum.ORIGINATOR_VIEW_DRAW)
    ) {
      tabs.push({
        id: 8,
        title: "Draw Request"
      });
    }
  }
  if (
    !isILP() &&
    !isAssetManagement() &&
    (isRole(InternalRolesEnum.LAT_ADMIN) || isRole(UserRole.LAT_TREASURER))
    // && window.location.hostname !== "www.toorakcapital.info"
  ) {
    tabs.push({
      id: 10,
      title: `Financeability`
    });
    if (
      window.location.hostname === "qa.toorakcapital.info" ||
      window.location.hostname === "localhost"
    ) {
      tabs.push({
        id: 6,
        title: `Docs Index Self Serve`
      });
    }
  }

  if (isPrivilege("publish_pricing") && !isILP() && !isAssetManagement()) {
    tabs.push({
      id: 12,
      title: "Publish Pricing"
    });
  }

  if (isPrivilege("takeout_partner") && !isILP() && !isAssetManagement()) {
    tabs.push({
      id: 13,
      title: `Take-out Partners`
    });
  }

  if (isILP()) {
    tabs = [
      {
        id: 0,
        title: `Loans`
      }
      // {
      //   id: 1,
      //   title: `Communication`
      // }
    ];
    if (
      isRole(InternalRolesEnum.LAT_ADMIN) ||
      isRole(InternalRolesEnum.LAT_APPROVER)
    ) {
      tabs.push({
        id: 2,
        title: `Approver Dashboard`
      });
    }
    // if (isRole(InternalRolesEnum.LAT_ADMIN)) {
    //   tabs.push(
    //     {
    //       id: 2,
    //       title: `Approver Action`
    //     },
    //     {
    //       id: 3,
    //       title: `Inquiry Action`
    //     }
    //   );
    // }
    // if (isPrivilege("view_cs_report")) {
    //   tabs.push({
    //     id: 4,
    //     title: `Low CS Report`
    //   });
    // }
    // if (isRole(InternalRolesEnum.LAT_BD)) {
    //   tabs.push({
    //     id: 5,
    //     title: "Pricing Grid"
    //   });
    // }
    if (isPrivilege("takeout_partner")) {
      tabs.push({
        id: 6,
        title: `Take-out Partners`
      });
    }
  }

  if (isAssetManagement() || isAMRoleDrawDetails()) {
    tabs = [
      // {
      //   id: 2,
      //   title: "Real Insights"
      // },
      // {
      //   id: 3,
      //   title: "Communication"
      // }
    ];
    if (
      isRole(AssetManagementRolesEnum.AM_SO) ||
      isPrivilege("view_asset_management")
    ) {
      tabs.splice(0, 0, {
        id: 1,
        title: `Servicer Onboarding`
      });
    }
    if (isDrawAMRole() || isPrivilege("view_asset_management")) {
      tabs.splice(0, 0, {
        id: 0,
        title: "Draw Request"
      });
      tabs.splice(2, 0, {
        id: 2,
        title: "Purchased Loans"
      });
    }
  }

  if (showTab === false) {
    tabs = [];
  }
  const handleMenuNot = (event: any) => {
    if (!isILP()) {
      dispatch(notificationClickedUpdate(false));
      setAnchorElNot(true);
    }
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = (e: any) => {
    const node = wrapperRef.current;
    if (wrapperRef && !node?.contains(e.srcElement)) {
      setAnchorElNot(false);
      // setShowMenu(false);
    }
  };
  const NotificationIcon = () => {
    return (
      <img
        src={bellIcon}
        className={classes.icon}
        onClick={handleMenuNot}
        alt="bell-icon"
      />
    );
  };
  useEffect(() => {
    if (!isILP()) {
      if (notificationClicked) {
        setAnchorElNot(false);
      }
    }
  }, [notificationClicked]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOutClick = () => {
    setAnchorEl(null);
    localStorage.removeItem("customizedColumn");
    sessionStorage.removeItem("excelUpload");
    navigate("/logout");
  };
  const clickStatus = {
    toorakConnect: false,
    internalProcessing: false,
    boxConditionMonitoring: false,
    notificationSettings: false,
    assetManagement: false
  };
  if (path.pathname.startsWith("/internal")) {
    clickStatus.internalProcessing = true;
  } else if (path.pathname.startsWith("/loan")) {
    clickStatus.toorakConnect = true;
  } else if (path.pathname === "/notification-settings") {
    clickStatus.notificationSettings = true;
  } else if (
    path.pathname.startsWith("/asset") ||
    path.pathname.startsWith("/drawDetails") ||
    path.pathname.startsWith("/serviceronboarding") ||
    path.pathname.startsWith("/purchasedLoanDetails")
  ) {
    clickStatus.assetManagement = true;
  }

  let checkHome = false;
  if (path.pathname.startsWith("/home")) {
    checkHome = true;
  }

  const clearSearchValue = () => {
    setCookie("globalSearchValue", "");
    setCookie("filterCleared", "true");
    dispatch(updateSearchValue(""));
  };

  const toorakConnectClick = () => {
    setAnchorEl(null);
    dispatch(setViewType("details"));
    navigate("/loans/list?tab=1");
    clearSearchValue();
  };
  const internalloanProcessingClick = () => {
    setAnchorEl(null);
    // if (
    //   !isRole(InternalRolesEnum.LAT_ADMIN) &&
    //   (isRole(InternalRolesEnum.LAT_APPROVER) ||
    //     isRole(InternalRolesEnum.LAT_INQUIRY))
    // ) {
    //   dispatch(push("/internal/loans/list?tab=2"));
    // } else {
    navigate(`/internal/loans/list?tab=0`);
    //  }
    clearSearchValue();
  };

  const onBoxConditionMonitoring = () => {
    const configParams = getConfig();
    const refreshTokenId = getCookie("refresh_token");
    window.location.href = `${configParams.boxRedirectUrl}/callBack?token=${refreshTokenId}`;
    clearSearchValue();
  };

  const notificationSettingsClick = () => {
    setAnchorEl(null);
    navigate(`/notification-settings`);
    clearSearchValue();
  };

  const assetManagementClick = () => {
    setAnchorEl(null);
    navigate(`/asset/home`);
    clearSearchValue();
  };

  const clearFilters = () => {
    // dispatch(updateSlectedFilters({}));
    clearSearchValue();
    // dispatch({
    //   type: DROPDOWN_DATA_SUCCESS,
    //   payload: {
    //     loanLists: {},
    //     pageCount: 0
    //   }
    // });
  };
  const StyledMenu: any = withStyles({
    paper: {
      border: "1px solid #d3d4d5"
    }
  })(
    (props: {
      open?: any;
      anchorEl?: any;
      id?: any;
      onClose?: any;
      keepMounted?: any;
    }) => (
      <Menu
        elevation={0}
        //getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={props.open}
        id={props.id}
        onClose={props.onClose}
        keepMounted={props.keepMounted}
        {...props}
      />
    )
  );

  // const StyledMenuItem = withStyles((theme) => ({
  //   root: {
  //     '&:focus': {
  //       backgroundColor: theme.palette.primary.main,
  //       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //         color: theme.palette.common.white,
  //       },
  //     },
  //   }
  // }))(MenuItem);
  const useMenuStyles = makeStyles((theme) => ({
    userDetailList: {
      border: "0",
      borderBottom: "1px solid #e2e4f1",
      backgroundColor: "transparent"
    },
    logoutContainer: {
      padding: "6px 0 0 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "0px"
    },
    mainMenuItem: {
      cursor: "pointer",
      padding: "12px 0 12px 0"
    },
    dividerStyle: {
      paddingTop: "10px",
      paddingBottom: "12px"
    },
    signOutStyle: {
      paddingTop: "10px",
      fontSize: "12px"
    }
  }));
  const classesStyle = useMenuStyles();
  // const handleClickBulkUpload = () => {
  //   dispatch(updateBulkUpload(true));
  // }

  // More Menu
  const [moreOpen, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const moreOptionClose: any = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(moreOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && moreOpen === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = moreOpen;
  }, [moreOpen]);
  // More Menu

  const iconButton = () => {
    return (
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        style={{ padding: "12px 12px" }}
      >
        <div id="profileImage" className={classes.profileImage}>
          {initials.toUpperCase()}
        </div>
      </IconButton>
    );
  };
  const switchToOriginator = () => {
    return (
      <>
        <Grid item xs={3} className={classes.imgUser2}>
          <Box display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
            {iconButton()}
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <ListItem className={classesStyle.userDetailList}>
                <ListItemAvatar>
                  <Avatar>
                    <div id="profileImage" className={classes.profileImage}>
                      {initials.toUpperCase()}
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
              <MenuList className={classesStyle.logoutContainer}>
                <MenuItem
                  onClick={logOutClick}
                  className={classesStyle.signOutStyle}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={logoutIcon} alt="logout-icon" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Signout
                  </Typography>
                </MenuItem>
              </MenuList>
            </StyledMenu>
          </Box>
          <HamBurger showTab={showTab} />
        </Grid>
      </>
    );
  };
  const switchToCounsel = () => {
    return (
      <>
        <Grid item xs={3} className={classes.imgUser2}>
          <Box display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
            {iconButton()}
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <ListItem className={classesStyle.userDetailList}>
                <ListItemAvatar>
                  <Avatar>
                    <div id="profileImage" className={classes.profileImage}>
                      {initials.toUpperCase()}
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
              <MenuList className={classesStyle.logoutContainer}>
                <MenuItem
                  onClick={logOutClick}
                  className={classesStyle.signOutStyle}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={logoutIcon} alt="logout-icon" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Signout
                  </Typography>
                </MenuItem>
              </MenuList>
            </StyledMenu>
          </Box>
          <HamBurger showTab={showTab} />
        </Grid>
      </>
    );
  };

  let platFormName = "";
  if (clickStatus.toorakConnect) {
    platFormName = "Toorak Connect";
  } else if (clickStatus.internalProcessing) {
    platFormName = "Internal Loan Processing";
  } else if (clickStatus.assetManagement) {
    platFormName = "Asset Management";
  } else {
    platFormName = "Notification Settings";
  }

  const switchToPlatform = () => {
    return (
      <>
        <Grid item xs={5}>
          <Box
            display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}
            className={
              clickStatus.toorakConnect
                ? classes.imgUserToorakConnect
                : classes.imgUser
            }
          >
            <Grid className={classes.textContainer} item xs={10}>
              <p
                className={
                  clickStatus.toorakConnect
                    ? `${classes.statusText} ${classes.statusTextPadding2}`
                    : `${classes.statusText} ${classes.statusTextPadding1}`
                }
              >
                {platFormName}
              </p>
            </Grid>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.IconText}
            >
              <div id="profileImage" className={classes.profileImage}>
                {initials.toUpperCase()}
              </div>
            </IconButton>
            <ArrowLeftIcon
              fontSize="large"
              htmlColor="white"
              onClick={(e: any) => handleMenu(e)}
              style={{
                width: 14,
                height: 16,
                marginTop: 14,
                transform: open ? "rotate(90deg)" : "rotate(270deg)",
                objectFit: "contain",
                cursor: "pointer"
              }}
              component="svg"
            />
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <ListItem className={classesStyle.userDetailList}>
                <ListItemAvatar>
                  <Avatar>
                    <div id="profileImage" className={classes.profileImage}>
                      {initials.toUpperCase()}
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
              <MenuList className={classesStyle.logoutContainer}>
                {isLAT && (
                  <>
                    <MenuItem onClick={toorakConnectClick}>
                      <ListItemIcon className={classes.listItemIcon}>
                        {clickStatus.toorakConnect ? (
                          <img src={dashboardIconFilled} alt="dashboard-icon" />
                        ) : (
                          <img
                            src={dashboardIconOutlined}
                            alt="dashboard-icon-filled"
                          />
                        )}
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        className={
                          !clickStatus.toorakConnect
                            ? classes.colorNormal
                            : classes.colorBlue
                        }
                        noWrap
                      >
                        Toorak Connect
                      </Typography>
                    </MenuItem>
                    {isPrivilege("view_workflow") && (
                      <MenuItem
                        onClick={internalloanProcessingClick}
                        divider
                        className={classesStyle.dividerStyle}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {clickStatus.internalProcessing ? (
                            <img
                              src={dashboardIconFilled}
                              alt="dashboard-icon"
                            />
                          ) : (
                            <img
                              src={dashboardIconOutlined}
                              alt="dashboard-icon-filled"
                            />
                          )}
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          className={
                            !clickStatus.internalProcessing
                              ? classes.colorNormal
                              : classes.colorBlue
                          }
                          noWrap
                        >
                          Internal Loan Processing
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={onBoxConditionMonitoring}
                      divider
                      className={classesStyle.dividerStyle}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {clickStatus.boxConditionMonitoring ? (
                          <img src={dashboardIconFilled} alt="dashboard-icon" />
                        ) : (
                          <img
                            src={dashboardIconOutlined}
                            alt="dashboard-icon-filled"
                          />
                        )}
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        className={
                          !clickStatus.boxConditionMonitoring
                            ? classes.colorNormal
                            : classes.colorBlue
                        }
                        noWrap
                      >
                        Box Condition Monitoring
                      </Typography>
                    </MenuItem>
                    {isAnyLATAMRole() && (
                      <MenuItem onClick={assetManagementClick}>
                        <ListItemIcon className={classes.listItemIcon}>
                          {clickStatus.assetManagement ? (
                            <img
                              src={dashboardIconFilled}
                              alt="dashboard-icon"
                            />
                          ) : (
                            <img
                              src={dashboardIconOutlined}
                              alt="dashboard-icon-filled"
                            />
                          )}
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          className={
                            !clickStatus.assetManagement
                              ? classes.colorNormal
                              : classes.colorBlue
                          }
                          noWrap
                        >
                          Asset Management
                        </Typography>
                      </MenuItem>
                    )}
                    {(window.location.hostname === "qa.toorakcapital.info" ||
                      window.location.hostname === "localhost") && (
                      <MenuItem
                        onClick={notificationSettingsClick}
                        divider
                        className={classesStyle.dividerStyle}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {clickStatus.notificationSettings ? (
                            <img
                              src={dashboardIconFilled}
                              alt="dashboard-icon"
                            />
                          ) : (
                            <img
                              src={dashboardIconOutlined}
                              alt="dashboard-icon-filled"
                            />
                          )}
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          className={
                            !clickStatus.notificationSettings
                              ? classes.colorNormal
                              : classes.colorBlue
                          }
                          noWrap
                        >
                          Notification Settings
                        </Typography>
                      </MenuItem>
                    )}
                  </>
                )}
                <MenuItem
                  onClick={logOutClick}
                  className={classesStyle.signOutStyle}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={logoutIcon} alt="logout-icon" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Signout
                  </Typography>
                </MenuItem>
              </MenuList>
            </StyledMenu>
          </Box>
          <HamBurger showTab={showTab} />
        </Grid>
      </>
    );
  };
  const switchToHome = () => {
    return (
      <>
        <Grid item xs={3} className={classes.imgUser2}>
          <Box display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
            {iconButton()}
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <ListItem className={classesStyle.userDetailList}>
                <ListItemAvatar>
                  <Avatar>
                    <div id="profileImage" className={classes.profileImage}>
                      {initials.toUpperCase()}
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
              <MenuList className={classesStyle.logoutContainer}>
                <MenuItem onClick={toorakConnectClick}>
                  <ListItemIcon className={classes.listItemIcon}>
                    {clickStatus.toorakConnect ? (
                      <img src={dashboardIconFilled} alt="dashboard-icon" />
                    ) : (
                      <img
                        src={dashboardIconOutlined}
                        alt="dashboard-icon-filled"
                      />
                    )}
                  </ListItemIcon>
                  <Typography
                    variant="inherit"
                    className={
                      !clickStatus.toorakConnect
                        ? classes.colorNormal
                        : classes.colorBlue
                    }
                    noWrap
                  >
                    Toorak Connect
                  </Typography>
                </MenuItem>
                {isPrivilege("view_workflow") && (
                  <MenuItem
                    onClick={internalloanProcessingClick}
                    divider
                    className={classesStyle.dividerStyle}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {clickStatus.internalProcessing ? (
                        <img src={dashboardIconFilled} alt="dashboard-icon" />
                      ) : (
                        <img
                          src={dashboardIconOutlined}
                          alt="dashboard-icon-filled"
                        />
                      )}
                    </ListItemIcon>
                    <Typography
                      variant="inherit"
                      className={
                        !clickStatus.internalProcessing
                          ? classes.colorNormal
                          : classes.colorBlue
                      }
                      noWrap
                    >
                      Internal Loan Processing
                    </Typography>
                  </MenuItem>
                )}
                {isAnyLATAMRole() && (
                  <MenuItem onClick={assetManagementClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                      {clickStatus.assetManagement ? (
                        <img src={dashboardIconFilled} alt="dashboard-icon" />
                      ) : (
                        <img
                          src={dashboardIconOutlined}
                          alt="dashboard-icon-filled"
                        />
                      )}
                    </ListItemIcon>
                    <Typography
                      variant="inherit"
                      className={
                        !clickStatus.assetManagement
                          ? classes.colorNormal
                          : classes.colorBlue
                      }
                      noWrap
                    >
                      Asset Management
                    </Typography>
                  </MenuItem>
                )}
                {(window.location.hostname === "qa.toorakcapital.info" ||
                  window.location.hostname === "localhost") && (
                  <MenuItem
                    onClick={notificationSettingsClick}
                    divider
                    className={classesStyle.dividerStyle}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {clickStatus.notificationSettings ? (
                        <img src={dashboardIconFilled} alt="dashboard-icon" />
                      ) : (
                        <img
                          src={dashboardIconOutlined}
                          alt="dashboard-icon-filled"
                        />
                      )}
                    </ListItemIcon>
                    <Typography
                      variant="inherit"
                      className={
                        !clickStatus.notificationSettings
                          ? classes.colorNormal
                          : classes.colorBlue
                      }
                      noWrap
                    >
                      Notification Settings
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={logOutClick}
                  className={classesStyle.signOutStyle}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={logoutIcon} alt="logout-icon" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Signout
                  </Typography>
                </MenuItem>
              </MenuList>
            </StyledMenu>
          </Box>
          <HamBurger showTab={showTab} />
        </Grid>
      </>
    );
  };

  const platformDecider = () => {
    if (isCounsel_Member) {
      return switchToCounsel();
    } else {
      if (!isLAT) {
        return switchToOriginator();
      } else {
        if (!checkHome) {
          return switchToPlatform();
        } else {
          return switchToHome();
        }
      }
    }
  };

  const { selectedTab, shouldTheExtraTabBeShown } = useMemo(() => {
    return {
      selectedTab: tabs.find((tabData) => tabData.id === value),
      shouldTheExtraTabBeShown:
        tabs.length > MIN_TABS &&
        value >= MIN_TABS &&
        !tabs.slice(0, MIN_TABS).find((tabData) => tabData.id === value)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tabs]);

  const organizationName = useMemo(() => {
    return getCookie("organizationName") || "";
  }, []);

  return (
    // <AppBar position="static" color="default" className={classes.paperHeader}>
    <div style={{ position: "fixed", width: "100%", zIndex: 1100 }}>
      {isBetweenDesiredRange() && (
        <ScrollingBanner text="Holiday Schedule: Toorak will be closed on Thursday 11/28/24 and Friday 11/29/24 in observance of Thanksgiving. DSCR rate locks will resume on Monday 12/2/24" />
      )}
      <AppBar
        position="static"
        className={classes.paperHeader}
        data-testid="app-header"
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Grid item className={classes.wrapper}>
            <Grid
              item
              className={classes.logo}
              style={
                canClick()
                  ? { display: "flex", cursor: "pointer" }
                  : { display: "flex" }
              }
              onClick={canClick() ? handleLogoClick : undefined}
            >
              <img src={logo} alt="toorak_logo" className={classes.imgLogo} />
            </Grid>
            <Grid
              item
              component={Box}
              display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}
              style={{ alignItems: "flex-end", marginBottom: "3px" }}
            >
              {tabs.map((item, index) => {
                if (item.hide) return null;
                return (
                  <React.Fragment key={item.id}>
                    {index < MIN_TABS && (
                      <CustomTab
                        title={item.title}
                        id={item.id}
                        isSelected={item.id === Number(urlParams.tab)}
                        onClick={handleChangeHeaderTabs}
                      />
                    )}
                  </React.Fragment>
                );
              })}
              {shouldTheExtraTabBeShown && (
                <CustomTab
                  title={selectedTab?.title}
                  id={selectedTab?.id}
                  isSelected={selectedTab?.id === Number(urlParams.tab)}
                  onClick={handleChangeHeaderTabs}
                />
              )}
              {tabs.length > MIN_TABS && (
                <div className={classes.moreMenuContainer}>
                  <Button
                    ref={anchorRef}
                    aria-controls={moreOpen ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={`${classes.moreButton} more-button`}
                  >
                    <span style={{ marginRight: 8 }}>More </span>
                    <span className="arrow-icon" />
                  </Button>

                  <Popper
                    className={`${classes.dropdownListContainer} dropdown-list-container`}
                    open={moreOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom"
                        }}
                      >
                        <Paper style={{ width: "160px" }}>
                          <ClickAwayListener onClickAway={moreOptionClose}>
                            <MenuList
                              autoFocusItem={moreOpen}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {tabs.map((item, index) => {
                                if (item.hide) return null;

                                return index >= MIN_TABS ? (
                                  <MenuItem
                                    onClick={() => {
                                      handleChangeHeaderTabs(item.id);
                                      setOpen((prevOpen) => !prevOpen);
                                    }}
                                    key={item.id}
                                  >
                                    {item.title}
                                  </MenuItem>
                                ) : null;
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.userInfo}>
            <div
              style={{
                minWidth: shouldTheExtraTabBeShown ? "18vw" : "25vw",
                margin: "auto"
              }}
            >
              {" "}
              {showGlobalSearch && <SearchBar />}
            </div>

            {!isLAT && (
              <Grid item className={classes.imgCapitalContainer}>
                {/* <img
                  src={rcnCapital}
                   alt="org_logo"
                   className={classes.imgCapital}
                 /> */}
                <span style={{ color: "#ffffff" }}>{organizationName}</span>
              </Grid>
            )}
            {showNotificationIcon && (
              <Grid item className={classes.notifyIconContainer}>
                {roles?.length > 0 && (
                  <Badge
                    badgeContent={unReadCount}
                    data-testid="notification-badge-icon"
                    overlap="circular"
                    variant="standard"
                    // onClick={handleMenuNot}
                    className="notificationNumber"
                  >
                    <NotificationIcon />
                    {anchorElNot && (
                      <div className={classes.customWidth} ref={wrapperRef}>
                        <Notification fromBell />
                      </div>
                    )}
                  </Badge>
                )}
              </Grid>
            )}
            {platformDecider()}
          </Grid>
        </Grid>
      </AppBar>
      {getLoan.loadingPostSubmit ? <LinearProgressBar /> : null}
    </div>
  );
};
