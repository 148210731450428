import { foreignNationalOptions, yesNoDropdown } from "../../../create-loan/constants/loanCreationDropDownValues";

const firstName = {
  fieldName: "guarantorFirstName",
  backendKey: "guarantorFirstName",
  fieldLabel: "First Name",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const secondName = {
  fieldName: "guarantorLastName",
  backendKey: "guarantorLastName",
  fieldLabel: "Second Name",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const foreignNational = {
  fieldName: "foreignNationalString",
  backendKey: "foreignNationalString",
  fieldLabel: "Foreign National Flag",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: foreignNationalOptions.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditScoreMedian = {
  fieldName: "originalCreditScoreMedian",
  backendKey: "creditScore",
  fieldLabel: "Original Credit Score (Median)",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditReportDate = {
  fieldName: "originalCreditReportDate",
  backendKey: "originalCreditReportDate",
  fieldLabel: "Original Credit Report Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const email = {
  fieldName: "guarantorEmail",
  backendKey: "guarantorEmail",
  fieldLabel: "Email",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const experience = {
  fieldName: "guarantorExperience",
  backendKey: "guarantorExperience",
  fieldLabel: "Experience",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const ownerShipEntity = {
  fieldName: "pOEntity",
  backendKey: "pOEntity",
  fieldLabel: "% of Ownership of Entity",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const firstTimeHomeBuyer = {
  fieldName: "firstTimeHomeBuyer",
  fieldLabel: "First Time Home Buyer",
  backendKey: "firstTimeHomeBuyer",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: yesNoDropdown.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: "customer.firstTimeHomeBuyer",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const prePostFields = [
  firstName,
  secondName,
  foreignNational,
  originalCreditScoreMedian,
  originalCreditReportDate,
  email,
  experience,
  ownerShipEntity,
  firstTimeHomeBuyer
];

export const getGuarantorInformationFields = (loanStage: string) => {
  switch (loanStage) {
    case "fes":
      return [];
    case "pre":
    case "post":
      return prePostFields;
  }
};
