import {
  cleanCurrency,
  isValidCurrency
} from "@toorak/tc-common-util-sdk/dist/errorCountService/fieldMissingCount";
import {
  thirdPartyValuationStatus,
  yesNoDropdown,
  closingCostValues
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { ruleV02_22 } from "../../../utils/constants";
import {
  compareRuleVersion,
  getRuleVersion,
  sanitizeCurrency
} from "../../../utils/formatChecks";
import { LoanSizerEnum } from "../../../frontend-sizer/FrontendSizerTemplate";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

const FesoriginalAsIsValue = {
  fieldName: "originalAsIsAppraisalValue",
  commentField: "originalAsIsAppraisalValue",
  backendKey: "originalAsIsAppraisalValue",
  eidtedLabel: "originalAsIsAppraisalValue",
  fieldLabel: "As-Is Value ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const FesoriginalAsIsAppraisalValue = {
  fieldName: "originalAsIsAppraisalValue",
  backendKey: "originalAsIsAppraisalValue",
  eidtedLabel: "originalAsIsAppraisalValue",
  fieldLabel: "As Is Valuation ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const FesThirdPartyValuationStatus = {
  fieldName: "thirdPartyValuationStatus",
  eidtedLabel: "thirdPartyValuationStatus",
  fieldLabel: "Third Party Valuation - Status",
  colSpan: 4,
  required: () => false,
  fieldType: () => "dropDown",
  dropDownData:
    thirdPartyValuationStatus.map((ele) => {
      return { label: ele, value: ele };
    }) || [],
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalAsIsAppraisalValue = {
  fieldName: "originalAppraisalValue",
  backendKey: "originalAsIsAppraisalValue",
  eidtedLabel: "originalAsIsAppraisalValue",
  fieldLabel: 'Original "As Is" Appraisal Value ($)',
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "originalAsIsAppraisalValue"
};

const bridgeOriginalAsIsAppraisalValue = {
  fieldName: "originalAsIsAppraisalValue",
  eidtedLabel: "originalAsIsAppraisalValue",
  fieldLabel: 'Original "As Is" Appraisal Value ($)',
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "originalAsIsAppraisalValue"
};

const originalAsRepairedAppraisedValue = {
  fieldName: "originalAsRepairedAppraisedValue",
  eidtedLabel: "originalAsRepairedAppraisedValue",
  fieldLabel: "After-Repair Value ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const FesthirdPartyValuation = {
  fieldName: "thirdPartyValuation",
  backendKey: "thirdPartyValuation",
  eidtedLabel: "thirdPartyValuation",
  fieldLabel: "Third Party Valuation - Property Value ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const thirdPartyValuation = {
  fieldName: "thirdPartyValuation",
  eidtedLabel: "thirdPartyValuation",
  fieldLabel: "Third Party Valuation ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => false,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const FesPurchasePrice = {
  fieldName: "purchasePrice",
  backendKey: "purchasePrice",
  eidtedLabel: "purchasePrice",
  fieldLabel: "Property Acquisition Price ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const purchasePrice = {
  fieldName: "purchasePrice",
  backendKey: "purchasePrice",
  eidtedLabel: "purchasePrice",
  fieldLabel: "Purchase Price ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeFES14ClosingCost = {
  fieldName: "closingCost",
  fieldLabel: "Closing Costs (%)",
  eidtedLabel: "closingCost",
  colSpan: 4,
  required: () => false,
  fieldType: () => "dropDown",
  dropDownData: closingCostValues,
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  ChangeValueMethod: (value: any) => parseFloat(value)
};

const costBasis = {
  fieldName: "costBasis",
  backendKey: "costBasis",
  eidtedLabel: "costBasisAfterCostIncurred",
  fieldLabel: "Cost Basis (After Cost Incurred) ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (
    isEvaluationPanelVisible: any,
    isEditable: any,
    loanState?: any,
    loanType?: any,
    loanStage?: any
  ) =>
    (loanStage === "FES" && loanType === "BridgeLoan") ||
    (isEvaluationPanelVisible && !isEditable)
};

const annualPropertyTaxes = {
  fieldName: "annualPropertyTaxes",
  backendKey: "annualPropertyTaxes",
  eidtedLabel: "annualPropertyTaxes",
  fieldLabel: "Annual Property Taxes ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: (data: any) => data?.stabilizedFlag === "Stabilized",
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const annualPropertyTaxesDscr = {
  fieldName: "annualPropertyTaxes",
  backendKey: "annualPropertyTaxes",
  eidtedLabel: "annualPropertyTaxes",
  fieldLabel: "Annual Property Taxes ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: (data: any) => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const annualHazardInsurance = {
  fieldName: "annualHazardInsurance",
  backendKey: "annualHazardInsurance",
  eidtedLabel: "annualHazardInsurance",
  fieldLabel: "Annual Hazard Insurance ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const annualFloodInsurance = {
  fieldName: "annualFloodInsurance",
  backendKey: "annualFloodInsurance",
  eidtedLabel: "annualFloodInsurance",
  fieldLabel: "Annual Flood Insurance ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const annualHoaFee = {
  fieldName: "annualHOAFee",
  backendKey: "annualHoaFee",
  eidtedLabel: "annualHoaFee",
  fieldLabel: "Annual HOA Fee ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrFESAnnualHoaFee = {
  fieldName: "annualHOAFee",
  backendKey: "annualHoaFee",
  eidtedLabel: "annualHoaFee",
  fieldLabel: "Annual HOA Fee ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeFESAnnualHoaFee = {
  fieldName: "annualHOAFee",
  backendKey: "annualHoaFee",
  eidtedLabel: "annualHoaFee",
  fieldLabel: "Annual HOA Fee ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const decliningMarkets = {
  fieldName: "decliningMarkets",
  backendKey: "decliningMarkets",
  eidtedLabel: "decliningMarkets",
  fieldLabel: "Declining Market",
  colSpan: 4,
  required: () => false,
  fieldType: () => "dropDown",
  dropDownData:
    yesNoDropdown.map((ele) => {
      return { label: ele, value: ele };
    }) || [],
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrFesDecliningMarkets = {
  fieldName: "decliningMarkets",
  backendKey: "decliningMarkets",
  eidtedLabel: "decliningMarkets",
  fieldLabel: "Declining Market",
  colSpan: 4,
  required: () => false,
  fieldType: () => "dropDown",
  dropDownData:
    yesNoDropdown.map((ele) => {
      return { label: ele, value: ele };
    }) || [],
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalRepairedAppraisalValue = {
  fieldName: "originalRepairedAppraisalValue",
  eidtedLabel: "originalRepairedAppraisalValueEdited",
  fieldLabel: "As Repaired Value (ARV) ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "originalAsRepairedAppraisedValue"
};

const bridgeOriginalRepairedAppraisalValue = {
  fieldName: "originalAsRepairedAppraisedValue",
  eidtedLabel: "originalRepairedAppraisalValueEdited",
  fieldLabel: "As Repaired Value (ARV) ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "originalAsRepairedAppraisedValue"
};

const grossPotentialRent = {
  fieldName: "grossPotentialRent",
  backendKey: "grossPotentialRent",
  eidtedLabel: "grossPotentialRent",
  fieldLabel: "Gross Potential Rent (Yearly) ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: (data: any) => data?.stabilizedFlag === "Stabilized",
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const grossCondoSelloutValue = {
  fieldName: "grossCondoSelloutValue", // new field
  fieldLabel: "Gross Condo Sellout Value ($)",
  fieldType: () => "currency",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => false,
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const assignmentFees = {
  fieldName: "assignmentFees",
  backendKey: "assignmentFees",
  eidtedLabel: "assignmentFeesEdited",
  fieldLabel: "Assignment Fees ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const insurance = {
  fieldName: "insurance",
  backendKey: "insurance",
  eidtedLabel: "insurance",
  fieldLabel: "Insurance ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: (data: any) => data?.stabilizedFlag === "Stabilized",
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const appraisalReported = {
  fieldName: "appraisalReported",
  backendKey: "appraisalReportedNoiNcf",
  eidtedLabel: "appraisalReported",
  fieldLabel: "Appraisal Reported NOI/NCF ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "appraisalReportedNoiNcf"
};

const bridgeFesMMAppraisalReported = {
  fieldName: "appraisalReportedNoiNcf",
  backendKey: "appraisalReportedNoiNcf",
  eidtedLabel: "appraisalReported",
  fieldLabel: "Appraisal Reported NOI/NCF ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "appraisalReportedNoiNcf"
};

const borrowerPaidCosts = {
  fieldName: "borrowerPaidCosts",
  backendKey: "borrowerPaidCosts",
  eidtedLabel: "borrowerPaidCosts",
  fieldLabel: "Borrower Paid costs to date",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const marketRentPerMonth = {
  fieldName: "marketRentPerMonth",
  fieldLabel: "Market Rent per month (if applicable) ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  colSpan: 4,
  required: () => true,
  fieldType: () => "currency",
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrFESFields = [
  FesoriginalAsIsAppraisalValue,
  FesThirdPartyValuationStatus,
  FesthirdPartyValuation,
  FesPurchasePrice,
  annualPropertyTaxesDscr,
  annualHazardInsurance,
  annualFloodInsurance,
  dscrFESAnnualHoaFee,
  dscrFesDecliningMarkets
];

const bridgeFesOneToFourFields: any = [
  bridgeOriginalAsIsAppraisalValue,
  purchasePrice,
  bridgeFES14ClosingCost,
  bridgeOriginalRepairedAppraisalValue,
  grossPotentialRent,
  { ...costBasis, required: () => false },
  assignmentFees,
  // TODO: below fields required for ta-6630 not required for refactoring prod release
  annualPropertyTaxes,
  insurance,
  bridgeFESAnnualHoaFee
];

const bridgeFesMultiFamilyFields: any = [
  bridgeOriginalAsIsAppraisalValue,
  purchasePrice,
  borrowerPaidCosts,
  bridgeOriginalRepairedAppraisalValue,
  grossPotentialRent,
  bridgeFesMMAppraisalReported,
  annualPropertyTaxes,
  insurance,
  { ...costBasis, required: () => false },
  grossCondoSelloutValue,
  assignmentFees,
  annualHoaFee,
];

const bridgeFesGroundFields: any = [
  FesoriginalAsIsValue,
  purchasePrice,
  grossPotentialRent,
  bridgeFES14ClosingCost,
  originalAsRepairedAppraisedValue,
  marketRentPerMonth,
  assignmentFees
];

const dscrPrePostFields = [
  { ...originalAsIsAppraisalValue, backendKey: "originalAsIsAppraisalValue" },
  thirdPartyValuation,
  purchasePrice,
  costBasis,
  annualPropertyTaxesDscr,
  annualHazardInsurance,
  annualFloodInsurance,
  { ...annualHoaFee, fieldName: "annualHoaFee" },
  decliningMarkets
];

const dscrPreFields: any = [
  { ...originalAsIsAppraisalValue, commentField: "originalAsIsAppraisalValue" },
  { ...thirdPartyValuation, required: () => false },
  purchasePrice,
  costBasis,
  annualPropertyTaxesDscr,
  annualHazardInsurance,
  annualFloodInsurance,
  { ...annualHoaFee, fieldName: "annualHoaFee" },
  { ...decliningMarkets, required: () => false }
];

const bridgePrePostFields: any = [
  { ...originalAsIsAppraisalValue, backendKey: "originalAsIsAppraisalValue" },
  {
    ...originalRepairedAppraisalValue,
    backendKey: "originalAsRepairedAppraisedValue"
  },
  purchasePrice,
  costBasis,
  grossPotentialRent,
  assignmentFees,
  annualPropertyTaxes,
  insurance,
  appraisalReported
];

const bridgePreFields: any = [
  { ...originalAsIsAppraisalValue, backendKey: "originalAsIsAppraisalValue" },
  {
    ...originalRepairedAppraisalValue,
    backendKey: "originalAsRepairedAppraisedValue"
  },
  purchasePrice,
  { ...costBasis, required: () => false },
  grossPotentialRent,
  assignmentFees,
  annualPropertyTaxes,
  insurance,
  appraisalReported
];

const getFeildsBasedOnLoanSizerType = (loanSizerType: any) => {
  switch (loanSizerType) {
    case LoanSizerEnum.multifamilyMixedUse:
      return bridgeFesMultiFamilyFields;
    case LoanSizerEnum.groundUp:
      return bridgeFesGroundFields;
    case LoanSizerEnum.oneFourFamily:
      return bridgeFesOneToFourFields;
    default:
      return [];
  }
};

export const getPropertyEconomicsField = (
  loanStage: any,
  loanType: any,
  loanSizerType: any
) => {
  switch (loanStage) {
    case "fes":
      return loanType === "InvestorDSCR"
        ? dscrFESFields
        : getFeildsBasedOnLoanSizerType(loanSizerType);
    case "pre":
      return loanType === "InvestorDSCR" ? dscrPreFields : bridgePreFields;
    case "post":
      return loanType === "InvestorDSCR"
        ? dscrPrePostFields
        : bridgePrePostFields;
    default:
      return [];
  }
};
