/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  borrowerType,
  cashOutNot,
  loanPurposeDropDown,
  loanStructureDropDown,
  predominantPropertyType,
  propertyTypeThirtyYearFes,
  multiFamMixedUsePropertyTypeDropDown,
  oneFourFamilyPropertyTypeDropDown,
  rentDescription,
  rentDescriptionOld,
  LeaseStatusEnum,
  thirdPartyValuationStatus,
  valuationSource,
  multiFamMixedUsevaluationSource,
  yesNoDropdown,
  multiFamilyRecourse,
  condoEligibilityDropDownFes,
  prepaymentPenalty,
  rateType,
  prepaymentPenaltyTypeDropDown,
  exitStrategyDropDown,
  closingCostValues,
  gUpFESExitStrategyOptions,
  gUpFESLoanStructure,
  gUpLoanTerm,
  irFundingSources,
  foreignNationalOptions,
  crossCollaterlizationData,
  // fundingChannelDropDown
} from "../create-loan/constants/loanCreationDropDownValues";
import { isNotStabilised } from "../create-loan/constants/loanFieldType";
import { percentageOccupiedFormula } from "../create-loan/evaluation-results/helpers/ruleRequestBody";
import { isLATUser } from "../utils/AccessManagement";
import {
  percentageToNumber,
  currencyToNumber,
  isEmptyValue,
  formatValueByType
} from "../utils/formatChecks";

export enum LoanSizerEnum {
  oneFourFamily = "1-4 Family",
  multifamilyMixedUse = "Multifamily/MixedUse",
  groundUp = "Ground-Up"
}
export interface Section {
  sectionName: string;
  sectionLabel: string;
  sectionId: string;
  fields: Array<Fields>;
}
export interface DropDownType {
  value: string | boolean;
  label: string;
}

export interface Fields {
  fieldName: string;
  fieldLabel: string;
  fieldId: string;
  fieldType: string;
  reduxFieldName?: string;
  ruleFieldName?: string;
  editedLabel?: string;
  extraData?: DropDownType[];
  requiredLeaseStatus?: any;
  rules?: any;
  getValues?: any;
  dropDownData?: DropDownType[];
  dependentQues?: any;
  viewModeOnly?: boolean;
  required?: boolean;
  optionalCondition?: any; // if field is required, return false.
  hideCondition?: any; // return true if the field should be hidden
}

export const fesBridgeLoan: Array<Section> = [
  {
    sectionName: "fesBorrowerInformation",
    sectionLabel: "Borrower Information",
    sectionId: "borrowerInformation-fes",
    fields: [
      {
        fieldName: "borrowerGUCExperience",
        fieldLabel: "Borrower GUC Experience",
        fieldId: "borrowerGUCExperience-fes",
        fieldType: "numberNoFormat",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "heavyRehabExperience",
        fieldLabel: "Heavy Rehab Experience",
        fieldId: "heavyRehabExperience-fes",
        fieldType: "numberNoFormat",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "borrowerExperience",
        fieldLabel: "Borrower Experience",
        fieldId: "borrowerExperience-fes",
        ruleFieldName: "experience",
        fieldType: "numberNoFormat",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "foreignNationalString",
        fieldLabel: "Foreign National",
        ruleFieldName: "foreignNationalString",
        fieldId: "foreignNationalFlag-fes",
        fieldType: "dropDown",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "borrowerCreditScore",
        fieldLabel: "Original Credit Score (Median)",
        fieldId: "borrowerCreditScore-fes",
        ruleFieldName: "originalCreditScoreMedian",
        fieldType: "numberNoFormat",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        },
        optionalCondition: (data: any) => {
          if (data) {
            if (data.foreignNationalString !== "No") {
              return true;
            }
          }
          return false;
        }
      },
      {
        fieldName: "borrowerCreditScore",
        fieldLabel: "Borrower Credit Score (Median)",
        fieldId: "borrowerCreditScore-fes",
        ruleFieldName: "originalCreditScoreMedian",
        fieldType: "numberNoFormat",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        optionalCondition: (data: any) => {
          if (data) {
            if (data.foreignNationalString !== "No") {
              return true;
            }
          }
          return false;
        }
      },
      {
        fieldName: "inState",
        fieldLabel: "In State",
        fieldId: "inState-fes",
        ruleFieldName: "inState",
        fieldType: "dropDown",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.oneFourFamily) {
            return false;
          }
          return true;
        },
        extraData: yesNoDropdown.map((item: any) => ({
          value: item === "Yes" ? "Y" : "N",
          label: item
        })),
        optionalCondition: false
      }
    ]
  },
  {
    sectionName: "fesLoanInformation",
    sectionLabel: "Loan Information",
    sectionId: "loanInformation-fes",
    fields: [
      {
        fieldName: "primaryLoanId",
        ruleFieldName: "primaryLoanId",
        fieldLabel: "Originator Loan Id",
        fieldType: "string",
        fieldId: "primaryLoanId-fes",
        optionalCondition: (data: any) => {
          return true; // not mandatory field
        }
      },
      {
        fieldName: "loanStructure",
        ruleFieldName: "loanStructure",
        fieldLabel: "Loan Structure",
        fieldType: "dropDown",
        fieldId: "loanStructure-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        },
        extraData: gUpFESLoanStructure.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "loanStructure",
        ruleFieldName: "loanStructure",
        fieldLabel: "Loan Structure",
        fieldType: "dropDown",
        fieldId: "loanStructure-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: loanStructureDropDown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "loanPurpose",
        fieldLabel: "Loan Purpose",
        ruleFieldName: "loanPurpose",
        fieldType: "dropDown",
        fieldId: "loanPurpose-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: loanPurposeDropDown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "cashoutFlag",
        fieldLabel: "Cash Out Flag",
        ruleFieldName: "cashOutFlag",
        fieldType: "dropDown",
        fieldId: "cashoutFlag-fes",
        extraData: cashOutNot.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "recourse",
        fieldLabel: "Recourse",
        ruleFieldName: "recourse",
        fieldType: "dropDown",
        fieldId: "recourse-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        extraData: multiFamilyRecourse.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "exitStrategy",
        fieldLabel: "Exit Strategy",
        ruleFieldName: "exitStrategy",
        fieldType: "dropDown",
        fieldId: "exitStrategy-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        },
        extraData: gUpFESExitStrategyOptions.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "exitStrategy",
        fieldLabel: "Exit Strategy",
        ruleFieldName: "exitStrategy",
        fieldType: "dropDown",
        fieldId: "exitStrategy-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        },
        extraData: exitStrategyDropDown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "pledgeOfEquity",
        fieldLabel: "Pledge Of Equity",
        ruleFieldName: "pledgeOfEquity",
        fieldType: "dropDown",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.oneFourFamily) {
            return false;
          }
          return true;
        },
        fieldId: "pledgeOfEquity-fes",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "loanTerm",
        fieldLabel: "Loan Term (Months)",
        fieldId: "fes-loanTerm",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldType: "number",
        ruleFieldName: "loanTermMonthly"
      },
      {
        fieldName: "loanTerm",
        fieldLabel: "Loan Term (Months)",
        fieldId: "fes-loanTerm",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        fieldType: "dropDown",
        ruleFieldName: "loanTermMonthly",
        extraData: gUpLoanTerm.map(({ value, label }: any) => ({
          value,
          label
        }))
      },
      {
        fieldName: "requiredInterestReservesAmount",
        fieldLabel: "Required Interest Reserves (Amount)($)",
        fieldType: "formula",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        rules: (inputs: any) => {
          const { loanEvaluationResult, loanId } = inputs;
          return formatValueByType(
            loanEvaluationResult?.loanResult &&
            loanEvaluationResult.loanResult[loanId]?.loanCharacterisations
              ?.requiredInterestReservesAmount,
            "currency"
          );
        },
        fieldId: "requiredInterestReserves-fes",
        ruleFieldName: "requiredInterestReserves"
      },
      {
        fieldName: "requiredInterestReservesMonth",
        fieldLabel: "Required Interest Reserves (Months)",
        fieldType: "formula",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        rules: (inputs: any) => {
          const { loanEvaluationResult, loanId } = inputs;
          return (
            loanEvaluationResult?.loanResult &&
            loanEvaluationResult.loanResult[loanId]?.loanCharacterisations
              ?.requiredInterestReservesMonth
          );
        },
        fieldId: "requiredInterestReservesMonth-fes",
        ruleFieldName: "requiredInterestReservesMonth"
      },
      {
        fieldName: "irFundingSource",
        fieldLabel: "IR funding source",
        ruleFieldName: "irFundingSource",
        fieldType: "dropDown",
        fieldId: "irFunding-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: irFundingSources.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "isPermitApprovalsAndFullPlansInPlace",
        fieldLabel: "Permit Approvals & Full Plans in Place",
        fieldType: "dropDown",
        fieldId: "permitApprovals-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      // {
      //   fieldName: "fundingType",
      //   fieldLabel: "Funding Type",
      //   ruleFieldName: "fundingType",
      //   fieldType: "dropDown",
      //   fieldId: "fundingtype-fes",
      //   extraData: fundingChannelDropDown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // }
    ]
  },
  {
    sectionName: "fesLoanEconomics",
    sectionLabel: "Loan Economics",
    sectionId: "loanEconomics-fes",
    fields: [
      {
        fieldName: "existDebt",
        fieldLabel: "Exist Debt ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldId: "existDebt-fes",
        ruleFieldName: "existDebt"
      },
      {
        fieldName: "hardCost",
        fieldLabel: "Hard Cost ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldId: "hardCost-fes",
        ruleFieldName: "hardCost"
      },
      {
        fieldName: "hardCost",
        fieldLabel: "Hard Costs ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        fieldId: "hardCost-fes",
        ruleFieldName: "hardCost"
      },
      {
        fieldName: "softCost",
        fieldLabel: "Soft Cost (cap at 15% Rehab)($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldId: "softCost-fes",
        ruleFieldName: "softCost"
      },
      {
        fieldName: "softCost",
        fieldLabel: "Soft Costs ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        fieldId: "softCost-fes",
        ruleFieldName: "softCost"
      },
      {
        fieldName: "rehabBudget",
        fieldLabel: "Total Rehab Budget ($)",
        fieldType: "formula",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        rules: (inputs: any) => {
          return totalRehabBudgeFormula(inputs);
        },
        fieldId: "rehabBudget-fes",
        ruleFieldName: "totalBudgetAmount"
      },
      {
        fieldName: "rehabBudget",
        fieldLabel: "Total Rehab Budget ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.oneFourFamily) {
            return false;
          }
          return true;
        },
        fieldId: "rehabBudget-fes",
        ruleFieldName: "totalBudgetAmount"
      },
      {
        fieldName: "originalLoanAmount",
        fieldLabel: "Initial Loan Amount ($)",
        fieldType: "currency",
        fieldId: "originalLoanAmount-fes"
      },
      {
        fieldName: "rehabAmount",
        fieldLabel: "Rehab Loan Amount ($)",
        fieldType: "formula",
        fieldId: "rehabAmount-fes",
        ruleFieldName: "rehabAmount",
        rules: (inputs: any) => {
          return totalRehabBudgeFormula(inputs);
        },
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "interestReserve",
        fieldLabel: "Interest Reserve ($)",
        fieldType: "currency",
        fieldId: "interestReserve-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "totalOriginationAndDiscountPoints",
        fieldLabel: "Total Origination and Discount Points (%)",
        fieldType: "percentage",
        fieldId: "totalOriginationAndDiscountPoints-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        }
      },
      {
        fieldName: "totalOriginationAndDiscountPoints",
        fieldLabel: "Origination & Broker Fees (%)",
        fieldType: "dropDown",
        fieldId: "totalOriginationAndDiscountPoints-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: closingCostValues.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "interestRate",
        fieldLabel: "Interest Rate (%)",
        fieldId: "fes-interestRate",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.oneFourFamily) {
            return false;
          }
          return true;
        },
        fieldType: "percentage",
        ruleFieldName: "interestRate"
      },
      {
        fieldName: "includeOutOfPocketBudgetInARLTV",
        fieldLabel: "Include Out Of Pocket Budget In ARLTV",
        fieldId: "fes-includeOutOfPocketBudgetInARLTV",
        hideCondition: (data: any) => !isLATUser(),
        fieldType: "checkbox"
      }
    ]
  }
];

export const fesBridgeProperty: Array<Section> = [
  {
    sectionName: "fesPropertyInformation",
    sectionLabel: "Property Information",
    sectionId: "propertyInformation-fes",
    fields: [
      {
        fieldName: "propertyType",
        fieldLabel: "Property Type",
        fieldType: "dropDown",
        fieldId: "propertyType-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType !== LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        extraData: oneFourFamilyPropertyTypeDropDown.map((item: any) => ({
          value: item,
          label: item === "SFR" ? "Single Family Detached" : item
        }))
      },
      {
        fieldName: "propertyType",
        fieldLabel: "Property Type",
        fieldType: "dropDown",
        fieldId: "propertyType-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        extraData: multiFamMixedUsePropertyTypeDropDown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "numberOfUnits",
        fieldLabel: "Number Of Units",
        fieldType: "numberNoFormat",
        fieldId: "numberOfUnits-fes",
        ruleFieldName: "propertyUnits"
      },
      {
        fieldName: "changeInUseCase",
        fieldLabel: "Expansion (>20%/+750 sq.ft) or Change Of Use Case",
        fieldType: "dropDown",
        fieldId: "changeInUseCase-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        },
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "valuationSource",
        fieldLabel: "Valuation Source",
        fieldType: "dropDown",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return true;
          }
          return false;
        },
        fieldId: "valuationSource-fes",
        extraData: valuationSource.map(item => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "valuationSource",
        fieldLabel: "Valuation Source",
        fieldType: "dropDown",
        fieldId: "valuationSource-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        extraData: multiFamMixedUsevaluationSource.map(item => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "isPropertyPurchasedLast2Years",
        fieldLabel: "Property purchased within last 2 years",
        fieldType: "dropDown",
        fieldId: "purchasedInlastTwoYears-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "isEntitledLand",
        fieldLabel: "Entitled Land / As of Right Development",
        fieldType: "dropDown",
        fieldId: "entitledLand-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      }
    ]
  },
  {
    sectionName: "fesPropertyEconomics",
    sectionLabel: "Property Economics",
    sectionId: "propertyEconomics-fes",
    fields: [
      {
        fieldName: "originalAsIsAppraisalValue",
        fieldLabel: 'Original "As Is" Appraisal Value ($)',
        fieldType: "currency",
        fieldId: "originalAsIsAppraisalValue-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        }
      },
      {
        fieldName: "originalAsIsAppraisalValue",
        fieldLabel: "As-Is Value ($)",
        fieldType: "currency",
        fieldId: "originalAsIsAppraisalValue-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "purchasePrice",
        fieldLabel: "Purchase Price ($)",
        fieldType: "currency",
        fieldId: "purchasePrice-fes"
      },
      {
        fieldName: "closingCost",
        fieldLabel: "Closing Costs (%)",
        fieldId: "fes-closingCosts",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return true;
          }
          return false;
        },
        fieldType: "dropDown",
        extraData: closingCostValues.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "borrowerPaidCosts", // new   field
        fieldLabel: "Borrower Paid costs to date",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldId: "borrowerPaidCosts-fes"
      },
      {
        fieldName: "originalAsRepairedAppraisedValue",
        fieldLabel: "As Repaired Value (ARV) ($)",
        fieldType: "currency",
        ruleFieldName: "originalAsRepairedAppraisedValue",
        fieldId: "originalAsRepairedAppraisedValue-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          return false;
        }
      },
      {
        fieldName: "originalAsRepairedAppraisedValue",
        fieldLabel: "After-Repair Value ($)",
        fieldType: "currency",
        ruleFieldName: "originalAsRepairedAppraisedValue",
        fieldId: "originalAsRepairedAppraisedValue-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        }
      },
      {
        fieldName: "grossPotentialRent",
        fieldLabel: "Gross Potential Rent (Yearly) ($)",
        fieldType: "currency",
        fieldId: "grossPotentialRent-fes",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return true;
          }
          if(isNotStabilised(data)) {
            return true;
          }
          return false;
        }
      },
      {
        fieldName: "appraisalReportedNoiNcf",
        fieldLabel: "Appraisal Reported NOI/NCF ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldId: "appraisalReportedNoiNcf-fes"
      },
      {
        fieldName: "annualPropertyTaxes",
        fieldLabel: "Annual Property Taxes ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          if (isNotStabilised(data)) {
            return true;
          }
          return true;
        },
        fieldId: "annualPropertyTaxes-fes"
      },
      {
        fieldName: "insurance",
        fieldLabel: "Insurance ($)",
        fieldType: "currency",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          if (isNotStabilised(data)) {
            return true;
          }
          return true;
        },
        fieldId: "insurance-fes"
      },
      {
        fieldName: "costBasis",
        fieldLabel: "Cost Basis (After Cost Incurred) ($)", // show in 1-4family loan
        fieldType: "formula",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.oneFourFamily) {
            return false;
          }
          return true;
        },
        rules: (inputs: any) => {
          return costBasisFormulaWithProperties(inputs);
        },
        fieldId: "costBasis-fes"
      },
      {
        fieldName: "costBasis",
        fieldLabel: "Cost Basis (After Cost Incurred) ($)", // show in multifamilyMixedUse loan
        fieldType: "formula",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        rules: (inputs: any) => {
          return costBasisFormulaWithProperties(inputs);
        },
        fieldId: "costBasis-fes"
      },
      {
        fieldName: "grossCondoSelloutValue", // new field
        fieldLabel: "Gross Condo Sellout Value ($)",
        optionalCondition: (data: any) => {
          return true; // not mandatory field
        },
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
            return false;
          }
          return true;
        },
        fieldType: "currency",
        fieldId: "grossCondoSellout-fes"
      },
      {
        fieldName: "marketRentPerMonth",
        fieldLabel: "Market Rent per month (if applicable) ($)",
        hideCondition: (data: any) => {
          const { loanSizerType } = data;
          if (loanSizerType === LoanSizerEnum.groundUp) {
            return false;
          }
          return true;
        },
        fieldType: "currency",
        fieldId: "marketRent-fes"
      },
      {
        fieldName: "assignmentFees",
        fieldLabel: "Assignment Fee ($)",
        fieldType: "currency",
        fieldId: "assignmentFee-fes"
      }
    ]
  }
];

export const fesThirtyYearLoan: Array<Section> = [
  {
    sectionName: "fesBorrowerInformation",
    sectionLabel: "Borrower information",
    sectionId: "fes-borrowerInformation",
    fields: [
      {
        fieldName: "borrowerCreditScore",
        fieldLabel: "Credit score- Actual",
        fieldId: "fes-CreditscoreActual",
        fieldType: "numberNoFormat",
        ruleFieldName: "originalCreditScoreMedian",
        optionalCondition: (data: any) => {
          if (data) {
            if (data.foreignNationalString === "Yes") {
              return true;
            }
          }
          return false;
        }
      },
      {
        fieldName: "foreignNationalString",
        fieldLabel: "Foreign National Flag(Borrower or Guarantor)",
        fieldId: "fes-foreignNationalFlag",
        ruleFieldName: "foreignNationalString",
        fieldType: "dropDown",
        extraData: foreignNationalOptions.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "borrowerType",
        fieldLabel: "Borrower Type",
        fieldId: "fes-borrowerType",
        fieldType: "dropDown",
        extraData: borrowerType.map(item => ({
          value: item,
          label: item
        }))
      }
    ]
  },
  {
    sectionName: "fesLoanInformation",
    sectionLabel: "Loan Information",
    sectionId: "fes-loanInformation",
    fields: [
      {
        fieldName: "primaryLoanId",
        ruleFieldName: "primaryLoanId",
        fieldLabel: "Originator Loan Id",
        fieldType: "string",
        fieldId: "primaryLoanId-fes",
        optionalCondition: (data: any) => {
          return true; // not mandatory field
        }
      },
      {
        fieldName: "expectedClosingDate",
        fieldLabel: "Expected Date of Closing",
        fieldId: "fes-expectedClosingDate",
        fieldType: "date-time",
        ruleFieldName: "expectedClosingDate"
      },
      {
        fieldName: "borrowerProceeds",
        fieldLabel: "Cash Out Refinance",
        fieldId: "fes-borrowerProceeds",
        fieldType: "dropDown",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "numberOfProperties",
        fieldLabel: "Number of Properties",
        fieldId: "fes-numberOfProperties",
        fieldType: "numberNoFormat",
        ruleFieldName: "noOfProperties"
      },
      {
        fieldName: "predominantState",
        fieldLabel: "Predominant State",
        fieldId: "fes-predominantState",
        fieldType: "string",
        hideCondition: (data: any) => {
          const { loanInformation } = data;
          return !(loanInformation?.crossCollaterlization === "Y" && loanInformation?.numberOfProperties > 1)
        }
      },
      {
        fieldName: "predominantPropertyType",
        fieldLabel: "Predominant Property Type",
        fieldId: "fes-predominantPropertyType",
        fieldType: "dropDown",
        extraData: predominantPropertyType.map((item: any) => ({
          value: item,
          label: item === "SFR" ? "Single Family Detached" : item
        })),
        hideCondition: (data: any) => {
          const { loanInformation } = data;
          return !(loanInformation?.crossCollaterlization === "Y" && loanInformation?.numberOfProperties > 1)
        }
      },
      {
        fieldName: "condoEligibility",
        fieldLabel: "Condo Eligibility",
        fieldId: "fes-condoEligibility",
        fieldType: "dropDown",
        extraData: condoEligibilityDropDownFes.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "loanTerm",
        fieldLabel: "Loan Term (Months)",
        fieldId: "fes-loanTerm",
        fieldType: "numberNoFormat",
        ruleFieldName: "loanTermMonthly"
      },
      {
        fieldName: "crossCollaterlization",
        fieldLabel: "Cross Collaterlization",
        fieldType: "radio",
        fieldId: "crossCollaterlization-fes",
        optionalCondition: () => true,
        extraData: crossCollaterlizationData,
        hideCondition: (data: any) => {
          const { loanInformation } = data;
          return (loanInformation?.numberOfProperties <= 1)
        }
      },
      // {
      //   fieldName: "fundingType",
      //   fieldLabel: "Funding Type",
      //   ruleFieldName: "fundingType",
      //   fieldType: "dropDown",
      //   fieldId: "fundingtype-fes",
      //   extraData: fundingChannelDropDown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      {
        fieldName: "creditEvent",
        fieldLabel: "FC/SS/DIL/BK7 36 - 47mo",
        fieldType: "dropDown",
        fieldId: "creditEvent-fes",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      }
      // {
      //   fieldName: "recourse",
      //   fieldLabel: "Recourse",
      //   ruleFieldName: "recourse",
      //   fieldType: "dropDown",
      //   fieldId: "recourse-fes",
      //   extraData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "originationDate",
      //   fieldLabel: "Origination Date",
      //   fieldId: "fes-originationDate",
      //   fieldType: "date-time"
      // }
    ]
  },
  {
    sectionName: "fesLoanEconomics",
    sectionLabel: "Loan Economics",
    sectionId: "fes-loanEconomics",
    fields: [
      {
        fieldName: "originalLoanAmount", // used in requestObject
        fieldLabel: "Borrower Requested ($)",
        fieldId: "fes-borrowerRquested",
        fieldType: "currency"
      },
      {
        fieldName: "interestRate",
        fieldLabel: "Original Interest Rate (%)",
        fieldId: "fes-interestRate",
        fieldType: "percentage",
        ruleFieldName: "interestRate"
      },
      {
        fieldName: "prepaymentPenalty",
        fieldLabel: "Prepayment Penalty (Months)",
        fieldId: "fes-prepaymentPenalty",
        fieldType: "dropDown",
        ruleFieldName: "prepaymentPenaltyMonths",
        extraData: prepaymentPenalty.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "prepayPenaltyType",
        fieldLabel: "Prepay Penalties Type",
        fieldId: "fes-prepayPenaltyType",
        fieldType: "dropDown",
        extraData: prepaymentPenaltyTypeDropDown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "rateType",
        fieldLabel: "Rate Type",
        fieldId: "fes-rateType",
        fieldType: "dropDown",
        extraData: rateType
          .filter(it => it !== "Adjustable")
          .map(item => ({
            value: item,
            label: item
          }))
      },
      {
        fieldName: "interestOnlyPeriod",
        fieldLabel: "Interest Only Period (Months)",
        fieldId: "fes-interestOnlyPeriod",
        fieldType: "numberNoFormat"
      },
      {
        fieldName: "loanLevelOccupancy",
        fieldLabel: "Loan Level Occupancy Percentage",
        fieldType: "formula",
        rules: (inputs: any) => {
          const { loanEvaluationResult, loanId } = inputs;
          return formatValueByType(
            loanEvaluationResult?.loanResult &&
            loanEvaluationResult.loanResult[loanId]?.loanEconomics
              ?.loanLevelOccupancy,
            "percentage"
          );
        },
        fieldId: "loanLevelOccupancy-fes",
        ruleFieldName: "loanLevelOccupancy"
      }
    ]
  }
];

export const fesThirtyYearProperty: Array<Section> = [
  {
    sectionName: "fesPropertyInformation",
    sectionLabel: "Property Information",
    sectionId: "fes-propertyInformation",
    fields: [
      // {
      //   fieldName: "propertyCondition",
      //   fieldLabel: "Property Condition",
      //   fieldId: "fes-propertyCondition",
      //   fieldType: "dropDown",
      //   extraData: propertyCondition.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      {
        fieldName: "squareFootage",
        fieldLabel: "Property Square Footage",
        fieldId: "fes-squareFootage",
        fieldType: "numberNoFormat",
        ruleFieldName: "squareFootage"
      },
      {
        fieldName: "propertyType",
        fieldLabel: "Property Type",
        fieldId: "fes-ropertyType",
        fieldType: "dropDown",
        extraData: propertyTypeThirtyYearFes.map((item: any) => ({
          value: item,
          label: item === "SFR" ? "Single Family Detached" : item
        }))
      },
      {
        fieldName: "propertyOriginallyAcquired",
        fieldLabel: "Original Date of Acquisition",
        fieldId: "fes-propertyOriginallyAcquired",
        fieldType: "date-time",
        ruleFieldName: "propertyAcquisitionDate"
      },
      {
        fieldName: "propertyValuationDate",
        fieldLabel: "Most Recent Property Valuation Date",
        fieldId: "fes-propertyValuationDate",
        fieldType: "date-time",
        ruleFieldName: "recentPropertyValuationDate"
      },
      {
        fieldName: "isPropertyOnDebt",
        fieldLabel: "Property with Debt",
        fieldId: "fes-isPropertyOnDebt",
        ruleFieldName: "isPurchasedWithDebt",
        fieldType: "dropDown",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "numberOfUnits",
        fieldLabel: "Number of Units",
        fieldId: "fes-umberOfUnits",
        fieldType: "numberNoFormat",
        ruleFieldName: "propertyUnits"
      },
      {
        fieldName: "% Occupied",
        fieldLabel: "% Occupied",
        fieldType: "formula",
        fieldId: "fes-percentageOccupied",
        ruleFieldName: "propertyUnits",
        rules: (inputs: any) => {
          const { loanEvaluationResult, loanId } = inputs;
          return formatValueByType(
            loanEvaluationResult?.loanResult &&
            loanEvaluationResult.loanResult[loanId]?.loanEconomics
              ?.loanLevelOccupancy,
            "percentage"
          );
        }
      }
    ]
  },
  {
    sectionName: "unitInformation",
    sectionLabel: "Unit Information",
    sectionId: "fes-unitInformation",
    fields: [
      /** Below fields commented as part of LPP-5430, not to be removed till story is marked done. */
      // {
      //   fieldName: "leaseStartDate",
      //   fieldId: "leaseStartDate",
      //   editedLabel: "leaseStartDate",
      //   reduxFieldName: "leaseStartDate",
      //   fieldLabel: "Lease Start Date",
      //   fieldType: "date"
      // },
      // {
      //   fieldName: "leaseEndDate",
      //   fieldId: "leaseEndDate",
      //   editedLabel: "leaseEndDate",
      //   reduxFieldName: "leaseEndDate",
      //   fieldLabel: "Lease End Date",
      //   fieldType: "date"
      // },
      // {
      //   fieldName: "monthlyLease",
      //   fieldId: "monthlyLease",
      //   editedLabel: "monthlyLease",
      //   reduxFieldName: "monthlyLease",
      //   fieldLabel: "Monthly Lease",
      //   fieldType: "dropDown",
      //   dropDownData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "leaseDurationGreaterThan12M",
      //   fieldId: "leaseDurationGreaterThan12M",
      //   reduxFieldName: "leaseDurationGreaterThan12M",
      //   editedLabel: "leaseDurationGreaterThan12M",
      //   fieldLabel: "Lease Duration >= 12 Months",
      //   fieldType: "dropDown",
      //   ruleFieldName: "leaseDurationMorethan12Mos",
      //   dropDownData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "leaseDurationLessThan12M",
      //   fieldId: "leaseDurationLessThan12M",
      //   editedLabel: "leaseDurationLessThan12M",
      //   reduxFieldName: "leaseDurationLessThan12M",
      //   fieldLabel: "Lease Duration < 12 Months",
      //   fieldType: "dropDown",
      //   ruleFieldName: "leaseDurationLessthan12Mos",
      //   dropDownData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "vacancyStatus",
      //   fieldId: "vacancyStatus",
      //   editedLabel: "vacancyStatus",
      //   reduxFieldName: "vacancyStatus",
      //   fieldLabel: "Vacancy Status",
      //   fieldType: "dropDown",
      //   dropDownData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "unitCategory",
      //   fieldId: "unitCategory",
      //   reduxFieldName: "unitCategory",
      //   editedLabel: "unitCategory",
      //   fieldLabel: "Is the unit not categorised in any buckets above",
      //   fieldType: "dropDown",
      //   dropDownData: yesNoDropdown.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      {
        fieldName: "rentalCharacterization",
        fieldId: "rentalCharacterization",
        reduxFieldName: "leaseStatus",
        fieldLabel: "Rental Characterisation",
        editedLabel: "leaseStatus",
        fieldType: "dropDown",
        dropDownData: rentDescription.map((item: any) => ({
          value: item,
          label: item
        })),
        ruleFieldName: "leaseStatus"
      },
      {
        fieldName: "currentLeaseTerm",
        fieldId: "currentLeaseTerm",
        editedLabel: "currentLeaseTermMonthly",
        reduxFieldName: "currentLeaseTermMonthly",
        fieldLabel: "Current Lease Term(Months)",
        fieldType: "number",
        requiredLeaseStatus: [
          LeaseStatusEnum.occupiedLongTerm,
          LeaseStatusEnum.unOccupiedShortTerm
        ],
        optionalCondition: (data: any) => {
          if (data) {
            if (
              data.unitInformation?.leaseStatus ===
              LeaseStatusEnum.unOccupiedShortTerm
            ) {
              return true;
            }
          }
          return false;
        },
        ruleFieldName: "currentLeaseTermMonths"
      },
      {
        fieldName: "marketRentMonthly",
        fieldId: "marketRentMonthly",
        editedLabel: "marketRentMonthly",
        reduxFieldName: "marketRentMonthly",
        fieldLabel: "Market Rent Monthly ($)",
        fieldType: "currency",
        ruleFieldName: "marketRentMonthly",
        optionalCondition: (data: any) => {
          if (data) {
            if (
              data.unitInformation?.leaseStatus ===
              LeaseStatusEnum.occupiedShortTerm
            ) {
              return true;
            }
          }
          return false;
        }
      },
      {
        fieldName: "inPlaceLeaseRent",
        fieldId: "inPlaceLeaseRent",
        editedLabel: "inPlaceLeaseRentMonthly",
        reduxFieldName: "inPlaceLeaseRent",
        fieldLabel: "In Place Lease Rent Monthly ($)",
        fieldType: "currency",
        optionalCondition: (data: any) => {
          if (data) {
            if (
              data.unitInformation?.leaseStatus ===
              LeaseStatusEnum.unOccupiedShortTerm
            ) {
              return true;
            }
          }
          return false;
        },
        requiredLeaseStatus: [
          rentDescriptionOld[0],
          rentDescriptionOld[1],
          rentDescriptionOld[2],
          LeaseStatusEnum.occupiedLongTerm,
          LeaseStatusEnum.unOccupiedShortTerm
        ],
        ruleFieldName: "inPlaceLeaseRentMonthly"
      },
      {
        fieldName: "recentLeaseRent",
        fieldId: "recentLeaseRent",
        reduxFieldName: "recentLeaseRent",
        editedLabel: "mostRecentLeaseRentMonthly",
        fieldLabel: "Net Monthly Operating Income ($)",
        fieldType: "currency",
        requiredLeaseStatus: [
          rentDescriptionOld[1],
          rentDescriptionOld[2],
          LeaseStatusEnum.occupiedShortTerm
        ],
        ruleFieldName: "mostRecentLeaseRentMonthly"
      },
      {
        fieldName: "percentageOccupied",
        fieldId: "percentageOccupied",
        reduxFieldName: "percentageOccupied",
        fieldLabel: "% Unit Occupied",
        fieldType: "formula",
        rules: (inputs: any) => {
          return percentageOccupiedFormula(inputs);
        },
        ruleFieldName: "percentageOccupied"
      },
      {
        fieldName: "monthlyGrossRent",
        fieldId: "monthlyGrossRent",
        reduxFieldName: "monthlyGrossRent",
        fieldLabel: "Monthly Unit Rent ($)",
        fieldType: "ruleOutput",
        getValues: (inputs: any) => {
          const {
            unitInformation,
            loanEvaluationResult,
            activePropertyId
          } = inputs;
          try {
            const { unitId } = unitInformation;
            const propertyResult =
              loanEvaluationResult?.propertiesResults[activePropertyId];
            if (propertyResult) {
              const unitResult = propertyResult[`unit${unitId}`];
              return formatValueByType(
                `${unitResult?.monthlyGrossRent}`,
                "currency"
              );
            }
          } catch (error) {
            console.log(error);
            return null;
          }
          return null;
        }
      }
    ]
  },
  {
    sectionName: "fesPropertyEconomics",
    sectionLabel: "Property Economics",
    sectionId: "fes-propertyEconomics",
    fields: [
      {
        fieldName: "originalAsIsAppraisalValue",
        fieldLabel: "As Is Valuation ($)",
        fieldId: "fes-originalAsIsAppraisalValue",
        fieldType: "currency"
      },
      {
        fieldName: "thirdPartyValuationStatus",
        fieldLabel: "Third Party Valuation - Status",
        fieldType: "dropDown",
        fieldId: "thirdPartyValuationStatus-fes",
        extraData: thirdPartyValuationStatus.map((item: any) => ({
          value: item,
          label: item
        }))
      },
      {
        fieldName: "thirdPartyValuation",
        fieldLabel: "Third Party Valuation - Property Value ($)",
        fieldId: "fes-thirdPartyValuation",
        ruleFieldName: "thirdPartyValuation",
        fieldType: "currency"
      },
      {
        fieldName: "purchasePrice", // acquisition size is same as purchaseprice in back end
        fieldLabel: "Property Acquisition Price ($)",
        fieldId: "fes-acquisitionPrice",
        fieldType: "currency"
      },
      // {
      //   fieldName: "closingCost",
      //   fieldLabel: "Closing Costs (%)",
      //   fieldId: "fes-closingCosts",
      //   fieldType: "dropDown",
      //   extraData: closingCostValues.map((item: any) => ({
      //     value: item,
      //     label: item
      //   }))
      // },
      // {
      //   fieldName: "additionalEligibleCost",
      //   fieldLabel: "Additional Eligible Cost (Net Closing Cost) ($)",
      //   fieldId: "fes-additionalEligibleCost",
      //   fieldType: "currency"
      // },
      {
        fieldName: "annualPropertyTaxes",
        fieldLabel: "Annual Property Taxes ($)",
        fieldId: "fes-annualPropertyTaxes",
        fieldType: "currency"
      },
      {
        fieldName: "annualHazardInsurance",
        fieldLabel: "Annual Hazard Insurance ($)",
        fieldId: "fes-annualHazardInsurance",
        fieldType: "currency"
      },
      {
        fieldName: "annualFloodInsurance",
        fieldLabel: "Annual Flood Insurance ($)",
        fieldId: "fes-annualFloodInsurance",
        fieldType: "currency"
      },
      {
        fieldName: "annualHOAFee",
        fieldLabel: "Annual HOA Fee ($)",
        fieldId: "fes-annualHOAFee",
        ruleFieldName: "annualHoaFee",
        fieldType: "currency"
      },
      {
        fieldName: "decliningMarkets",
        fieldLabel: "Declining Markets",
        fieldType: "dropDown",
        fieldId: "declining-markets",
        extraData: yesNoDropdown.map((item: any) => ({
          value: item,
          label: item
        }))
      }
    ]
  }
];
export const fesThirtyYearCondominium: any = [
  {
    fieldName: "borrowerName",
    fieldLabel: "Borrower Name",
    fieldId: "fes-borrowerName",
    fieldType: "string",
    viewModeOnly: true
  },
  {
    fieldName: "condoProjectName",
    fieldLabel: "Condo Project Name",
    fieldId: "fes-condoProject",
    fieldType: "string",
    viewModeOnly: false
  },
  {
    fieldName: "streetAddressLine1",
    fieldLabel: "Condo Street Address",
    fieldId: "fes-streetAddress",
    fieldType: "string",
    viewModeOnly: false
  },

  {
    fieldName: "noOfUnits",
    fieldLabel: "Unit #",
    fieldId: "fes-unit",
    fieldType: "number",
    viewModeOnly: false
  },
  {
    fieldName: "city",
    fieldLabel: "City",
    fieldId: "fes-city",
    fieldType: "string",
    viewModeOnly: false
  },
  {
    fieldName: "state",
    fieldLabel: "State",
    fieldId: "fes-state",
    fieldType: "string",
    viewModeOnly: false
  },
  {
    fieldName: "postalCode",
    fieldLabel: "Zip",
    fieldId: "fes-zip",
    fieldType: "number",
    viewModeOnly: false
  }
];

export const fesThirtyYearCondominiumQuestion: any = [
  {
    fieldName: "monthlyHOAFee",
    fieldLabel: "Monthly HOA Fess assessed on Unit # above ($)",
    fieldId: "fes-monthlyHOAFee",
    fieldType: "currency"
  },
  {
    fieldName: "hoaDuesAboveFifteen",
    fieldLabel: "HOA assessment Dues Over 15% ",
    fieldId: "fes-hoaDuesAboveFifteen",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "hoaDuesAboveTwenty",
    fieldLabel: "HOA assessment Dues Over 20%",
    fieldId: "fes-hoaDuesAboveTwenty",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "reserveFundsForOneYear",
    fieldLabel: "Current Reserve Funds available for 1 year ",
    fieldId: "fes-reserveFundsForOneYear",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "reserveFundsForTwoYears",
    fieldLabel: "Current Reserve Funds available for 2 year ",
    fieldId: "fes-reserveFundsForTwoYears",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "isProjectCompleted",
    fieldLabel: "Is the Project 100% complete? ",
    fieldId: "fes-isProjectCompleted",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "subjectToAdditionalPhasing",
    fieldLabel: "Is the project subject to additional phasing or annexation?",
    fieldId: "fes-subjectToAdditionalPhasing",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "soldPercentage",
    fieldLabel: "What percentage of units have been sold to owners?",
    fieldId: "fes-soldPercentage",
    fieldType: "percentage",
    required: true
  },
  {
    fieldName: "hoaUnderOwnerControl",
    fieldLabel: "Has control of the HOA been turned over to the unit owners?",
    fieldId: "fes-hoaUnderOwnerControl",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "simpleEstateOwnership",
    fieldLabel: "Are all units in the projects owned as fee simple estates?",
    fieldId: "fes-simpleEstateOwnership",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "ownerControlStartDate",
    fieldLabel: "If Yes when?",
    fieldId: "fes-ownerControlStartDate",
    fieldType: "date-time",
    dependentQues: "hoaUnderOwnerControl"
  },
  {
    fieldName: "emptyGrid",
    fieldLabel: "emptyGrid",
    fieldId: "fes-emptyGrid",
    fieldType: "emptyGrid",
    dependentQues: "hoaUnderOwnerControl"
  },

  {
    fieldName: "ownerOccupiedUnits",
    fieldLabel: "Owner Occupied (#)",
    fieldId: "fes-ownerOccupiedUnits",
    fieldType: "number"
  },
  {
    fieldName: "renterOccupiedUnits",
    fieldLabel: "Renter Occupied (#)",
    fieldId: "fes-renterOccupiedUnits",
    fieldType: "number"
  },
  {
    fieldName: "renterOccupiedPercentage",
    fieldLabel: "% Renter Occupied",
    fieldId: "fes-renterOccupiedPercentage",
    fieldType: "percentage"
  },
  {
    fieldName: "individualOwnershipAboveTwentyFive",
    fieldLabel:
      "Does an individual or single entity own more than 25% of total units?",
    fieldId: "fes-individualOwnershipAboveTwentyFive",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "emptyGrid",
    fieldLabel: "emptyGrid",
    fieldId: "fes-emptyGrid",
    fieldType: "emptyGrid",
    dependentQues: "individualOwnershipAboveTwentyFive"
  },
  {
    fieldName: "indivdualOwnedUnits",
    fieldLabel: "If Yes, how many units are owned?",
    fieldId: "fes-indivdualOwnedUnits",
    fieldType: "number",
    dependentQues: "individualOwnershipAboveTwentyFive"
  },

  {
    fieldName: "masterAssurancePolicy",
    fieldLabel:
      "Does the Condominium Association maintain a master insurance policy?",
    fieldId: "fes-masterAssurancePolicy",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "projectHasMobileHomes",
    fieldLabel:
      "Does the project contain any of the following? Mobile homes, timeshares (or property subject to rental pooling agreements), co-ops, common interest or community apartments (properties are owned in common with the right to lease individual units) or houseboats ",
    fieldId: "fes-projectHasMobileHomes",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "otherSourcesIncomeMoreThanTwenty",
    fieldLabel:
      "Other than association fee income, does the condo generate revenue from other sources? If Yes, does the income represent >20% of total associated income?",
    fieldId: "fes-otherSourcesIncomeMoreThanTwenty",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "commercialPurposesAreaMoreThanForty",
    fieldLabel:
      "Is any portion of the condo project used for commercial purposes? If Yes, does the area represent >40% of total square footage?",
    fieldId: "fes-commercialPurposesAreaMoreThanForty",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "hoawithLitOrBankruptcy",
    fieldLabel:
      "Has the Homeowner's Association been named as party to any litigation or bankruptcy?",
    fieldId: "fes-hoawithLitOrBankruptcy",
    fieldType: "radio",
    required: true
  },

  {
    fieldName: "documentsWithSEC",
    fieldLabel:
      "Does the project have any documents on file with the U.S. Securities and Exchange Commission?",
    fieldId: "fes-documentsWithSEC",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "hoawithLitOrBankruptcyDesc",
    fieldLabel: "If Yes, please describe under separate cover",
    fieldId: "fes-hoawithLitOrBankruptcyDesc",
    fieldType: "string",
    dependentQues: "hoawithLitOrBankruptcy"
  },
  {
    fieldName: "emptyGrid",
    fieldLabel: "emptyGrid",
    fieldId: "fes-emptyGrid",
    fieldType: "emptyGrid",
    dependentQues: "hoawithLitOrBankruptcy"
  },
  {
    fieldName: "restrictOwnerToRent",
    fieldLabel:
      "Does the project restrict the owner's ability to rent the unit?",
    fieldId: "fes-restrictOwnerToRent",
    fieldType: "radio",
    required: true
  },
  {
    fieldName: "emptyGrid",
    fieldLabel: "emptyGrid",
    fieldId: "fes-emptyGrid",
    fieldType: "emptyGrid"
  },
  {
    fieldName: "restrictOwnerToRentOutDesc",
    fieldLabel: "If Yes, please describe under separate cover",
    fieldId: "fes-restrictOwnerToRentOutDesc",
    fieldType: "string",
    dependentQues: "restrictOwnerToRent"
  },
  {
    fieldName: "emptyGrid",
    fieldLabel: "emptyGrid",
    fieldId: "fes-emptyGrid",
    fieldType: "emptyGrid",
    dependentQues: "restrictOwnerToRent"
  }
];

/** ------------------------------- Formulas Start------------------------------- */
// This formula is to be used when full property array is passed
export const costBasisFormulaWithProperties = (
  data: any,
  dontDoConversion?: boolean
) => {
  try {
    const { loanSizerType, loanDetails, activePropertyId } = data;
    const { propertyDetails } = loanDetails;
    const propertyObject = propertyDetails.find(
      (ele: any) => ele.propertyId === activePropertyId
    );
    return costBasisFormula(propertyObject, loanSizerType, false);
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const costBasisFormula = (
  propertyObject: any,
  loanSizerType: any,
  dontDoConversion?: boolean
) => {
  if (loanSizerType === LoanSizerEnum.oneFourFamily) {
    const closingCosts = propertyObject.fesPropertyEconomics.closingCost;
    const purchasePrice = currencyToNumber(
      propertyObject.fesPropertyEconomics.purchasePrice
    );
    if (!isEmptyValue(closingCosts) && !isEmptyValue(purchasePrice)) {
      const value =
        ((Number(closingCosts) + 100) * Number(purchasePrice)) / 100;
      return dontDoConversion
        ? value
        : formatValueByType(value.toString(), "currency");
    }
  }
  if (loanSizerType === LoanSizerEnum.multifamilyMixedUse) {
    const borrowerPaidCosts = currencyToNumber(
      propertyObject.fesPropertyEconomics.borrowerPaidCosts
    );
    const purchasePrice = currencyToNumber(
      propertyObject.fesPropertyEconomics.purchasePrice
    );
    if (!isEmptyValue(borrowerPaidCosts) && !isEmptyValue(purchasePrice)) {
      const value = Number(borrowerPaidCosts) + Number(purchasePrice);
      return dontDoConversion
        ? value
        : formatValueByType(value.toString(), "currency");
    }
  }
  return null;
};
export const totalRehabBudgeFormula = (
  data: any,
  dontDoConversion?: boolean
) => {
  try {
    const { loanDetails } = data;
    const hardCost = currencyToNumber(
      loanDetails?.fesLoanEconomics?.hardCost ?? ""
    );
    const softCost = currencyToNumber(
      loanDetails?.fesLoanEconomics?.softCost ?? ""
    );
    if (!isEmptyValue(hardCost) && !isEmptyValue(softCost)) {
      const value = Number(hardCost) + Number(softCost);
      return dontDoConversion
        ? value
        : formatValueByType(value.toString(), "currency");
    }
  } catch (error) {
    console.log(error);
  }
  return "";
};

/** ------------------------------- Formulas End------------------------------- */