import { TextField } from "@mui/material";
import React from "react";
import { DropdownWithTooltip } from "./FieldsPanel/DropdownWithTooltip";
import { BudgetDescription } from "./FieldsPanel/BudgetDescription";
import { HtmlWhiteTooltip } from "../utils/Tooltip";
import { availableOptionsInterface } from "../utils/DropDownCommon";

export const isString = (value: unknown): value is string => {
  return typeof value === "string" || value instanceof String;
};

export function convertNumberToUSD(input: number | string): string {
  const number = typeof input === "number" ? input : parseFloat(input);
  if (isNaN(number)) {
    return "";
  }
  const fixedNumber = number.toFixed(2);
  const parts = fixedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${parts.join(".")}`;
}

export function convertUSDToNumber(usdString: string): number {
  if (isString(usdString)) {
    const numberString = usdString.replace(/[$,]/g, "");
    const number = parseFloat(numberString);
    return number; // Return the number
  }
  return usdString;
}

interface ConfigData {
  budgetUnitOptions?: string[];
  row: {
    unit?: string;
    item: string;
    budgetLineItemId: number;
    oldBudget?: string;
    budget: string;
  };
  isDisabled: boolean;
  budgetClasses?: any;
  showUnitField?: boolean;
  setPopUpOpen?: (value: boolean) => void;
  isPropertyMapped?: boolean;
  textareaRefs?: any;
  fieldsData?: any;
  setFieldsData?: any;
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    field: string
  ) => void;
  handleItemOnBlur: () => void;
  // BudgetDescription?: any;
  budgetButtonInfo?: {
    financedBudgetPercentage: number;
  };
  handleDataFormat?: (id: number, field: string) => void;
}

const getValue = (val: string | number) => {
  const maxLen = window.innerWidth > 1250 ? 12 : 10;
  return String(val).length > maxLen
    ? `${String(val).slice(0, maxLen)}...`
    : String(val);
};

export const ConfigItems = () => {
  return {
    unit: {
      value: "Unit",
      renderCustom: (data: ConfigData, classes: any) => {
        const { budgetUnitOptions, row, isDisabled } = data;
        const menuItemOptions: availableOptionsInterface[] =
          budgetUnitOptions?.map((item) => ({ title: item })) || [];

        return (
          <DropdownWithTooltip
            MenuItemOptions={menuItemOptions || []}
            tooltipText=""
            BttnText={row.unit ? `${row.unit}` : "Select"}
            rowData={row}
            onMenuItemClick={() => {}}
            styleMenuCard={{ width: 45 }}
            MenuStyle={{ width: 55 }}
            isDisabled={isDisabled}
          />
        );
      },
      style: {
        width: "7%",
        justifyContent: "left"
      },
      contentStyle: {
        paddingLeft: "7px",
        fontSize: "13px"
      }
    },
    item: {
      value: "Item",
      renderCustom: (data: ConfigData, classes: any) => {
        const {
          isDisabled,
          budgetClasses,
          showUnitField,
          row,
          handleFieldChange,
          handleItemOnBlur
        } = data;
        return (
          <TextField
            variant="outlined"
            data-testid="item-input"
            value={row.item}
            className={`${
              isDisabled
                ? budgetClasses.lineItemTextFieldDisabled
                : budgetClasses.lineItemTextField
            }`}
            style={{
              width: showUnitField ? "98%" : "95%",
              borderColor: row.item === "" ? "red" : "#cad1d7"
            }}
            onChange={(e) => handleFieldChange(e, row.budgetLineItemId, "item")}
            onBlur={handleItemOnBlur}
            disabled={isDisabled}
          />
        );
      },
      style: {
        width: "18%",
        justifyContent: "left"
      },
      contentStyle: {
        paddingLeft: "4px",
        fontSize: "13px"
      }
    },
    description: {
      value: "Description",
      renderCustom: (data: ConfigData, classes: any) => {
        const {
          row,
          isDisabled,
          setPopUpOpen,
          showUnitField,
          isPropertyMapped,
          textareaRefs,
          fieldsData,
          setFieldsData,
          handleFieldChange,
          handleItemOnBlur
          // BudgetDescription,
        } = data;
        return (
          <BudgetDescription
            row={row}
            isDisabled={isDisabled}
            setPopUpOpen={setPopUpOpen}
            showUnitField={showUnitField}
            isPropertyMapped={isPropertyMapped}
            textareaRefs={textareaRefs}
            fieldsData={fieldsData}
            setFieldsData={setFieldsData}
            handleItemOnBlur={handleItemOnBlur}
            handleFieldChange={handleFieldChange}
          />
        );
      },
      style: {
        width: "22%",
        justifyContent: "left"
      },
      contentStyle: {
        paddingLeft: "4px",
        fontSize: "13px"
      }
    },

    budget: {
      value: "Budget ($)",
      renderCustom: (data: ConfigData, classes: any) => {
        const {
          row,
          budgetClasses,
          isDisabled,
          handleFieldChange,
          handleItemOnBlur,
          handleDataFormat
        } = data;
        return (
          <HtmlWhiteTooltip
            title={convertNumberToUSD(row.budget)}
            placement="right"
            arrow
          >
            <TextField
              variant="outlined"
              data-testid="last-page-input"
              value={row.budget}
              className={`${
                isDisabled
                  ? budgetClasses.lineItemTextFieldDisabled
                  : budgetClasses.lineItemTextField
              }`}
              onChange={(e) =>
                handleFieldChange(e, row.budgetLineItemId, "budget")
              }
              onBlur={handleItemOnBlur}
              onFocus={() => handleDataFormat?.(row.budgetLineItemId, "budget")}
              disabled={isDisabled}
            />
          </HtmlWhiteTooltip>
        );
      },
      style: {
        width: "18%",
        justifyContent: "left"
      },
      contentStyle: {
        fontSize: "13px"
      }
    },
    financedBudget: {
      value: "Financed Budget ($)",
      renderCustom: (data: ConfigData) => {
        const { row, budgetButtonInfo } = data;
        const { financedBudgetPercentage = 0 } = budgetButtonInfo || {}; //default value 0
        const formattedBudget: number = convertUSDToNumber(row.budget);
        return (
          <HtmlWhiteTooltip
            title={convertNumberToUSD(
              (financedBudgetPercentage * formattedBudget) / 100
            )}
            placement="right"
            arrow
          >
            <span style={{ fontSize: 13 }}>
              {getValue(
                convertNumberToUSD(
                  (financedBudgetPercentage * formattedBudget) / 100
                )
              )}
            </span>
          </HtmlWhiteTooltip>
        );
      },
      style: {
        width: "17%",
        justifyContent: "left"
        // paddingRight: "40px"
      },
      contentStyle: {
        paddingLeft: "5px",
        fontSize: "13px"
      }
    }
  };
};
