import moment from "moment";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { BundleStatuses } from "@toorak/tc-common-util-sdk";
import { ObjectType } from "../../masterView/common";
// import { UPDATE_TTF_FIELDS } from "../../stores/documentReview/DocumentReview.action";
import {
  getLoanInfoForTTF,
  getPropertyInfoForTTF,
  removeLoanEscrowMap,
  removePropertyEscrowMap
} from "../../network/apiService";
import {
  evaluateLoan,
  getOverriddenOutputValues,
  getOverriddenOutputs,
  getRuleEvaluationByLoanId,
  updateOverriddenInredux
} from "../../stores/EvaluationResults/EvaluationResults.action";
// import { clearLoader } from "../../stores/loaderAndException/loaderAndException.action";
import {
  loanCreditFields,
  loanInformationFields,
  propertyInformationFields,
  unitInformationFields
} from "../../stores/loanAndPropertyDetails/helpers/loanAndPropertyFields";
import {
  getSectionObjectForRedux,
  updateLoanDetailsInRedux,
  updatePropertyDetailsInRedux
} from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { getAffectedWaivers } from "../../stores/WaiverRequest/WaiverRequest.action";
import { isEmptyValue } from "../../utils/formatChecks";
import { resolvePath } from "../../utils/utils";
import { handleSettlementAfterEdit } from "../bridge-loan-details/LoanDetailsBorrowerInformation";
import {
  condoEligibilityDropDown,
  condoEligibilityV2DropDown,
  LoanStructureTypes,
  rentDescription,
  ToorakProductEnum
} from "../constants/loanCreationDropDownValues";
import {
  formatResponse,
  getLoanHistory,
  // GET_LOAN_BY_ID_SUCCESS,
  // GET_PROPERTY_DETAILS,
  postLoan,
  postPropertyData,
  // POST_LOAN_SUCCESS,
  prepareObjectForTemplateMapping,
} from "../create-loan.action";
import {
  getPropertyDetails,
  postLoanSuccess,
  setLoanByIdSuccess
} from "../create-loan.reducer";
import { LoanDetails } from "../create-loan.reducerHelper";
import {
  formatLoanDetailsResponse,
  formatPropertyResponse
} from "../CreateLoan";
import { getBridgeLoanGuarantorInformationToPass } from "../CreateLoanHeader";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import { getDropDownData } from "../property-details/30-year-loan-property-details/PropertyDetailsUnitInformation";
import {
  formatData,
  getFormattedAddress
} from "../tape-to-file/TapeToFileUtils";
import {
  BRIDGE_REQUIRED_TAGS_FOR_TTFREVIEW,
  defaultComment,
  DOC_MAPPING_ENTITIES,
  DSCR_REQUIRED_TAGS_FOR_TTFREVIEW,
  tabIds,
  tagGroups,
  ttfAction
} from "./constants";
import {
  snackBarOpenAndClose,
  snackBarOpenAndCloseReconcileView,
  somethingFailedMessage,
  submissionFailedMessage,
  submittedSuccessMessage
} from "./document-review/popup-messages/toastMessages";
import {
  BridgeLoanFields,
  DSCRLoanFields,
  BridgeUserFields,
  DSCRUserFields,
  DSCRPropertyFields,
  BridgePropertyFields
} from "./TTFFields";
import { AutomaticTaggedDocumentType } from "./types";
import { getLoanType } from "../../config/config";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { updateTtfFields } from "../../stores/documentReview/DocumentReview.reducer";
import { postApprovalStates } from "../../utils/constants";
import { getBundleLoanData } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { setBundleLoanData } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import { latestVersioningDate } from "../../v2/fields/LoanDetails/LoanInformation";

export const prepareLoanFieldsForRedux = (
  loanDetails: LoanDetails,
  tagList: any[],
  isDSCR: boolean,
  baseData: any,
  dataPoints: any,
  key: string,
  editHistory: any
) => {
  let fieldSet: any = isDSCR ? DSCRLoanFields : BridgeLoanFields;
  fieldSet = fieldSet.filter(
    (field: any) => !Boolean(field.hidden) || !field.hidden(loanDetails)
  );
  const loanFields: any[] = [];
  fieldSet.forEach((field: any) => {
    let obj: any = {};
    obj = prepareObj(
      obj,
      field,
      loanDetails,
      loanDetails?.loanInfo?.toorakProduct,
      editHistory,
      loanDetails?.loanDetailId?.created_on,
      loanDetails?.loanDetailId?.loanId,
    );
    loanFields.push(obj);
  });
  const userFields = prepareUserFieldsForRedux(
    loanDetails?.loanUserMap,
    isDSCR,
    editHistory
  );
  if (!Array.isArray(baseData)) {
    addLoanAndUserLevelBaseDataInfo(key, loanFields, userFields, baseData, false, loanDetails?.loanDetailId?.loanId);
  }
  loanFields.forEach((field: any, index: number) => {
    addMappings(field, index, dataPoints, "Loan", tagList);
  });
  userFields.forEach((field: any, index: number) => {
    addMappings(field, index, dataPoints, "User", tagList);
  });
  return {
    loanFields,
    userFields
  };
};

export const preparePropertyFieldsForRedux = (
  propertyDetails: any = [],
  tagList: any[],
  leaseStatusDropdown: any,
  toorakProduct: string,
  isDSCR: boolean,
  baseData: any,
  dataPoints: any,
  key: string,
  editHistory?: any
) => {
  let counter = 0;
  let sortedPropertyDetails = sortByKey(
    propertyDetails,
    "loanPropertyOrder",
    "loanPropertyId"
  );
  sortedPropertyDetails.forEach((property: any) => {
    if (property?.propertyUnit?.length > 1) {
      property.propertyUnit = sortByKey(
        property.propertyUnit,
        "propertyUnitOrder",
        "propertyUnitId"
      );
    }
  });
  let fieldSet: any = isDSCR ? DSCRPropertyFields : BridgePropertyFields;
  const propertyFields: any[] = [];
  const escrowPropObj: any = [];
  sortedPropertyDetails.forEach((property: any, propertyIndex: number) => {
    const propertyUnit: any = [];
    escrowPropObj.push(property);
    fieldSet.forEach((element: any) => {
      const key = element.key;
      let obj: any = {};
      if (key?.length) {
        property[element.path].forEach((unit: any, index: number) => {
          key.forEach((prop: string, index2: number) => {
            obj = {};
            obj.path = `${element.path}.${index}.${prop}`;
            obj.auditPath = `${
              element.auditPath || element.path
            }.${index}.${prop}`;
            obj.type = element.type[index2];
            if (element.values?.[index2] !== undefined) {
              obj.values = element.values[index2];
              if (obj.values === "leaseStatusDropDown") {
                obj.values = leaseStatusDropdown;
              }
            }
            if (element.aliasKey !== undefined) {
              obj.aliasKey = element.aliasKey[index2];
            }
            if (element.rulesKey !== undefined) {
              obj.rulesKey = element.rulesKey[index2];
            }
            if (element.skipRules !== undefined) {
              obj.skipRules = element.skipRules[index2];
            }
            if (element.disableAfterApproved !== undefined) {
              obj.disableAfterApproved = element.disableAfterApproved[index2];
            }
            obj.label = `Unit ${index + 1} ${element.label[index2]}`;
            if (["Rent", "Amount"].includes(obj.label)) {
              obj.label += " ($)";
            } else if (obj.label.includes("Term")) {
              obj.label += " (Months)";
            }
            obj.loanInfoDB = unit[prop];
            obj.loanInfo = formatData(obj.loanInfoDB, obj.type);
            obj.documentInfo = "";
            obj.documentInfoDB = "";
            obj.categories = [];
            obj.documents = [];
            obj.field = element.field[index2];
            obj.unitIndex = index;
            obj.propertyIndex = propertyIndex;
            obj.loanPropertyId = property.loanPropertyId;
            obj.loanPropertyOrder = property.loanPropertyOrder;
            obj.propertyUnitId = unit.propertyUnitId;
            obj.propertyUnitOrder = unit.propertyUnitOrder;
            obj.comment = defaultComment.updatedThroughTTF;
            obj.auditHistory = editHistory?.[getFieldName(obj)];
            propertyUnit.push(obj);
          });
        });
      } else {
        obj.propertyIndex = propertyIndex;
        obj.loanPropertyId = property.loanPropertyId;
        obj.loanPropertyOrder = property.loanPropertyOrder;
        prepareObj(obj, element, property, toorakProduct, editHistory);
        propertyUnit.push(obj);
      }
    });
    propertyFields.push(propertyUnit);
  });
  addPropertyLevelBaseDataInfo(key, propertyFields, baseData?.properties);
  propertyFields.forEach((property: any) => {
    property.forEach((field: any) => {
      addMappings(field, counter, dataPoints, "Property", tagList);
      counter++;
    });
  });
  return {
    propertyFields,
    escrowPropObj
  };
};

export const setValueToField = (
  obj: ObjectType,
  keyPath: string[],
  value: any
) => {
  if (
    ["businessPurposeOccupancy", "subordinateFinancing"].includes(keyPath?.[1])
  ) {
    if (value === "Yes") {
      value = true;
    } else if (value === "No") {
      value = false;
    }
  }
  const lastKeyIndex = keyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
};

export const createSavePayload = (
  loanFields: any,
  userFields: any,
  propertyFields: any[],
  valueType: string,
  isNormalFlow = true
) => {
  const payload: any = { loans: { loanUserMap: [] }, properties: [] };
  loanFields.forEach((field: any) => {
    setValueToField(payload.loans, field.path.split("."), field[valueType]);
  });
  if (payload?.loans?.loanEconomics?.originalMonthlyPiPayment === "") {
    payload.loans.loanEconomics.originalMonthlyPiPayment = null;
  }
  userFields.forEach((field: any) => {
    if (payload.loans.loanUserMap[field.index] === undefined) {
      payload.loans.loanUserMap[field.index] = {
        loanUserMapId: field.loanUserMapId,
        loanUserSequence: field.loanUserSequence,
        partyId: field.partyId,
        ...(isNormalFlow && { isPrimary: field.isPrimary }),
        loanUserType: field.userType,
        customer: {
          contactList: [{}],
          addressList: [{}],
          ...(isNormalFlow && { partyType: field.partyType })
        }
      };
    }
    setValueToField(
      payload.loans.loanUserMap[field.index],
      field.path.split("."),
      field[valueType]
    );
  });
  propertyFields.forEach((property: any) => {
    property.forEach((dataPoint: any) => {
      if (dataPoint.unitIndex === undefined) {
        if (payload.properties[dataPoint.propertyIndex] === undefined) {
          payload.properties[dataPoint.propertyIndex] = {
            loanPropertyId: dataPoint.loanPropertyId,
            loanPropertyOrder: dataPoint.loanPropertyOrder
          };
        }
        setValueToField(
          payload.properties[dataPoint.propertyIndex],
          dataPoint.path.split("."),
          dataPoint[valueType]
        );
        if (dataPoint.field === "addressLine1" && isNormalFlow) {
          const path = "propertyLocation.locationValidationStatus";
          setValueToField(
            payload.properties[dataPoint.propertyIndex],
            path.split("."),
            dataPoint.locationValidationStatus
          );
        }
      } else {
        if (
          payload.properties[dataPoint.propertyIndex].propertyUnit === undefined
        ) {
          payload.properties[dataPoint.propertyIndex].propertyUnit = [];
        }
        if (
          payload.properties[dataPoint.propertyIndex].propertyUnit[
            dataPoint.unitIndex
          ] === undefined
        ) {
          payload.properties[dataPoint.propertyIndex].propertyUnit[
            dataPoint.unitIndex
          ] = {
            propertyUnitId: dataPoint.propertyUnitId,
            propertyUnitOrder: dataPoint.propertyUnitOrder
          };
        }
        setValueToField(
          payload.properties[dataPoint.propertyIndex].propertyUnit[
            dataPoint.unitIndex
          ],
          [dataPoint.field],
          dataPoint[valueType]
        );
      }
    });
  });
  return payload;
};

export const createExtractPayload = (
  loanFields: any,
  userFields: any,
  propertyFields: any[]
) => {
  let obj = createSavePayload(
    loanFields,
    userFields,
    propertyFields,
    "extractedInfoDB",
    false
  );
  obj = cleanObject(JSON.parse(JSON.stringify(obj)));
  if (obj?.loans?.loanUserMap) {
    obj.loanUserMap = obj?.loans?.loanUserMap;
    delete obj.loans.loanUserMap;
  }
  return obj;
};

export const updateSubmitPayload = (
  submitPayload: any,
  element: any,
  isPostApprovalAndShouldBeDisabled: boolean,
  isPartialSubmit = 0,
  override?: boolean
) => {
  if (
    ((isPartialSubmit && element.checked) || !isPartialSubmit) &&
    (override ||
      (element.documentInfo !== "" &&
        element.documentInfo != null &&
        // eslint-disable-next-line
        element.documentInfo != element.loanInfo)) &&
    (!element.disableAfterApproved ||
      (element.disableAfterApproved &&
        (!isPostApprovalAndShouldBeDisabled ||
          (isPartialSubmit && element.checked))))
  ) {
    const obj = {
      field: element.field,
      path: element.path,
      auditPath: element.auditPath,
      value: element.documentInfoDB,
      valueForComment: element.documentInfo,
      comment: element.comment || defaultComment.updatedThroughTTF,
      type: element.type,
      label: element.label,
      ...(element.loanUserMapId !== undefined && {
        loanUserMapId: element.loanUserMapId
      }),
      ...(element.loanUserSequence !== undefined && {
        loanUserSequence: element.loanUserSequence
      }),
      ...(element.partyId !== undefined && {
        partyId: element.partyId
      }),
      ...(element.loanPropertyId !== undefined && {
        loanPropertyId: element.loanPropertyId
      }),
      // index of property unit
      ...(element.unitIndex !== undefined && {
        unitIndex: element.unitIndex
      }),
      // alias key is the name of the field in the reducer/audit. if no alias key, then field name to be used
      ...(element.aliasKey !== undefined && {
        aliasKey: element.aliasKey
      }),
      ...(element.rulesKey !== undefined && {
        rulesKey: element.rulesKey
      }),
      ...(element.skipRules !== undefined && {
        skipRules: element.skipRules
      }),
      ...(element.locationValidationStatus !== undefined && {
        locationValidationStatus: element.locationValidationStatus
      })
    };
    submitPayload.push(obj);
  }
};

export const transformSubmitPayload = (submitPayload: any, isDSCR: boolean) => {
  // Single Family Detached to be saved as SFR in backend. This check should be removed
  // and handled from BE.
  const submitPayloadLoc = submitPayload.map((obj: any) => {
    if (
      obj?.field === "propertyType" &&
      obj?.value === "Single Family Detached"
    ) {
      obj.value = "SFR";
    }
    return obj;
  });

  return submitPayloadLoc;
};

export const getLeaseStatusDropdown = (loanRuleVersions: any) => {
  const defaultDropdown = rentDescription.map((item: any) => ({
    value: item,
    label: item
  }));
  let dropdownData = getDropDownData(
    "leaseStatus",
    defaultDropdown,
    loanRuleVersions
  );
  return dropdownData.map((item: any) => item.value);
};

export const updateAddress = (propertyDetails: any) => {
  const address: any[] = [];
  let sortedPropertyDetails = sortByKey(
    propertyDetails,
    "loanPropertyOrder",
    "loanPropertyId"
  );
  sortedPropertyDetails.forEach((property: any) => {
    let fullAddress = [
      property.propertyLocation?.addressNumber,
      property.propertyLocation?.addressLine1,
      property.propertyLocation?.addressLine2,
      property.propertyLocation?.addressLine3,
      property.propertyLocation?.city,
      property.propertyLocation?.state,
      property.propertyLocation?.country
    ]
      .filter(Boolean)
      .join(", ");
    if (property.propertyLocation?.postalCode) {
      fullAddress += ` ${property.propertyLocation?.postalCode}`;
    }
    address.push({
      value: fullAddress,
      assetExternalId: property.loanPropertyOrder,
      assetName: DOC_MAPPING_ENTITIES.property
    });
  });
  return address;
};

export const formatAddressFromRedux = (propertyDetails: any) => {
  const address: any = [];
  propertyDetails.forEach((property: any) => {
    let fullAddress = [
      property.propertyLocation.payload.address.street_line,
      property.propertyLocation.payload.address.city,
      property.propertyLocation.payload.address.state
    ]
      .filter(Boolean)
      .join(", ");
    if (property.propertyLocation?.payload.address.zipcode) {
      fullAddress += ` ${property.propertyLocation?.payload?.address?.zipcode}`;
    }
    address.push({
      value: fullAddress,
      assetExternalId: property.loanPropertyOrder,
      assetName: DOC_MAPPING_ENTITIES.property
    });
  });
  return address;
};

export const areThereRequiredBudgetDocs = (
  isDSCR: boolean,
  documentList: AutomaticTaggedDocumentType[],
  address: ObjectType[]
) => {
  const budgetMessage: string[] = [];
  let flag = true;
  if (isDSCR) {
    return [];
  }
  const budgetDocs = documentList?.filter(
    (document: AutomaticTaggedDocumentType) =>
      ["REBD", "SCOW"].includes(document.TagsInternal[0]?.tag?.code)
  );
  if (address?.length > budgetDocs?.length) {
    return [
      "Number of Budget Documents are less than the number of properties in this loan."
    ];
  }
  address.forEach((property: ObjectType, index: number) => {
    const propertyBudget = budgetDocs.filter(
      (document: AutomaticTaggedDocumentType) =>
        document?.assets?.find(
          (asset: ObjectType) =>
            asset?.assetExternalId?.toString() ===
              property?.assetExternalId?.toString() &&
            asset?.assetName === "PROPERTY" &&
            document.isPurchasePrimary
        )
    );
    if (propertyBudget.length === 0) {
      flag = false;
      budgetMessage.push(
        `Property ${index + 1} - ${
          property.value
        } - Primary budget document not found.`
      );
    } else if (propertyBudget.length > 1) {
      flag = false;
      budgetMessage.push(
        `Property ${index + 1} - ${
          property.value
        } - More than one primary budget document found.`
      );
    }
  });
  if (flag) {
    return [];
  }
  return budgetMessage;
};

export const userFieldsInfo = (userFields: any, userType: string) => {
  let firstName = "";
  let fullName = "";
  let firstNameIndex: number;
  let fullNameArray: any[] = [];
  if (userFields?.length) {
    userFields.forEach((item: any) => {
      if (item.userType === userType) {
        if (item.field === "firstName") {
          firstName = item.loanInfo;
          firstNameIndex = item.index;
        } else if (item.field === "lastName" && item.index === firstNameIndex) {
          fullName = firstName + " " + item.loanInfo;
          if (fullName !== " ") {
            fullNameArray = [
              ...fullNameArray,
              {
                value: fullName,
                assetExternalId: item.partyId,
                assetName: userType.toUpperCase()
              }
            ];
          }
        } else if (item.field === "accountName") {
          if (item.loanInfo && item.loanInfo !== " ") {
            fullNameArray = [
              ...fullNameArray,
              {
                value: item.loanInfo,
                assetExternalId: item.partyId,
                assetName: userType.toUpperCase()
              }
            ];
          }
        }
      }
    });
  }
  return fullNameArray;
};

const getAddressComponents = (value: string) => {
  const splitUp = value.split(", ");
  if (value && splitUp?.length >= 4) {
    return {
      city: splitUp[splitUp.length - 3],
      state: splitUp[splitUp.length - 2],
      pincode: splitUp[splitUp.length - 1]
    };
  }
  return {
    city: "",
    state: "",
    pincode: ""
  };
};

const setUserBaseData = (
  userMap: ObjectType[],
  field: ObjectType,
  valueType: string,
  key: string,
  skipFormat: boolean
) => {
  const index = userMap.findIndex(
    (val: ObjectType) => val[key] != null && val[key] === field[key]
  );
  if (index !== -1) {
    addBaseDataInfo(valueType, field, userMap[index], skipFormat);
  }
  return index;
};

const setBorrowerPayload = (
  borrowerInfoTTF: ObjectType,
  item: ObjectType,
  field: string,
  key: string,
  secondaryKey?: string
) => {
  const itemKey = secondaryKey ?? key;
  const index = borrowerInfoTTF.findIndex(
    (borrower: ObjectType) =>
      item[itemKey] != null && borrower.payload[key] === item[itemKey]
  );
  if (index !== -1) {
    let value = item.value;
    if (field === "billingAddress") {
      value = getFormattedAddress(item.value, true);
      const { city, state, pincode } = getAddressComponents(value);
      borrowerInfoTTF[index].payload.city = city;
      borrowerInfoTTF[index].payload.state = state;
      borrowerInfoTTF[index].payload.pincode = pincode;
      borrowerInfoTTF[index].payload.locationValidationStatus = "OVERRIDDEN";
    }
    borrowerInfoTTF[index].payload[field] = value;
  }
  return index;
};

const setGuarantorPayload = (
  guarantorInfoTTF: ObjectType,
  item: ObjectType,
  field: string,
  key: string,
  secondaryKey?: string
) => {
  const itemKey = secondaryKey ?? key;
  const index = guarantorInfoTTF.findIndex(
    (guarantor: ObjectType) =>
      item[itemKey] != null && guarantor.payload[key] === item[itemKey]
  );
  if (index !== -1) {
    guarantorInfoTTF[index].payload[field] = item.value;
  }
  return index;
};

const processPayload = (
  payloadArray: ObjectType[],
  item: ObjectType,
  field: string,
  primaryKey: string,
  secondaryKey: string,
  tertiaryKey: string,
  payloadFunction: Function
) => {
  let index = payloadFunction(payloadArray, item, field, primaryKey);
  if (index === -1) {
    index = payloadFunction(
      payloadArray,
      item,
      field,
      secondaryKey,
      "loanUserSequence"
    );
  }
  if (index === -1) {
    payloadFunction(payloadArray, item, field, tertiaryKey);
  }
};

export const preparePayload = (
  item: ObjectType,
  isDSCR: boolean,
  bridgeLoanEconomicsTTF: ObjectType,
  thirtyYearLoanEconomicsTTF: ObjectType,
  bridgeLoanInformationTTF: ObjectType,
  thirtyYearLoanInformationTTF: ObjectType,
  bridgeLoanBorrowerInformationTTF: ObjectType,
  bridgeLoanGuarantorInformationTTF: ObjectType,
  propertyDetailsInformationTTF: ObjectType,
  loanSummaryTTF: ObjectType,
  loanFeesTTF: ObjectType,
  achDataTTF: ObjectType
) => {
  const field = item.aliasKey !== undefined ? item.aliasKey : item.field;
  const payloadObject: ObjectType = {
    Borrower: {
      payloadArray: bridgeLoanBorrowerInformationTTF,
      payloadFunction: setBorrowerPayload,
      secondaryKey: "borrowerLoanUserSequence"
    },
    Guarantor: {
      payloadArray: bridgeLoanGuarantorInformationTTF,
      payloadFunction: setGuarantorPayload,
      secondaryKey: "guarantorLoanUserSequence"
    }
  };

  if (item.path.startsWith("loanDetailId.loanSummaryId")) {
    loanSummaryTTF[field] = item.value;
  } else if (item.path.startsWith("loanFees")) {
    loanFeesTTF[field] = item.value;
  } else if (item.path.startsWith("achData")) {
    achDataTTF[field] = item.value;
  } else if (item.path.startsWith("loanEconomics")) {
    if (
      ["businessPurposeOccupancy", "subordinateFinancing"].includes(item.field)
    ) {
      if (item.value === true) {
        item.value = "Yes";
      } else if (item.value === false) {
        item.value = "No";
      }
    }
    if (!isDSCR) {
      bridgeLoanEconomicsTTF.payload[field] = item.value;
    } else {
      thirtyYearLoanEconomicsTTF.payload[field] = item.value;
    }
  } else if (item.path.startsWith("loanInfo")) {
    if (item.field === "crossCollaterlization") {
      if (["true", true, "Yes"].includes(item.value)) {
        item.value = "Y";
      } else if (["false", false, "No"].includes(item.value)) {
        item.value = "N";
      }
    }
    if (!isDSCR) {
      bridgeLoanInformationTTF.payload[field] = item.value;
    } else {
      thirtyYearLoanInformationTTF.payload[field] = item.value;
    }
    /* eslint-disable eqeqeq */
  } else if (
    item.partyId != undefined ||
    item.loanUserSequence != undefined ||
    item.loanUserMapId != undefined
    /* eslint-enable eqeqeq */
  ) {
    for (const key in payloadObject) {
      if (item.label.startsWith(key)) {
        const { payloadArray, payloadFunction, secondaryKey } =
          payloadObject[key];
        processPayload(
          payloadArray,
          item,
          field,
          "partyId",
          secondaryKey,
          "loanUserMapId",
          payloadFunction
        );
      }
    }
  } else if (item.path.startsWith("property")) {
    const propertyIndex = propertyDetailsInformationTTF.findIndex(
      (property: any) => property.propertyId === item.loanPropertyId
    );
    if (propertyIndex !== -1) {
      if (item.path.startsWith("propertyinfo")) {
        propertyDetailsInformationTTF[
          propertyIndex
        ].propertyInformation.payload[field] = item.value;
      } else if (item.path.startsWith("propertyEconomics")) {
        propertyDetailsInformationTTF[propertyIndex].propertyEconomics.payload[
          field
        ] = item.value;
      } else if (item.path.startsWith("propertyUnit")) {
        propertyDetailsInformationTTF[propertyIndex].unitsInformation[
          item.unitIndex
        ].payload[field] = item.value;
      } else if (item.path.startsWith("propertyLocation")) {
        if (item.field === "addressLine1") {
          propertyDetailsInformationTTF[
            propertyIndex
          ].propertyLocation.payload.address[field] = item.value;
        } else {
          propertyDetailsInformationTTF[propertyIndex].propertyLocation.payload[
            field
          ] = item.value;
          propertyDetailsInformationTTF[
            propertyIndex
          ].propertyLocation.payload.locationValidationStatus =
            item.locationValidationStatus;
        }
      }
    }
  }
};

export const getFieldName = (obj: any) => {
  let fullPath = obj.auditPath;
  if (obj.loanUserMapId !== undefined) {
    fullPath.replace(".0.", "[0]");
    return `data.loan.loanUserMap[${obj.loanUserMapId}].${fullPath}`;
  } else if (obj.unitIndex !== undefined) {
    return `data.properties[${obj.loanPropertyId}].propertyUnit[${
      obj.unitIndex
    }].${fullPath.split(".")[2]}`;
  } else if (obj.loanPropertyId !== undefined) {
    return `data.properties[${obj.loanPropertyId}].${fullPath}`;
  }
  return `data.loan.${fullPath}`;
};

export const prepCommentObj = (item: any, personId: any) => {
  let { value, comment, valueForComment } = item;
  if (["crossCollaterlization"].includes(item.field)) {
    if (["true", true, "Yes"].includes(value)) {
      value = "Y";
    } else if (["false", false, "No"].includes(value)) {
      value = "N";
    }
  } else if (
    ["businessPurposeOccupancy", "subordinateFinancing"].includes(item.field)
  ) {
    if (value === true) {
      value = "Yes";
    } else if (value === false) {
      value = "No";
    }
  }
  if (item.type === "currency") {
    value = `$${value}`;
  } else if (item.type === "percentage") {
    value = `${value}%`;
  } else if (item.type === "number" || item.type === "date") {
    value = valueForComment;
  }
  return {
    value,
    comment,
    updatedBy: personId,
    field: getFieldName(item)
  };
};

export const findMinMax = (arr: any[], key: string) => {
  let min = arr?.[0]?.[key],
    max = arr?.[0]?.[key];

  for (let i = 1, len = arr?.length; i < len; i++) {
    let v = arr?.[i]?.[key];
    min = v < min ? v : min;
    max = v > max ? v : max;
  }

  return { min, max };
};

export const prepareForSaveToDB = (type: any, readVal: any, newVal: any) => {
  switch (type) {
    case "dateOnly": {
      // Ensure only the "YYYY-MM-DD" portion is returned if there's a time component
      return newVal ? newVal.split(" ")[0] : "";
    }
    case "date": {
      // Check if newVal already contains a time component (e.g., 'T00:00:00')
      if (newVal && newVal.includes("T")) {
        return newVal; // If it already contains a time, return as is
      }
      return newVal ? `${newVal}T00:00:00.000Z` : "";
    }
    case "dropdown": {
      if (readVal === "Y") {
        return true;
      } else if (readVal === "N") {
        return false;
      }
      return readVal;
    }
    default: {
      return readVal;
    }
  }
};

export const sortByKey = (
  obj: any,
  primaryKey: string,
  secondaryKey: string
) => {
  const newObj = JSON.parse(JSON.stringify(obj));
  return newObj.sort((a: any, b: any) => {
    if (!isEmptyValue(a?.[primaryKey]) && !isEmptyValue(b?.[primaryKey])) {
      return parseInt(a[primaryKey]) - parseInt(b[primaryKey]);
    }
    if (!isEmptyValue(a?.[secondaryKey]) && !isEmptyValue(b?.[secondaryKey])) {
      return parseInt(a[secondaryKey]) - parseInt(b[secondaryKey]);
    }
    return 0;
  });
};

export const updateAndRunRules = async (
  dispatch: any,
  documentReviewDispatch: any,
  changedRulesFields: any[],
  waiversCreated: any,
  loanId: string,
  loanStage: LoanStage,
  loanType: string,
  overriddenValues: any,
  bridgeLoanGuarantorInformationTTF: any,
  bridgeLoanBorrowerInformationTTF: any,
  thirtyYearLoanInformationTTF: any,
  thirtyYearLoanEconomicsTTF: any,
  bridgeLoanInformationTTF: any,
  bridgeLoanEconomicsTTF: any,
  propertyDetailsInformationTTF: any,
  loanRuleVersions: any,
  loanSummaryTTF: any,
  loanConfigTTF: any,
  loanFeesTTF: any,
  achDataTTF: any,
  loanEvaluationResult: any,
  loanParamChanged: boolean,
  propertyParamChanged: boolean,
  isSettlementAvailable: boolean,
  commentObj: any[],
  bundleId: string,
  bundleStatus: BundleStatuses,
  toBeSubmittedData: any,
  updateLoanCallback: any,
  updatePropertyCallback: any,
  takeoutPartnerId?: string,
  saveObj?: ObjectType
) => {
  const isDSCR = loanType.includes("DSCR");
  let overriddenValuesToUse: any = overriddenValues;
  const initializer = { reRequestWaiverObject: {}, openWaivers: [] };
  let { reRequestWaiverObject, openWaivers }: any = initializer;
  let refreshWaiver: boolean = false;
  if (changedRulesFields.length) {
    ({ reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
      changedRulesFields,
      waiversCreated,
      loanId,
      loanStage,
      loanType
    ));
    refreshWaiver =
      (typeof reRequestWaiverObject === "object" &&
        Object.keys(reRequestWaiverObject)?.length) ||
      openWaivers.length;
    // skipping when no partner and loan is of dscr
    // https://toorakcapital.atlassian.net/browse/TA-4313
    if (
      refreshWaiver &&
      (loanType === getLoanType[0].displayValue ||
        (loanType === getLoanType[1].displayValue && takeoutPartnerId))
    ) {
      const resp = await getOverriddenOutputs(
        loanId,
        loanStage,
        takeoutPartnerId
      );
      overriddenValuesToUse = resp?.data;
      dispatch(updateOverriddenInredux(resp));
    }
  }
  const bridgeLoanGuarantorInformationToPass =
    getBridgeLoanGuarantorInformationToPass(bridgeLoanGuarantorInformationTTF);
  const data = getLoanPayLoadData(
    loanRuleVersions,
    loanSummaryTTF,
    loanFeesTTF,
    achDataTTF,
    isDSCR,
    bridgeLoanBorrowerInformationTTF,
    bridgeLoanGuarantorInformationTTF,
    thirtyYearLoanInformationTTF,
    thirtyYearLoanEconomicsTTF,
    bridgeLoanInformationTTF,
    bridgeLoanEconomicsTTF
  );

  const commonPayload = {
    loanId,
    loanType,
    loanConfig: loanConfigTTF,
    loanSummary: loanSummaryTTF,
    loanFees: loanFeesTTF,
    achData: achDataTTF,
    borrowerInformation: bridgeLoanBorrowerInformationTTF,
    guarantorInfo: bridgeLoanGuarantorInformationToPass
  };

  const postLoanPayload = !isDSCR
    ? {
        ...commonPayload,
        ...bridgeLoanInformationTTF.payload,
        ...bridgeLoanEconomicsTTF.payload
      }
    : {
        ...commonPayload,
        ...thirtyYearLoanInformationTTF.payload,
        ...thirtyYearLoanEconomicsTTF.payload
      };

  const promise1 = loanParamChanged
    ? postLoan(postLoanPayload, false, true, false, "", commentObj)
    : Promise.resolve("Success");

  const promise2 = propertyParamChanged
    ? postPropertyData(
        loanId,
        loanStage,
        propertyDetailsInformationTTF,
        null,
        false,
        true,
        false,
        commentObj
      )
    : Promise.resolve("Success");
  Promise.all([promise1, promise2]).then(
    async (responseArr: any) => {
      if (loanParamChanged) {
        // dispatch({
        //   type: POST_LOAN_SUCCESS,
        //   payload: {
        //     reservation: responseArr[0]
        //   }
        // });
        dispatch(postLoanSuccess({ reservation: responseArr[0] }));
        callAggregateLoanService(
          dispatch,
          loanId,
          loanStage,
          loanType,
          toBeSubmittedData,
          updateLoanCallback,
          changedRulesFields,
          saveObj
        );
      }
      if (
        propertyParamChanged ||
        (changedRulesFields.includes("firstPaymentDateOfLoan") &&
          loanType.includes("DSCR"))
      ) {
        callAggregatePropertyService(
          dispatch,
          loanId,
          loanStage,
          loanType,
          toBeSubmittedData,
          updatePropertyCallback,
          changedRulesFields,
          saveObj
        );
      }
      if (changedRulesFields.length) {
        getEvaluationRequestBody(
          loanId,
          loanStage,
          data,
          loanEvaluationResult,
          propertyDetailsInformationTTF,
          loanType
        ).then(
          ({ evaluate, loanEvaluationRequest }) => {
            if (evaluate && loanEvaluationRequest) {
              let loanEvaluationRequestLoc: any = loanEvaluationRequest;
              loanEvaluationRequestLoc.ruleAttributs = {
                ruleGroup: [],
                ruleField: [],
                ruleResult: []
              };
              loanEvaluationRequestLoc.resultObject = overriddenValuesToUse;
              dispatch(
                evaluateLoan(
                  loanEvaluationRequestLoc,
                  loanId,
                  loanStage,
                  true,
                  false,
                  propertyDetailsInformationTTF,
                  refreshWaiver,
                  false,
                  false,
                  null,
                  null,
                  function () {
                    failureCallback(
                      dispatch,
                      documentReviewDispatch,
                      submissionFailedMessage
                    );
                  },
                  "",
                  true
                )
              );
              if (isSettlementAvailable) {
                handleSettlementAfterEdit(
                  changedRulesFields,
                  commentObj,
                  loanId,
                  bundleId,
                  bundleStatus,
                  dispatch
                );
              }
            }
            submitFinalCallback(dispatch, documentReviewDispatch);
            setTimeout(() => {
              dispatch(getLoanHistory(`${loanId}_${loanStage}`));
            }, 1000);
          },
          (error: any) => {
            failureCallback(
              dispatch,
              documentReviewDispatch,
              somethingFailedMessage
            );
          }
        );
      } else {
        submitFinalCallback(dispatch, documentReviewDispatch);
        setTimeout(() => {
          dispatch(getLoanHistory(`${loanId}_${loanStage}`));
        }, 1000);
      }
    },
    (error: any) => {
      failureCallback(
        dispatch,
        documentReviewDispatch,
        submissionFailedMessage
      );
    }
  );
};

const failureCallback = (
  dispatch: any,
  documentReviewDispatch: any,
  message: any
) => {
  dispatch(clearLoader());
  if (documentReviewDispatch) {
    snackBarOpenAndClose(documentReviewDispatch, message);
  } else {
    snackBarOpenAndCloseReconcileView(dispatch, message);
  }
};

const submitFinalCallback = (dispatch: any, documentReviewDispatch: any) => {
  dispatch(clearLoader());
  if (documentReviewDispatch) {
    snackBarOpenAndClose(documentReviewDispatch, submittedSuccessMessage);
  } else {
    snackBarOpenAndCloseReconcileView(dispatch, submittedSuccessMessage);
  }
};

export const setBundleData = async (dispatch: any, loanId: string, loanStage: LoanStage, loanDetails: any, formattedPropertyData: any, loanType: string) => {
  dispatch(
    getRuleEvaluationByLoanId(
      loanId,
      loanStage,
      loanDetails?.loanInfo?.takeoutPartner,
      false,
      formattedPropertyData,
      loanType,
      true
    )
  );
  dispatch(getOverriddenOutputValues(loanId, loanStage, loanDetails?.loanInfo?.takeoutPartner)); // need this as some results might have been carry forwarded from previous stage.
  if (postApprovalStates.includes(loanDetails.loanState)) {
    let bundleDetails: any;
    try {
      bundleDetails = await getBundleLoanData(loanId, true);
    } catch (err) {
      console.error(err);
      bundleDetails = null;
      // Handle error
    }
    if (bundleDetails?.loanbundleData) {
      dispatch(setBundleLoanData(bundleDetails));
    } else if (bundleDetails?.bundleId) {
      dispatch(
        setBundleLoanData({
          loanbundleData: {
            bundleDetails: { bundleId: bundleDetails.bundleId }
          },
          calcFields: {},
          bundleStatus: bundleDetails?.bundleStatus || ""
        })
      );
    } else {
      setBlankBundleData(dispatch);
    }
  } else {
    setBlankBundleData(dispatch);
  }
}

const setBlankBundleData = (dispatch: any) => {
  dispatch(
    setBundleLoanData({
      loanbundleData: {
        bundleDetails: { bundleId: "" }
      },
      calcFields: {},
      bundleStatus: ""
    })
  );
}

export const sortByTagName = (a: any, b: any) => {
  const tagDiff = [null, undefined].includes(a?.TagsInternal?.[0]?.tag?.name)
    ? -1
    : a?.TagsInternal?.[0]?.tag?.name?.localeCompare(
        b?.TagsInternal?.[0]?.tag?.name
      );
  if (tagDiff !== 0) {
    return tagDiff;
  }

  // If the tags are equal, then objects with isPurchasePrimary=true should come first.
  const purchasePrimaryDiff = a.isPurchasePrimary - b.isPurchasePrimary;
  if (purchasePrimaryDiff !== 0) {
    return purchasePrimaryDiff * -1;
  }

  // If the tags and isPurchasePrimary are equal, then sort by name in ascending order.
  return a.name.localeCompare(b.name);
};

export const sortByLabel = (a: any, b: any) => {
  return a?.label?.localeCompare(b.label);
};

export const callAggregateLoanService = async (
  dispatch: any,
  loanId: string,
  loanStage: LoanStage,
  loanType: string,
  toBeSubmittedData: any,
  updateLoanCallback: any,
  changedRulesFields: any[],
  saveObj?: any
) => {
  const promiseA = getLoanInfoForTTF(loanId, loanStage);
  promiseA.then((response: any) => {
    const loanDetails = updateLoanDetailsInStore(
      dispatch,
      response.data,
      loanId,
      loanStage,
      loanType
    );
    if (
      changedRulesFields.includes("firstPaymentDateOfLoan") &&
      loanType.includes("DSCR")
    ) {
      loanDetails.loanEscrowMap = [];
      removeLoanEscrowMap(loanId);
    }
    updateLoanCallback &&
      updateLoanCallback(
        loanDetails,
        toBeSubmittedData,
        changedRulesFields,
        saveObj
      );
  });
};

export const callAggregatePropertyService = async (
  dispatch: any,
  loanId: string,
  loanStage: LoanStage,
  loanType: string,
  toBeSubmittedData: any,
  updatePropertyCallback: any,
  changedRulesFields: any[],
  saveObj?: any
) => {
  const promiseB = getPropertyInfoForTTF(loanId, loanStage);
  promiseB
    .then((resp: any) => {
      if (
        changedRulesFields.includes("firstPaymentDateOfLoan") &&
        loanType.includes("DSCR")
      ) {
        resp.data?.forEach((prop: any) => {
          prop.propertyEscrowMap = [];
        });
        removePropertyEscrowMap(loanId, loanStage);
      }
      const { propertyDetails } = updatePropertyDetailsInStore(
        dispatch,
        resp.data,
        loanId,
        loanStage,
        loanType
      );
      updatePropertyCallback(propertyDetails, toBeSubmittedData, saveObj);
    })
    .catch((e) => console.error(e));
};

export const addLoanAndUserLevelBaseDataInfo = (
  valueType: string,
  loanFields: any,
  userFields: any,
  baseData: any,
  skipFormat: boolean = false,
  loanId?: string
) => {
  addLoanLevelBaseDataInfo(valueType, loanFields, baseData?.loans, skipFormat, loanId);
  addUserLevelBaseDataInfo(
    valueType,
    userFields,
    baseData?.loans?.loanUserMap,
    skipFormat
  );
};

export const addPropertyLevelBaseDataInfo = (
  valueType: string,
  propertyFields: any,
  propertyBaseData: any = [],
  skipFormat: boolean = false
) => {
  if (propertyBaseData?.length) {
    let sortedpropertyBaseData: any[];
    if (valueType === "diligenceInfo") {
      sortedpropertyBaseData = propertyBaseData;
    } else {
      sortedpropertyBaseData = sortByKey(
        propertyBaseData,
        "loanPropertyOrder",
        "loanPropertyId"
      );
      sortedpropertyBaseData.forEach((property: any) => {
        if (property?.propertyUnit?.length > 1) {
          property.propertyUnit = sortByKey(
            property.propertyUnit,
            "propertyUnitOrder",
            "propertyUnitId"
          );
        }
      });
    }
    propertyFields.forEach((property: any, index: number) => {
      property.forEach((field: any) => {
        const propertyIdIndex =
          valueType === "diligenceInfo"
            ? index
            : sortedpropertyBaseData.findIndex(
                (item: ObjectType) =>
                  item.loanPropertyId === field.loanPropertyId
              );
        addBaseDataInfo(
          valueType,
          field,
          sortedpropertyBaseData[propertyIdIndex],
          skipFormat
        );
        if (valueType !== "diligenceInfo" && field.field === "addressLine1") {
          const path = "propertyLocation.locationValidationStatus";
          field.locationValidationStatus = resolvePath(
            path,
            sortedpropertyBaseData[propertyIdIndex]
          );
        }
      });
    });
  }
};

export const addPropertyLevelExtractedDataInfoInitialState = (
  valueType: string,
  propertyFields: any,
  propertyBaseData: any = [],
  skipFormat: boolean
) => {
  if (propertyBaseData?.length) {
    propertyFields.forEach((property: any) => {
      property.forEach((field: any) => {
        addBaseDataInfo(valueType, field, propertyBaseData[0], skipFormat);
      });
    });
  }
};

export const addLoanLevelBaseDataInfo = (
  valueType: string,
  loanFields: any,
  baseData: any,
  skipFormat: boolean = false,
  loanId?: string
) => {
  loanFields.forEach((field: any) => {
    addBaseDataInfo(valueType, field, baseData, skipFormat, loanId);
  });
};

export const addUserLevelDiligenceInfo = (
  valueType: string,
  userFields: any,
  borrowerBaseData: any[] = [],
  guarantorBaseData: any[] = [],
  skipFormat: boolean = false
) => {
  let countOfBorrowers = 0;
  if (borrowerBaseData?.length) {
    borrowerBaseData.forEach((user: any, index: number) => {
      userFields.forEach((field: any) => {
        if (field.userType === "Borrower") {
          if (field.index === index) {
            addBaseDataInfo(valueType, field, user, skipFormat);
          }
          countOfBorrowers = field.index + 1;
        }
      });
    });
  }
  if (guarantorBaseData?.length) {
    guarantorBaseData.forEach((user: any, index: number) => {
      userFields.forEach((field: any) => {
        if (
          field.userType === "Guarantor" &&
          field.index - countOfBorrowers === index
        ) {
          addBaseDataInfo(valueType, field, user, skipFormat);
        }
      });
    });
  }
};

export const getDiscrepanciesOrFullSet = (fieldSet: any, showAll: boolean) => {
  if (!showAll) {
    return fieldSet.filter(
      (item: any) =>
        (item.categories.length || item.field === 'dscr') &&
        ["", null].includes(item.documentInfo) &&
        ![
          item.originalLoanInfo,
          item.loanInfo,
          item.ttfInfo,
          item.diligenceInfo
        ].every((val, i, arr) => val === arr[0] && !["", null].includes(val))
    );
  } else {
    return fieldSet.filter((item: any) => (item.categories.length || item.field === 'dscr'));
  }
};

export const updateLoanDetailsInStore = (
  dispatch: any,
  data: any,
  loanId: string,
  loanStage: LoanStage,
  loanType: string
) => {
  prepareObjectForTemplateMapping(
    null,
    data,
    dispatch,
    "loan",
    false,
    loanStage.toLowerCase(),
    loanId,
    loanType
  );
  const newLoanDetails = formatResponse(data);
  updateLoanDetailsInRedux(newLoanDetails, dispatch); // credit-eval reducer
  const { loanInfo, loanCredit, loanUserMap, loanDetailId } = newLoanDetails;
  const loanDetails = {
    ...newLoanDetails,
    loanUserMap,
    loanInfo: getSectionObjectForRedux(loanInfo, loanInformationFields),
    loanCredit: {
      ...getSectionObjectForRedux(loanCredit, loanCreditFields),
      truliaCrimeCheck: loanDetailId?.loanConfigId?.truliaCrime
    },
    loanConfig: loanDetailId?.loanConfigId
  };
  const formattedLoanData = formatLoanDetailsResponse(data, loanStage);
  // dispatch({
  //   type: GET_LOAN_BY_ID_SUCCESS,
  //   payload: {
  //     loan: formattedLoanData,
  //     keepTab: true
  //   }
  // });
  dispatch(
    setLoanByIdSuccess({
      loan: formattedLoanData,
      keepTab: true
    })
  );
  return loanDetails;
};

export const updatePropertyDetailsInStore = (
  dispatch: any,
  data: any,
  loanId: string,
  loanStage: LoanStage,
  loanType: string
) => {
  let propertyDetails: any = [];
  updatePropertyDetailsInRedux(data, dispatch);
  const newPropertyDetails = data.sort(
    (a: any, b: any) => a.loanPropertyOrder - b.loanPropertyOrder
  );
  if (newPropertyDetails?.length) {
    const propertyObj = newPropertyDetails.map((element: any) => {
      const {
        loanPropertyId,
        propertyinfo,
        propertyLocation,
        propertyUnit = [],
        loanPropertyOrder
      } = element;
      return {
        ...element,
        loanPropertyId,
        loanPropertyOrder,
        propertyInfo: getSectionObjectForRedux(
          propertyinfo,
          propertyInformationFields
        ),
        propertyLocation,
        propertyUnit: propertyUnit?.length
          ? propertyUnit.map((item: ObjectType) => {
              return getSectionObjectForRedux(item, unitInformationFields);
            })
          : []
      };
    });
    propertyDetails = propertyObj || [];
  } else {
    propertyDetails = [];
  }
  const formattedPropertyData = formatPropertyResponse(
    data,
    loanStage,
    loanType
  );
  dispatch(
    getPropertyDetails({
      propertyDetails: formattedPropertyData,
      thirtyYearLoanPropertyDetails: formattedPropertyData,
      keepTab: true
    })
  );
  if (data.length) {
    prepareObjectForTemplateMapping(
      null,
      data[0],
      dispatch,
      "property",
      false,
      loanStage.toLowerCase(),
      loanId,
      loanType
    );
  }
  return {
    propertyDetails,
    formattedPropertyData
  };
};

export const cleanObject = (object: any) => {
  Object.entries(object).forEach((item: any) => {
    const [k, v] = item;
    if (v && typeof v === "object") {
      cleanObject(v);
    }
    if (
      (v &&
        typeof v === "object" &&
        !(v instanceof Date) &&
        !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v?.length === 0
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};

export const objectFlip = (obj: ObjectType) => {
  const ret: any = {};
  Object.keys(obj).forEach((key: string) => {
    ret[obj[key]] = key;
  });
  return ret;
};

const getLoanPayLoadData = (
  loanRuleVersions: ObjectType,
  loanSummaryTTF: ObjectType,
  loanFeesTTF: ObjectType,
  achDataTTF: ObjectType,
  isDSCR: boolean,
  bridgeLoanBorrowerInformationTTF: ObjectType,
  bridgeLoanGuarantorInformationTTF: ObjectType,
  thirtyYearLoanInformationTTF: ObjectType,
  thirtyYearLoanEconomicsTTF: ObjectType,
  bridgeLoanInformationTTF: ObjectType,
  bridgeLoanEconomicsTTF: ObjectType
) => {
  const data = {
    borrowerInformation: bridgeLoanBorrowerInformationTTF,
    guarantorInformation: bridgeLoanGuarantorInformationTTF,
    loanInformation: null,
    loanEconomics: null,
    loanRuleVersions,
    loanSummary: loanSummaryTTF,
    loanFees: loanFeesTTF,
    achData: achDataTTF
  };
  if (isDSCR) {
    data.loanInformation = thirtyYearLoanInformationTTF.payload;
    data.loanEconomics = thirtyYearLoanEconomicsTTF.payload;
  } else {
    data.loanInformation = bridgeLoanInformationTTF.payload;
    data.loanEconomics = bridgeLoanEconomicsTTF.payload;
  }

  return data;
};

export const prepareUserFieldsForRedux = (
  userDetails: any = [],
  isDSCR: boolean,
  editHistory?: any
) => {
  let userFields: any[] = [];
  let borrowerData: any = [];
  let guarantorData: any = [];
  let counter = 0;
  userDetails.forEach((user: any) => {
    const extensibleUser = JSON.parse(JSON.stringify(user));
    user?.loanUserType !== "Guarantor"
      ? borrowerData.push(extensibleUser)
      : guarantorData.push(extensibleUser);
  });
  if (borrowerData.length > 1) {
    borrowerData = sortUserData(borrowerData);
  }
  if (guarantorData.length > 1) {
    guarantorData = sortUserData(guarantorData);
  }
  borrowerData.forEach((user: any) => {
    user.index = counter;
    counter++;
  });
  guarantorData.forEach((user: any) => {
    user.index = counter;
    counter++;
  });
  userFields = [
    ...prepareUserArray(borrowerData, "Borrower", isDSCR, editHistory),
    ...prepareUserArray(guarantorData, "Guarantor", isDSCR, editHistory)
  ];
  return userFields;
};

const addBaseDataInfo = (
  valueType: string,
  field: any,
  baseObj: any,
  skipFormat: boolean,
  loanId?: string
) => {
  let dbValue = field.path !== "" ? resolvePath(field.path, baseObj, { loanId }) : "";
  if (field.type.startsWith("date") && valueType === "diligenceInfo") {
    dbValue = dbValue?.replace(" ", "T");
  }
  field[`${valueType}DB`] = dbValue;
  if (skipFormat) {
    if (valueType === "extractedInfo" && field.extractedInfoDB?.length === 1) {
      field.temporaryDocInfoDB = field.extractedInfoDB[0].editedValue;
      field.temporaryDocInfo = formatData(
        field.extractedInfoDB[0].editedValue,
        field.type
      );
      if ([null, ""].includes(field.documentInfo)) {
        field.status = "";
        field.extractedInfoDB = JSON.parse(
          JSON.stringify(field.extractedInfoDB)
        );
        field.extractedInfoDB[0].selected = true;
      } else if (field.documentInfo === field.temporaryDocInfo) {
        field.status = "APPROVED";
      } else {
        field.status = "REJECTED";
      }
    }
  } else {
    field[valueType] = formatData(field[`${valueType}DB`], field.type);
    field = transformInfo(field, field, valueType);
  }
};

const addMappings = (
  field: any,
  index: number,
  dataPoints: any[] | null,
  section: "Loan" | "Property" | "User",
  tagList: any[]
) => {
  field.posIndex = index;
  if (field.unitIndex !== undefined) {
    section += "Unit";
  }
  if (dataPoints?.length) {
    const dataPoint: any = dataPoints.find(
      (item: any) => item.name === field.field && section === item.section
    );
    field.categories = dataPoint?.categories || [];
    field.categoriesNamesList = dataPoint?.categoriesNamesList || [];
    field.documents = dataPoint?.documents || [];
    field.documentTagNames = tagList
      .filter((tag: any) => field.documents.includes(tag?.code))
      .map((tag: any) => tag.name);
  }
};

export const addUserLevelExtractedDataInfoInitialState = (
  valueType: string,
  userFields: any,
  userBaseData: any,
  skipFormat: boolean
) => {
  if (userBaseData?.length) {
    userBaseData.forEach((user: any) => {
      userFields.forEach((field: any) => {
        if (field.userType === user.loanUserType) {
          addBaseDataInfo(valueType, field, user, skipFormat);
        }
      });
    });
  }
};

const processUserFields = (
  userMap: ObjectType[],
  field: ObjectType,
  valueType: string,
  primaryKey: string,
  secondaryKey: string,
  tertiaryKey: string,
  skipFormat: boolean
) => {
  let index = setUserBaseData(
    userMap,
    field,
    valueType,
    primaryKey,
    skipFormat
  );
  if (index === -1) {
    index = setUserBaseData(
      userMap,
      field,
      valueType,
      secondaryKey,
      skipFormat
    );
  }
  if (index === -1) {
    setUserBaseData(userMap, field, valueType, tertiaryKey, skipFormat);
  }
};

export const addUserLevelBaseDataInfo = (
  valueType: string,
  userFields: ObjectType[],
  userBaseData: ObjectType[],
  skipFormat: boolean
) => {
  if (userBaseData?.length) {
    let borrowerUserMap: any = [];
    let guarantorUserMap: any = [];
    userBaseData.forEach((user: any) => {
      if (user.loanUserType !== "Guarantor") {
        borrowerUserMap.push(user);
      } else {
        guarantorUserMap.push(user);
      }
      if (borrowerUserMap.length > 1) {
        borrowerUserMap = sortUserData(borrowerUserMap);
      }
      if (guarantorUserMap.length > 1) {
        guarantorUserMap = sortUserData(guarantorUserMap);
      }
      const userMaps = {
        Borrower: borrowerUserMap,
        Guarantor: guarantorUserMap
      };

      userFields.forEach((field: ObjectType) => {
        const userType: "Borrower" | "Guarantor" = field.userType;
        const userMap = userMaps[userType];
        processUserFields(
          userMap,
          field,
          valueType,
          "partyId",
          "loanUserSequence",
          "loanUserMapId",
          skipFormat
        );
      });
    });
  }
};

const transformInfo = (obj: any, element: any, key: string) => {
  // SFR in BE is shown as Single Family Detached on UI. To remove this and handle from BE later.
  if (obj[`${key}DB`] === "SFR" && element?.field === "propertyType") {
    obj[key] = "Single Family Detached";
  }
  return obj;
};

const prepareObj = (
  obj: any,
  element: any,
  parent: any,
  toorakProduct: string,
  editHistory?: any,
  createdOn?: string,
  loanId?: string
) => {
  obj.loanInfoDB = element.path !== "" ? resolvePath(element.path, parent, { loanId }) : "";
  if (element.field === "addressLine1" && obj?.loanPropertyId != null) {
    obj.locationValidationStatus = resolvePath(
      "propertyLocation.locationValidationStatus",
      parent
    );
  }
  obj.loanInfo = formatData(obj.loanInfoDB, element.type);
  obj.label = element.label;
  obj.comment = defaultComment.updatedThroughTTF;
  obj = transformInfo(obj, element, "loanInfo");
  return initializeObj(obj, element, toorakProduct, editHistory, createdOn);
};

const transformDropDown = (toorakProduct: string, createdOn: any, element: any) => {
  const isLatestVersion =
    createdOn &&
    moment(latestVersioningDate, "DD-MM-YYYY").isSameOrBefore(
      moment(createdOn, "YYYY-MM-DD")
    );
  if (element.field === "condoEligibility") {
    element.values = isLatestVersion ? condoEligibilityV2DropDown : condoEligibilityDropDown;
  } else if (toorakProduct === ToorakProductEnum.Rental) {
    if (element.field === "loanStructure") {
      element.values = [LoanStructureTypes.singleDraw];
    } else if (element.field === "propertyType") {
      element.values = element.values.filter(
        (val: string) => !["5+ Unit Multifamily", "Mixed Use"].includes(val)
      );
    }
  }
};

const initializeObj = (
  obj: any,
  element: any,
  toorakProduct: any,
  editHistory: any,
  createdOn?: string
) => {
  obj.path = element.path;
  obj.auditPath = element.auditPath || element.path;
  if (element.aliasKey !== undefined) {
    obj.aliasKey = element.aliasKey;
  }
  if (element.rulesKey !== undefined) {
    obj.rulesKey = element.rulesKey;
  }
  if (element.values !== undefined) {
    transformDropDown(toorakProduct, createdOn, element);
    obj.values = element.values;
  }
  obj.type = element.type;
  obj.documentInfo = "";
  obj.documentInfoDB = "";
  obj.categories = [];
  obj.documents = [];
  obj.skipRules = element.skipRules;
  obj.field = element.field;
  obj.disableAfterApproved = element.disableAfterApproved;
  obj.auditHistory = editHistory?.[getFieldName(obj)];
  return obj;
};

const sortUserData = (users: any) => {
  users.sort((a: any, b: any) => {
    if (a.isPrimary !== b.isPrimary) {
      if (a.isPrimary) {
        return -1;
      }
      return 1;
    }
    if (Number(a?.loanUserSequence) !== Number(b?.loanUserSequence)) {
      return Number(a?.loanUserSequence) - Number(b?.loanUserSequence);
    }
    return Number(a?.loanUserMapId) - Number(b?.loanUserMapId);
  });
  return users;
};

const prepareUserArray = (
  userData: any[],
  type: string,
  isDSCR: boolean,
  editHistory?: any
) => {
  const userFields: any[] = [];
  if (userData?.length) {
    let fieldSet: any = isDSCR ? DSCRUserFields : BridgeUserFields;
    userData.forEach((user: any, index: number) => {
      fieldSet.forEach((element: any) => {
        let obj: any = {};
        obj.loanInfoDB = resolvePath(element.path, user);
        obj.loanInfo = formatData(obj.loanInfoDB, element.type);
        if (
          (element.partyType === user.customer.partyType ||
            element.partyType === undefined) &&
          ((type === "Guarantor" && element.guarantorAliasKey !== undefined) ||
            (type === "Borrower" && element.borrowerAliasKey !== undefined))
        ) {
          obj.partyType = user?.customer?.partyType;
          obj.userType = type;
          obj.index = user.index;
          obj.aliasKey =
            type === "Borrower"
              ? element.borrowerAliasKey
              : element.guarantorAliasKey;
          obj.identifier =
            element.ddIdentifier !== undefined
              ? element.ddIdentifier
              : element.borrowerAliasKey;
          obj.label = `${type} ${index + 1} ${element.label}`;
          obj.loanUserMapId = user.loanUserMapId;
          obj.loanUserSequence = user.loanUserSequence;
          obj.partyId = user?.customer?.partyId;
          obj.isPrimary = user.isPrimary;
          obj.comment = defaultComment.updatedThroughTTF;
          initializeObj(obj, element, null, editHistory);
          userFields.push(obj);
        }
      });
    });
  }
  return userFields;
};

export const getFormattedValue = (
  value: any,
  type: string,
  callback: Function,
  documentInfo?: any
) => {
  if (type === "billingaddress") {
    return getFormattedAddress(value, false, callback);
  }
  if (documentInfo !== undefined) {
    return prepareForSaveToDB(
      type,
      documentInfo,
      value
    )
  }
  return value;
};

export const getMissingTagsForTTFReview = (
  tags: string[],
  masterList: any[],
  isDSCR: boolean,
  loanDetails: any
) => {
  let missingTags: any[] = [];
  const minTags = isDSCR
    ? DSCR_REQUIRED_TAGS_FOR_TTFREVIEW
    : BRIDGE_REQUIRED_TAGS_FOR_TTFREVIEW;
  minTags.forEach((tagObj: any) => {
    if (
      !tags?.includes(tagObj.docCode) &&
      (tagObj.shouldBeOmitted ? !tagObj.shouldBeOmitted(loanDetails) : true)
    ) {
      missingTags.push(tagObj.docCode);
    }
  });
  if (missingTags.length) {
    tagGroups.forEach((group: string[]) => {
      if (group.every((val: string) => missingTags.includes(val))) {
        missingTags = missingTags.filter((tag: string) => !group.includes(tag));
        missingTags.push(group);
      } else if (group.some((val: string) => missingTags.includes(val))) {
        missingTags = missingTags.filter((tag: string) => !group.includes(tag));
      }
    });
  }
  const missingTagsDescriptive = missingTags.map((tag: string | string[]) => {
    if (Array.isArray(tag)) {
      return tag.reduce((prevVal: string, currVal: string, index: number) => {
        return index === 0
          ? getTagDataFromTagCode(currVal, masterList)
          : prevVal + " / " + getTagDataFromTagCode(currVal, masterList);
      }, "");
    }
    return getTagDataFromTagCode(tag, masterList);
  });
  return missingTagsDescriptive;
};

export const getTagDataFromTagCode = (code: string, masterList: any[]) => {
  return masterList.find((tag: any) => tag?.code === code)?.name;
};

export const updateHistoryOnSubmit = (
  loanFields: any,
  userFields: any,
  propertyFields: any,
  editHistory: any,
  dispatch: any
) => {
  const loanFieldsPayload: any = JSON.parse(JSON.stringify(loanFields));
  const userFieldsPayload: any = JSON.parse(JSON.stringify(userFields));
  const propertyFieldsPayload: any = JSON.parse(JSON.stringify(propertyFields));
  loanFieldsPayload.forEach((field: any) => {
    field.auditHistory = editHistory?.[getFieldName(field)];
  });
  userFieldsPayload.forEach((field: any) => {
    field.auditHistory = editHistory?.[getFieldName(field)];
  });
  propertyFieldsPayload.forEach((element: any) => {
    element.forEach((ele: any) => {
      ele.auditHistory = editHistory?.[getFieldName(ele)];
    });
  });
  // dispatch({
  //   type: UPDATE_TTF_FIELDS,
  //   payload: {
  //     loanFieldsPayload,
  //     userFieldsPayload,
  //     propertyFieldsPayload
  //   }
  // });
  updateTtfFields({
    loanFieldsPayload,
    userFieldsPayload,
    propertyFieldsPayload
  });
};

export const updateLocalReducerValue = (
  documentReviewDispatch: any,
  extractionSource: any[]
) => {
  const activePageNumber = extractionSource?.[0]?.pageNo;
  documentReviewDispatch({
    type: ttfAction.UPDATE_PAGE_OR_HIGHLIGHT_SET,
    payload: {
      value: {
        highlightCoordinates: extractionSource,
        ...((activePageNumber || activePageNumber === 0) && {
          activePageNumber
        })
      },
      tabToUpdate: tabIds.DOCUMENT_REVIEW
    }
  });
};

export const updateTTFDBCallFlag = (
  documentReviewDispatch: any,
  payload: any
) => {
  documentReviewDispatch({
    type: ttfAction.UPDATE_TTF_PARAMS,
    payload: {
      value: payload,
      tabToUpdate: tabIds.DOCUMENT_REVIEW
    }
  });
};

export const clearRadio = (
  dispatch: any,
  loanFields: any,
  userFields: any,
  propertyFields: any
) => {
  const newLoanFields = JSON.parse(JSON.stringify(loanFields));
  const newUserFields = JSON.parse(JSON.stringify(userFields));
  const newPropertyFields = JSON.parse(JSON.stringify(propertyFields));
  newLoanFields.forEach((item: any) => {
    delete item?.radio;
  });
  newUserFields.forEach((item: any) => {
    delete item?.radio;
  });
  newPropertyFields.forEach((property: any) => {
    property.forEach((item: any, propertyIndex: number) => {
      delete item?.radio;
    });
  });
  dispatch(
    updateTtfFields({
      loanFieldsPayload: newLoanFields,
      userFieldsPayload: newUserFields,
      propertyFieldsPayload: newPropertyFields
    })
  );
  // dispatch({
  //   type: UPDATE_TTF_FIELDS,
  //   payload: {
  //     loanFieldsPayload: newLoanFields,
  //     userFieldsPayload: newUserFields,
  //     propertyFieldsPayload: newPropertyFields
  //   }
  // });
};

export const groupByTags = (docList: ObjectType[]) => {
  const newDocList = JSON.parse(JSON.stringify(docList));
  const tagCounts: ObjectType = {};

  newDocList.forEach((obj: ObjectType, index: number) => {
    const code = obj.TagsInternal?.[0]?.tag?.code;
    if (tagCounts[code] === undefined) {
      tagCounts[code] = 1;
      obj.groupType = "start-end";
    } else {
      tagCounts[code]++;

      if (tagCounts[code] === 2) {
        newDocList[index - 1].groupType = "start";
      }
      if (
        index === newDocList.length - 1 ||
        code !== newDocList[index + 1]?.TagsInternal?.[0]?.tag?.code
      ) {
        obj.groupType = "end";
      } else {
        obj.groupType = "between";
      }
    }
  });

  return newDocList;
};

export const highLightMap = (
  key: string,
  index: number,
  documents: ObjectType[]
) => {
  const length = documents.length;
  const map: ObjectType = {
    start: {
      backgroundColor: "#faf1ad61",
      borderTop:
        index === 0 || documents[index - 1]?.groupType === "start-end"
          ? "2px solid #f7b500"
          : "1px solid #f7b500",
      borderLeft: "2px solid #f7b500",
      borderRight: "2px solid #f7b500",
      borderBottom: "none",
      padding: "2px 0"
    },
    between: {
      backgroundColor: "#faf1ad61",
      borderTop: "none",
      borderLeft: "2px solid #f7b500",
      borderRight: "2px solid #f7b500",
      borderBottom: "none",
      padding: "2px 0"
    },
    end: {
      backgroundColor: "#faf1ad61",
      borderTop: "none",
      borderLeft: "2px solid #f7b500",
      borderRight: "2px solid #f7b500",
      borderBottom:
        index === length - 1 || documents[index + 1]?.groupType === "start-end"
          ? "2px solid #f7b500"
          : "1px solid #f7b500",
      padding: "2px 0"
    },
    "start-end": {
      // backgroundColor: "#faf1ad61",
      // borderTop: index === 0 ? "2px solid #f7b500" : "1px solid #f7b500",
      // borderLeft: "2px solid #f7b500",
      // borderRight: "2px solid #f7b500",
      // borderBottom: index === length - 1 ? "2px solid #f7b500" : "1px solid #f7b500",
      padding: "2px 0"
    }
  };
  return map[key];
};

function isAllKeysNullExcluding(obj: ObjectType, excludeKeys: string[] = []) {
  if (!obj || typeof obj !== 'object') return true; // Handle null or non-object cases
  return Object.keys(obj)
      .filter(key => !excludeKeys.includes(key)) // Exclude specified keys
      .every(key =>  [null, ""].includes(obj[key]));
}

export function isDataCorrupt(saveObj: ObjectType) {
  const loans = saveObj?.documentInfo?.loans;

  if (!loans) return false;

  const conditions = [
      isAllKeysNullExcluding(loans?.loanEconomics),
      isAllKeysNullExcluding(loans?.loanInfo),
      isAllKeysNullExcluding(loans?.loanDetailId?.loanSummaryId),
      isAllKeysNullExcluding(loans?.loanFees),
      !Array.isArray(loans?.loanUserMap) || (Array.isArray(loans?.loanUserMap) && loans.loanUserMap.every((user: ObjectType) => 
          isAllKeysNullExcluding(user.customer, ['addressList', 'contactList', 'partyType']) &&
          isAllKeysNullExcluding(user.addressList?.[0]) &&
          isAllKeysNullExcluding(user.contactList?.[0])
      ))
  ];

  return conditions.every(condition => condition === true);
}
