/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  OutlinedInput,
  Checkbox,
  TextField
} from "@mui/material";
import { style, useDrawsStyles } from "../Draws.styles";
import { propertFormatter } from "../../../create-loan/loan-lists/DetailsView/ColumnFormatters";

import {
  DrawStage
  // ExpandCollapse,
} from "@toorak/tc-common-fe-sdk";
import { Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { isOrigUser } from "../../../utils/AccessManagement";

import { DrawRequestStore } from "../../../stores/drawRequest/drawRequest.interface";
import { RootState } from "../../../stores/rootReducer";
import {
  getDrawDetailsbyLoanId,
  updateDrawDetails
} from "../../../stores/drawRequest/drawRequest.action";
import { updateDrawSelectedProp } from "../../../stores/drawRequest/drawRequest.reducer";
import { useParams } from "react-router-dom";
import { useDebounceFn } from "../../../custom-hooks/useDebounce";

export const DrawLoanInformation = ({
  // availableLoanIds,
  // selectedLoanId,
  // handlePropertyChange,
  // selectedProperty,
  // handleLoanIdChange,
  isSubmitClicked,
  showErrorMsg,
  // drawObj,
  // getPropertyView,
  // propertyDetailsOptions,
  isBulkDraw,
  fieldWidth
}: any) => {
  const classes = useDrawsStyles();
  const isOrig = isOrigUser();
  const dispatch = useDispatch();
  const [availableLoanIds, setAvailableLoanIds] = useState<any>([]);
  const [selectedLoanId, setSelectedLoanId] = useState<string | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<string[]>([]);
  const [drawObj, setDrawObj] = useState<any>({});
  const { draft } = DrawStage;
  const isDraft = drawObj.stage === draft;
  const { drawId } = useParams<any>() as any;

  const {
    drawRequestType,
    loanIds,
    drawDetails,
    selectedBankDetails,
    propertyDetailsOptions
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  useEffect(() => {
    if (loanIds?.length) {
      const newLoanIds = loanIds.map((item, index) => ({
        key: item,
        value: item,
        index
      }));
      setAvailableLoanIds(newLoanIds);
    }
    // eslint-disable-next-line
  }, [loanIds]);

  useEffect(() => {
    if (drawDetails) {
      setDrawObj(drawDetails);
      const loanId: string = drawDetails?.primaryLoanId as string;
      setSelectedLoanId(loanId);
      drawDetails.propertyDetails &&
        setSelectedProperty(drawDetails.propertyDetails);
    }
    // eslint-disable-next-line
  }, [
    drawDetails.primaryLoanId,
    drawDetails.stage,
    drawDetails.propertyDetails
  ]);

  const handleLoanIdChange = (newVal: string | null) => {
    setSelectedLoanId(newVal);
    if (newVal) {
      dispatch(
        getDrawDetailsbyLoanId(
          drawRequestType !== "asset",
          newVal,
          true,
          drawDetails.drawAmountDetails,
          drawObj
        )
      );
    }
  };

  // const removeAllDuplicates = (array: any, key: any) => {
  //   const countMap = array.reduce((acc: any, current: any) => {
  //     const keyValue = current[key];
  //     acc[keyValue] = (acc[keyValue] || 0) + 1;
  //     return acc;
  //   }, {});

  //   return array.filter((item: any) => countMap[item[key]] === 1);
  // };

  const handlePropertyChange = (value: any) => {
    const newVal = typeof value === "string" ? value.split(",") : value;
    const newObjects = value.filter(
      (obj2:any) => !selectedProperty.some((obj1:any) => obj1.propertyId === obj2.propertyId)
    );
    setSelectedProperty(newObjects);
    dispatch(updateDrawSelectedProp(newObjects));
  };

  const debouncedDispatch = useDebounceFn(() => {
    try {
      dispatch(
        updateDrawDetails(
          drawDetails,
          selectedBankDetails,
          drawId,
          isOrig,
          drawDetails.stage,
          drawDetails.originatorId,
          false
        )
      );
      console.log("Dispatch successful");
    } catch (e) {
      console.error("Error in dispatching updateDrawDetails:", e);
    }
  }, 500); // Adjust debounce delay as needed

  useEffect(() => {
    if (!isDraft) return;
    if (!drawDetails.propertyDetails?.length) return;

    // Call the debounced function inside useEffect
    const cleanup = debouncedDispatch();

    // Cleanup the timeout on unmount or when dependencies change
    return cleanup;
  }, [selectedProperty, debouncedDispatch ]);

  const getPropertyView = () => {
    const propertyAddresses =
      selectedProperty.length && typeof selectedProperty[0] !== "string"
        ? selectedProperty.map((items: any) => {
            return items?.propertyAddress;
          })
        : selectedProperty;
    const AddressString = {
      row: { properties: propertyAddresses }
    };
    return propertFormatter(
      { row: AddressString.row, key: "propertyDetails" },
      classes,
      true
    );
  };
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Grid
      item
      container
      xs={12}
      data-testid="draw-view-itemContainer"
      className={classes.itemContainer}
    >
      <Grid
        item
        container
        xs={10}
        className={classes.itemHeaderText}
        data-testid="draw-view-itemHeaderText"
      >
        Loan Information
      </Grid>

      {/* <Grid
              item
              container
              xs={2}
              data-testid="draw-view-expandCollapse"
              className={classes.expandCollapse}
            >
              <ExpandCollapse
                isMinimize={isMinimizedLoanDetail}
                clicked={() => {
                  setIsMinimizedLoanDetail(!isMinimizedLoanDetail);
                }}
                keyDown={() => {
                  setIsMinimizedLoanDetail(!isMinimizedLoanDetail);
                }}
              />
            </Grid> */}
      <Grid
        item
        container
        xs={12}
        data-testid="draw-view-fieldContainer"
        className={classes.fieldContainer}
      >
        <Grid
          item
          container
          xs={fieldWidth}
          data-testid="draw-view-primary-loan"
          className={classes.fieldRow}
        >
          {isOrig && isDraft && !isBulkDraw ? (
            <Grid>
              <p className={classes.headerLoanID} data-testid="primary-loan-id">
                Primary Loan ID
                <span className={classes.asterick}>*</span>
              </p>

              <Autocomplete
                id="checkboxes-loanid-demo"
                data-testid="auto-complete-loanid-input"
                options={availableLoanIds}
                getOptionLabel={(option: any) => option?.value || option || ""}
                onChange={(event: any, newValue: any) => {
                  handleLoanIdChange(newValue?.value);
                }}
                value={selectedLoanId}
                renderOption={(props, option: any, { selected }) => {
                  return (
                    <li {...props} key={option?.index}>
                      {(option as any)?.key}
                    </li>
                  );
                }}
                style={style}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select"
                    />
                  );
                }}
              />
              {isSubmitClicked ? showErrorMsg("selectedLoanId") : null}
            </Grid>
          ) : (
            <div className={classes.itemBox}>
              <p
                className={classes.itemSubHeaderText}
                data-testid="draw-primary-loan-id"
              >
                Primary Loan ID
              </p>
              {drawObj?.primaryLoanId ? (
                <p
                  className={classes.itemValueText}
                  data-testid="primary-loan-value"
                >
                  {drawObj.primaryLoanId}
                </p>
              ) : (
                <p
                  className={classes.itemValueText}
                  data-testid="primary-loan-blank"
                >
                  __ __
                </p>
              )}
            </div>
          )}
        </Grid>
        <Grid
          item
          container
          xs={fieldWidth}
          data-testid="draw-property-details"
          className={classes.fieldRow}
        >
          {isOrig && isDraft ? (
            <Grid>
              <p
                className={`${classes.headerLoanID} ${
                  selectedLoanId === ""
                    ? classes.disabledHeaderText
                    : classes.headerLoanID
                }`}
              >
                Property Details
                <span
                  className={`${classes.asterick} ${
                    selectedLoanId === ""
                      ? classes.hiddenAsterick
                      : classes.asterick
                  }`}
                >
                  *
                </span>
              </p>
              <div>
                <FormControl
                  variant="outlined"
                  className={classes.formControlOption}
                >
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      }
                      // getContentAnchorEl: null
                    }}
                    value={selectedProperty}
                    multiple
                    disabled={selectedLoanId === ""}
                    labelId="select-property"
                    id="demo-simple-select-outlined"
                    placeholder="Select"
                    data-testid="draw-property-dropdown"
                    className={classes.selectRoot}
                    onChange={(e) => handlePropertyChange(e.target.value)}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected: any) =>
                      selected
                        .map((item: any) => {
                          return item.propertyAddress;
                        })
                        .join(", ")
                    }
                  >
                    {propertyDetailsOptions?.length > 0 &&
                      propertyDetailsOptions.map((item: any, index: any) => (
                        <MenuItem key={index} value={item}>
                          <Checkbox
                            checked={selectedProperty.some(
                              (prop: any) =>
                                prop?.propertyId === item?.propertyId
                            )}
                          />
                          <ListItemText primary={item.propertyAddress} />
                        </MenuItem>
                      ))}
                  </Select>
                  {isSubmitClicked ? showErrorMsg("selectedProperty") : null}
                </FormControl>
              </div>
            </Grid>
          ) : (
            <div className={classes.itemBox}>
              <p
                className={classes.itemSubHeaderText}
                data-testid="draw-property-value"
              >
                Property Details
              </p>
              {selectedProperty.length ? (
                <p
                  className={classes.itemValueText}
                  data-testid="draw-property-options"
                >
                  {getPropertyView()}
                </p>
              ) : (
                <p
                  className={classes.itemValueText}
                  data-testid="draw-property-blank"
                >
                  __ __
                </p>
              )}
            </div>
          )}
        </Grid>
        <Grid
          item
          container
          xs={fieldWidth}
          className={classes.fieldRow}
          data-testid="draw-toorak-loanId"
        >
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Toorak Loan ID</p>
            {drawObj?.toorakLoanId ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-loanId-value"
              >
                {drawObj.toorakLoanId}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-loanId-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Originator Name</p>
            {drawObj?.originatorName ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-originatorName-value"
              >
                {drawObj.originatorName}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-originatorName-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Servicer ID</p>
            {drawObj?.servicerId ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-servicerId-value"
              >
                {drawObj.servicerId}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-servicerId-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Servicer Name</p>
            {drawObj?.servicerName ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-servicerName-value"
              >
                {drawObj.servicerName}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-servicerName-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Loan Type</p>
            {drawObj?.loanType ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-loanType-value"
              >
                {drawObj.loanType}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-loanType-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Draw Number</p>
            {drawObj?.drawNumber ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-drawNumber-value"
              >
                {drawObj.drawNumber}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-drawNumber-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Borrower Name</p>
            {drawObj?.borrowerEntityName ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-borrowerEntityName-value"
              >
                {drawObj.borrowerEntityName}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-borrowerEntityName-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        <Grid item container xs={fieldWidth} className={classes.fieldRow}>
          <div className={classes.itemBox}>
            <p className={classes.itemSubHeaderText}>Address</p>
            {drawObj?.borrowerAddress ? (
              <p
                className={classes.itemValueText}
                data-testid="toorak-borrowerAddress-value"
              >
                {drawObj.borrowerAddress}
              </p>
            ) : (
              <p
                className={classes.itemValueText}
                data-testid="toorak-borrowerAddress-blank"
              >
                __ __
              </p>
            )}
          </div>
        </Grid>
        {/* <DrawBalanceToFinish loanId={selectedLoanId} /> */}
      </Grid>
    </Grid>
  );
};
