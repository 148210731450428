/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig, getLoanType } from "../../config/config";
import {
  LoanDetails,
  MultiplUnitInfo
} from "../../create-loan/create-loan.reducerHelper";
import {
  booleanToYorN,
  booleanToRecourseFes,
  formatValueByType,
  splitISODateTillT,
  yesornToBoolean,
  isEmptyValue,
  yesOrNoToYorN,
  getNumFromCommaSeparatedNum,
  formatNumberToCommaSeparated
} from "../../utils/formatChecks";
// import { uuidV4 } from "../../utils/utils";
import { getLocationObject } from "../../create-loan/create-loan.action";
// import { getCookie } from "../../utils/cookies";
import { percentageToFloat } from "../../create-loan/evaluation-results/helpers/ruleRequestBody";
import {
  costBasisFormula,
  LoanSizerEnum,
  totalRehabBudgeFormula
} from "../../frontend-sizer/FrontendSizerTemplate";
import {
  ErrorPriority,
  getErrorConfigObject
  // toorakSupportMessage
} from "../../ErrorHandler/ErrorConfigData";
import { errorMessageJson } from "../../ErrorHandler/ErrorMessageJson";
import {
  LeaseStatusEnum,
  rentDescriptionOld
} from "../../create-loan/constants/loanCreationDropDownValues";
// import { updateFesLoanDetailRedux } from "../../create-loan/create-loan.reducer";

/**
 * All this redux are already commented out in reducer
 */
// export const UPDATE_FES_LOAN_DETAILS = "UPDATE_FES_LOAN_DETAILS";
// export const UPDATE_FES_PROPERTY_DETAILS = "UPDATE_FES_PROPERTY_DETAILS";
// export const VALIDATE_FES_FORM = "VALIDATE_FES_FORM";
// export const EDIT_FES_SECTION = "EDIT_FES_SECTION";
// export const SET_SECTION_PREVIOUS_STATE = "SET_SECTION_PREVIOUS_STATE";
// export const GET_FES_LOAN_BY_ID_SUCCESS = "GET_FES_LOAN_BY_ID_SUCCESS";
// export const GET_FES_PROPERTY_BY_ID_SUCCESS = "GET_FES_PROPERTY_BY_ID_SUCCESS";
// export const SWITCH_LOAN_SIZER_TYPE = "SWITCH_LOAN_SIZER_TYPE";

// export function updateFesLoanDetails(
//   sectionName: string,
//   fieldName: string,
//   fieldValue: string | unknown
// ) {
//   return (dispatch: any) => {
//     dispatch(
//       updateFesLoanDetailRedux({
//         sectionName,
//         fieldName,
//         fieldValue
//       })
//     );
//   };
// }

// export function updateFesPropertyDetails(
//   sectionName: string,
//   fieldName: string,
//   fieldValue: string | number | unknown,
//   propertyId: string
// ) {
//   return async (dispatch: any) => {
//     dispatch(
//       updateFesPropertyDetails({
//         sectionName,
//         fieldName,
//         fieldValue,
//         propertyId
//       })
//     );
//   };
// }

// export function fesValidateForm() {
//   return async (dispatch: any) => {
//     dispatch({
//       type: VALIDATE_FES_FORM
//     });
//   };
// }

// export function fesEditSection(value: boolean, sectionName: string) {
//   return async (dispatch: any) => {
//     dispatch({
//       type: EDIT_FES_SECTION,
//       payload: value,
//       sectionValue: sectionName
//     });
//   };
// }

// export function fesSectionPrevState(
//   dataObj: any,
//   key: string,
//   propertyData: boolean
// ) {
//   return async (dispatch: any) => {
//     try {
//       dispatch({
//         type: SET_SECTION_PREVIOUS_STATE,
//         payload: {
//           dataObj,
//           keyInfo: key,
//           propertyData
//         }
//       });
//     } catch (error) {
//       console.log("error in get loan by id", error);
//     }
//   };
// }

// export function updateRedux(reduxData: any) {
//   return async (dispatch: any) => {
//     dispatch({
//       type: GET_FES_LOAN_BY_ID_SUCCESS,
//       payload: reduxData
//     });
//   };
// }

// export function updatePropertRedux(reduxData: any) {
//   return async (dispatch: any) => {
//     dispatch({
//       type: GET_FES_PROPERTY_BY_ID_SUCCESS,
//       payload: reduxData
//     });
//   };
// }

const getFundingChannelValueInSnakeCase = (fundingType: string) => {
  let fundingChannelValue = "";
  switch (fundingType) {
    case "Advance Funding":
      fundingChannelValue = "advance_funding";
      break;
    case "Table Funding":
      fundingChannelValue = "table_funding";
      break;
    case "Warehouse Funding":
      fundingChannelValue = "normal";
      break;
    default:
      fundingChannelValue = "normal";
  }
  return fundingChannelValue;
};

const getfundingChannelValue = (key: string) => {
  let fundingChannelValue = "";
  switch (key) {
    case "advance_funding":
      fundingChannelValue = "Advance Funding";
      break;
    case "table_funding":
      fundingChannelValue = "Table Funding";
      break;
    default:
      fundingChannelValue = "Normal";
  }
  return fundingChannelValue;
};

export function getLoanRequestObject(
  createLoanParams: any,
  loanId: any,
  loanType: string,
  condoEligibSave?: boolean,
  calculatedCondo?: string,
  loanStage?: LoanStage
) {
  const {
    fesBorrowerInformation,
    fesLoanInformation,
    fesLoanEconomics,
    fesLoanCondo,
    loanDetailId,
    dueDiligencePartyInfo,
    originatorInfo,
    loanSizerType,
    loanConfig,
    bridgeLoanBorrowerInformation
  } = createLoanParams;
  const {
    loanStructure,
    loanPurpose,
    cashoutFlag,
    recourse,
    originationDate,
    expectedClosingDate,
    borrowerProceeds,
    numberOfProperties,
    predominantState,
    pledgeOfEquity,
    predominantPropertyType,
    condoEligibility,
    loanTerm,
    calculatedCondoEligibility,
    exitStrategy,
    isPermitApprovalsAndFullPlansInPlace,
    irFundingSource,
    sizerRateLockPeriod,
    primaryLoanId,
    crossCollaterlization,
    fundingType,
    creditEvent
  } = fesLoanInformation;
  const {
    rehabBudget,
    existDebt,
    softCost,
    hardCost,
    originalLoanAmount,
    totalOriginationAndDiscountPoints,
    rehabAmount,
    interestRate,
    prepaymentPenalty,
    prepayPenaltyType,
    rateType,
    interestOnlyPeriod,
    interestReserve,
    includeOutOfPocketBudgetInARLTV
  } = fesLoanEconomics;
  const {
    borrowerExperience,
    foreignNationalString,
    borrowerType,
    borrowerCreditScore,
    borrowerGUCExperience,
    heavyRehabExperience,
    inState,
    partyId,
    borrowerLoanUserSequence,
    loanUserSequence
  } = fesBorrowerInformation;
  const partyType = borrowerType === "Entity" ? "account" : "person";
  const accountType = borrowerType === "Entity" ? "BORROWER" : "person";
  const borrowerInfo: any[] = [];

  const experience = borrowerExperience
    ? isEmptyValue(borrowerExperience)
      ? null
      : getNumFromCommaSeparatedNum(borrowerExperience)
    : isEmptyValue(bridgeLoanBorrowerInformation[0]?.payload.experience)
    ? null
    : getNumFromCommaSeparatedNum(
        bridgeLoanBorrowerInformation[0]?.payload.experience
      );

  const originalCreditScoreMedian = borrowerCreditScore
    ? isEmptyValue(borrowerCreditScore)
      ? null
      : getNumFromCommaSeparatedNum(borrowerCreditScore)
    : isEmptyValue(
        bridgeLoanBorrowerInformation[0]?.payload.originalCreditScoreMedian
      )
    ? null
    : getNumFromCommaSeparatedNum(
        bridgeLoanBorrowerInformation[0]?.payload.originalCreditScoreMedian
      );

  const updateBorrowerGUCExperience = borrowerGUCExperience
    ? isEmptyValue(borrowerGUCExperience)
      ? null
      : getNumFromCommaSeparatedNum(borrowerGUCExperience)
    : isEmptyValue(
        bridgeLoanBorrowerInformation[0]?.payload.borrowerGUCExperience
      )
    ? null
    : getNumFromCommaSeparatedNum(
        bridgeLoanBorrowerInformation[0]?.payload.borrowerGUCExperience
      );

  const updatedHeavyRehabExperience = heavyRehabExperience
    ? isEmptyValue(heavyRehabExperience)
      ? null
      : getNumFromCommaSeparatedNum(heavyRehabExperience)
    : isEmptyValue(
        bridgeLoanBorrowerInformation[0]?.payload.heavyRehabExperience
      )
    ? null
    : getNumFromCommaSeparatedNum(
        bridgeLoanBorrowerInformation[0]?.payload.heavyRehabExperience
      );
  const updatedInState =
    (inState
      ? isEmptyValue(inState)
        ? null
        : inState
      : null) ||
    (isEmptyValue(bridgeLoanBorrowerInformation[0]?.payload.inState)
      ? null
      : bridgeLoanBorrowerInformation[0]?.payload.inState);
  const newForeignNationalString =
    foreignNationalString ||
    bridgeLoanBorrowerInformation[0]?.payload.foreignNationalString || null;
  const party = partyId ?? null;
  const loanUserSequenceId = (loanUserSequence || borrowerLoanUserSequence) ?? "0";
  borrowerInfo.push({
    loanUserType: "Borrower",
    isPrimary: borrowerType !== "Entity",
    originalCreditScoreMedian: originalCreditScoreMedian?.toString(),
    originalCreditScoreReportDate: null,
    experience,
    borrowerGUCExperience: updateBorrowerGUCExperience,
    heavyRehabExperience: updatedHeavyRehabExperience,
    ownershipOfEntity: null,
    inState: updatedInState,
    partyId: party,
    loanUserSequence: loanUserSequenceId,
    customer: {
      accountName: "",
      accountType,
      addressList: [],
      contactList: [],
      dateOfBirth: null,
      firstName: "",
      middleName: null,
      lastName: null,
      partyType,
      foreignNationalString: newForeignNationalString
    }
  });
  const loanUserMap = [...borrowerInfo];

  // Boolean value only for loan request  body
  let selectedCrossValue;
  if (crossCollaterlization === "Y") {
    selectedCrossValue = true;
  } else if (crossCollaterlization === "N") {
    selectedCrossValue = false;
  }
  let isWarehouseFunded = false;
  const selectedFundingChannelValue =
    getFundingChannelValueInSnakeCase(fundingType);

  if (fundingType === "Warehouse Funding") isWarehouseFunded = true;

  const rehabBudgetValue =
    loanSizerType === LoanSizerEnum.multifamilyMixedUse
      ? totalRehabBudgeFormula({ loanDetails: createLoanParams })
      : rehabBudget;
  let loanRequestObject = {
    loanId: loanId.toString(),
    loanTypeId: 2,
    loanSizerType,
    loanType,
    loanExcelJson: "",
    // loanState: "in-progress",
    loanEconomics: {
      totalBudgetAmount: rehabBudgetValue
        ? rehabBudgetValue.replace("$", "")
        : rehabBudget?.replace("$", "") || null,
      originalLoanAmount: originalLoanAmount?.replace("$", "") || null,
      rehabAmount:
        totalRehabBudgeFormula({ loanDetails: createLoanParams })?.replace(
          "$",
          ""
        ) || null,
      existDebt: existDebt?.replace("$", "") || null,
      softCost: softCost?.replace("$", "") || null,
      hardCost: hardCost?.replace("$", "") || null,
      // originalLoanAmount:borrowerRquested,
      interestRateAsOfCutOffDate: interestRate
        ? Number(interestRate?.replace("%", ""))
        : null,
      prepaymentPenaltyMonths: prepaymentPenalty,
      prepayPenaltyType,
      rateType,
      interestOnlyPeriod: !isEmptyValue(interestOnlyPeriod)
        ? parseInt(interestOnlyPeriod, 10)
        : null,
      totalOriginationAndDiscountPoints: totalOriginationAndDiscountPoints
        ? `${totalOriginationAndDiscountPoints}`?.replace("%", "")
        : null,
      interestReserve: interestReserve?.replace("$", "").replace(/,/g, ""),
      includeOutOfPocketBudgetInARLTV: includeOutOfPocketBudgetInARLTV ?? true
    },
    loanInfo: {
      loanStructure: loanStructure || null,
      loanPurpose: loanPurpose || null,
      cashoutFlag: cashoutFlag || null,
      recourseString: recourse,
      pledgeOfEquity: yesornToBoolean(pledgeOfEquity),
      isPermitApprovalsAndFullPlansInPlace,
      expectedClosingDate,
      irFundingSource,
      sizerRateLockPeriod,
      borrowerProceeds: borrowerProceeds || null,
      noOfProperties: Number(numberOfProperties) || null,
      predominantState,
      predominantPropertyType,
      condoEligibility,
      originationDate,
      loanTermMonthly: Number(loanTerm) || null,
      calculatedCondoEligibility,
      exitStrategy: isEmptyValue(exitStrategy) ? null : exitStrategy,
      primaryLoanId,
      // takeoutPartnerId,
      crossCollaterlization: selectedCrossValue,
      creditEvent: creditEvent || "No"
    },
    loanUserMap,

    loanConfig: loanConfig || {},
    loanCondominium: {},
    loanDetailId: {
      ...loanDetailId,
      fundingType: selectedFundingChannelValue,
      isWarehouseFunded
    },
    dueDiligencePartyInfo,
    originatorInfo
  };
  if (loanStage !== LoanStage.fes) {
    loanRequestObject = {
      ...loanRequestObject,
      ...{ toorakProduct: loanSizerType }
    };
  }
  if (createLoanParams?.loanInfo) {
    loanRequestObject.loanInfo = {
      ...loanRequestObject.loanInfo,
      ...createLoanParams?.loanInfo
    };
  }
  if (condoEligibSave === true) {
    loanRequestObject.loanInfo.calculatedCondoEligibility =
      calculatedCondo || calculatedCondoEligibility;
    loanRequestObject.loanInfo.condoEligibility =
      calculatedCondo || condoEligibility;
    loanRequestObject.loanCondominium = {
      borrowerName: fesLoanCondo.borrowerName,
      monthlyHOAFee:
        fesLoanCondo.monthlyHOAFee !== null
          ? fesLoanCondo.monthlyHOAFee.replace("$", "")
          : null,
      hoaDuesAboveFifteen:
        fesLoanCondo.hoaDuesAboveFifteen !== null
          ? fesLoanCondo.hoaDuesAboveFifteen === "Yes"
          : null,
      hoaDuesAboveTwenty:
        fesLoanCondo.hoaDuesAboveTwenty !== null
          ? fesLoanCondo.hoaDuesAboveTwenty === "Yes"
          : null,
      reserveFundsForOneYear:
        fesLoanCondo.reserveFundsForOneYear !== null
          ? fesLoanCondo.reserveFundsForOneYear === "Yes"
          : null,
      reserveFundsForTwoYears:
        fesLoanCondo.reserveFundsForTwoYears !== null
          ? fesLoanCondo.reserveFundsForTwoYears === "Yes"
          : null,
      isProjectCompleted:
        fesLoanCondo.isProjectCompleted !== null
          ? fesLoanCondo.isProjectCompleted === "Yes"
          : null,
      subjectToAdditionalPhasing:
        fesLoanCondo.subjectToAdditionalPhasing !== null
          ? fesLoanCondo.subjectToAdditionalPhasing === "Yes"
          : null,
      soldPercentage:
        fesLoanCondo.soldPercentage !== null
          ? fesLoanCondo.soldPercentage.replace("%", "") / 100 || null
          : null,
      hoaUnderOwnerControl:
        fesLoanCondo.hoaUnderOwnerControl !== null
          ? fesLoanCondo.hoaUnderOwnerControl === "Yes"
          : null,
      simpleEstateOwnership:
        fesLoanCondo.simpleEstateOwnership !== null
          ? fesLoanCondo.simpleEstateOwnership === "Yes"
          : null,
      ownerOccupiedUnits: Number(fesLoanCondo.ownerOccupiedUnits) || null,
      renterOccupiedUnits: Number(fesLoanCondo.renterOccupiedUnits) || null,
      renterOccupiedPercentage:
        fesLoanCondo.renterOccupiedPercentage !== null
          ? fesLoanCondo.renterOccupiedPercentage.replace("%", "") / 100 || null
          : null,
      individualOwnershipAboveTwentyFive:
        fesLoanCondo.individualOwnershipAboveTwentyFive !== null
          ? fesLoanCondo.individualOwnershipAboveTwentyFive === "Yes"
          : null,
      masterAssurancePolicy:
        fesLoanCondo.masterAssurancePolicy !== null
          ? fesLoanCondo.masterAssurancePolicy === "Yes"
          : null,
      projectHasMobileHomes:
        fesLoanCondo.projectHasMobileHomes !== null
          ? fesLoanCondo.projectHasMobileHomes === "Yes"
          : null,
      otherSourcesIncomeMoreThanTwenty:
        fesLoanCondo.otherSourcesIncomeMoreThanTwenty !== null
          ? fesLoanCondo.otherSourcesIncomeMoreThanTwenty === "Yes"
          : null,
      commercialPurposesAreaMoreThanForty:
        fesLoanCondo.commercialPurposesAreaMoreThanForty !== null
          ? fesLoanCondo.commercialPurposesAreaMoreThanForty === "Yes"
          : null,
      restrictOwnerToRent:
        fesLoanCondo.restrictOwnerToRent !== null
          ? fesLoanCondo.restrictOwnerToRent === "Yes"
          : null,
      documentsWithSEC:
        fesLoanCondo.documentsWithSEC !== null
          ? fesLoanCondo.documentsWithSEC === "Yes"
          : null,
      hoawithLitOrBankruptcy:
        fesLoanCondo.hoawithLitOrBankruptcy !== null
          ? fesLoanCondo.hoawithLitOrBankruptcy === "Yes"
          : null,
      ownerControlStartDate:
        fesLoanCondo.ownerControlStartDate !== ""
          ? fesLoanCondo.ownerControlStartDate
          : null,
      indivdualOwnedUnits: Number(fesLoanCondo.indivdualOwnedUnits),
      hoawithLitOrBankruptcyDesc: fesLoanCondo.hoawithLitOrBankruptcyDesc,
      restrictOwnerToRentOutDesc: fesLoanCondo.restrictOwnerToRentOutDesc,
      condoProjectName: fesLoanCondo.condoProjectName,
      streetAddressLine1: fesLoanCondo.streetAddressLine1,
      noOfUnits: Number(fesLoanCondo.noOfUnits),
      state: fesLoanCondo.state,
      city: fesLoanCondo.city,
      postalCode: fesLoanCondo.postalCode
    };
  }
  return loanRequestObject;
}

export function postFesLoan(
  createLoanParams: any,
  loanId: any,
  loanType: string,
  loanStage: LoanStage,
  condoEligibSave?: boolean,
  calculatedCondoEligibility?: string,
  autoSave?: boolean
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/loans`;
    const loanRequestObject = getLoanRequestObject(
      createLoanParams,
      loanId,
      loanType,
      condoEligibSave,
      calculatedCondoEligibility,
      loanStage
    );
    const requestObject = loanRequestObject;
    publicClient
      .post(url, requestObject, {
        errorConfig: getErrorConfigObject({
          errorPriority: autoSave ? ErrorPriority.low : ErrorPriority.medium,
          customErrorMessage: {
            heading: "Failed to save loan.",
            body: autoSave
              ? errorMessageJson.postLoanAutoSave.message
              : errorMessageJson.postLoan.message
          }
        })
      } as any)
      .then((res: { data: any }) => resolve(res.data))
      .catch((e: any) => {
        reject(e);
      });
  });
}
export function fetchCondoEligibility(
  createLoanParams: any,
  loanId: any,
  loanType: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const { fesLoanCondo } = createLoanParams;
    const url = `${
      getConfig().apiUrl
    }/template/fetch?locale=NA&countryCode=NA&partialProcess=false`;
    const requestObject = {
      templateKeys: ["rule.InvestorDSCR.condominium"],
      data: {
        loan: {
          loanCondominium: {
            monthlyHOAFee: fesLoanCondo.monthlyHOAFee,
            hoaDuesAboveFifteen:
              fesLoanCondo.hoaDuesAboveFifteen !== null
                ? fesLoanCondo.hoaDuesAboveFifteen
                : null,
            hoaDuesAboveTwenty:
              fesLoanCondo.hoaDuesAboveTwenty !== null
                ? fesLoanCondo.hoaDuesAboveTwenty
                : null,
            reserveFundsForOneYear:
              fesLoanCondo.reserveFundsForOneYear !== null
                ? fesLoanCondo.reserveFundsForOneYear
                : null,
            reserveFundsForTwoYears:
              fesLoanCondo.reserveFundsForTwoYears !== null
                ? fesLoanCondo.reserveFundsForTwoYears
                : null,
            isProjectCompleted:
              fesLoanCondo.isProjectCompleted !== null
                ? fesLoanCondo.isProjectCompleted
                : null,
            subjectToAdditionalPhasing:
              fesLoanCondo.subjectToAdditionalPhasing !== null
                ? fesLoanCondo.subjectToAdditionalPhasing
                : null,
            soldPercentage:
              fesLoanCondo.soldPercentage !== null
                ? fesLoanCondo.soldPercentage.replace("%", "") / 100 || null
                : null,
            hoaUnderOwnerControl:
              fesLoanCondo.hoaUnderOwnerControl !== null
                ? fesLoanCondo.hoaUnderOwnerControl
                : null,
            simpleEstateOwnership:
              fesLoanCondo.simpleEstateOwnership !== null
                ? fesLoanCondo.simpleEstateOwnership
                : null,
            ownerOccupiedUnits: Number(fesLoanCondo.ownerOccupiedUnits) || null,
            renterOccupiedUnits:
              Number(fesLoanCondo.renterOccupiedUnits) || null,
            renterOccupiedPercentage:
              fesLoanCondo.renterOccupiedPercentage !== null
                ? fesLoanCondo.renterOccupiedPercentage.replace("%", "") /
                    100 || null
                : null,
            individualOwnershipAboveTwentyFive:
              fesLoanCondo.individualOwnershipAboveTwentyFive !== null
                ? fesLoanCondo.individualOwnershipAboveTwentyFive
                : null,
            masterAssurancePolicy:
              fesLoanCondo.masterAssurancePolicy !== null
                ? fesLoanCondo.masterAssurancePolicy
                : null,
            projectHasMobileHomes:
              fesLoanCondo.projectHasMobileHomes !== null
                ? fesLoanCondo.projectHasMobileHomes
                : null,
            otherSourcesIncomeMoreThanTwenty:
              fesLoanCondo.otherSourcesIncomeMoreThanTwenty !== null
                ? fesLoanCondo.otherSourcesIncomeMoreThanTwenty
                : null,
            commercialPurposesAreaMoreThanForty:
              fesLoanCondo.commercialPurposesAreaMoreThanForty !== null
                ? fesLoanCondo.commercialPurposesAreaMoreThanForty
                : null,
            restrictOwnerToRent:
              fesLoanCondo.restrictOwnerToRent !== null
                ? fesLoanCondo.restrictOwnerToRent
                : null,
            documentsWithSEC:
              fesLoanCondo.documentsWithSEC !== null
                ? fesLoanCondo.documentsWithSEC
                : null,
            hoawithLitOrBankruptcy:
              fesLoanCondo.hoawithLitOrBankruptcy !== null
                ? fesLoanCondo.hoawithLitOrBankruptcy
                : null,
            ownerControlStartDate: fesLoanCondo.ownerControlStartDate,
            indivdualOwnedUnits: Number(fesLoanCondo.indivdualOwnedUnits),
            hoawithLitOrBankruptcyDesc: fesLoanCondo.hoawithLitOrBankruptcyDesc,
            restrictOwnerToRentOutDesc: fesLoanCondo.restrictOwnerToRentOutDesc,
            condoProjectName: fesLoanCondo.condoProjectName,
            streetAddressLine1: fesLoanCondo.streetAddressLine1,
            noOfUnits: Number(fesLoanCondo.noOfUnits),
            state: fesLoanCondo.state,
            city: fesLoanCondo.city,
            postalCode: fesLoanCondo.postalCode
          }
        }
      }
    };
    publicClient
      .post(url, requestObject)
      .then((res: { data: any }) => {
        resolve(res.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
}

export function downLoadCondo(
  createLoanParams: any,
  loanId: any,
  loanType: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/loans/condo`;
    const loanRequestObject = getLoanRequestObject(
      createLoanParams,
      loanId,
      loanType,
      true
    );
    const requestObject = loanRequestObject;
    publicClient
      .post(url, requestObject)
      .then((res: { data: any }) => resolve(res.data))
      .catch((e: any) => {
        reject(e);
      });
  });
}

export function formatFesBorrowerInformation(loanData: any) {
  const loan: any = JSON.parse(JSON.stringify(loanData));
  const dataToBeReturned: any = [];
  loan.bridgeLoanBorrowerInformation?.map((bInfo: any) => {
    let borrowerExp: null | number = bInfo?.payload?.borrowerExperience;
    const partyType: any = bInfo?.payload?.borrowerType;
    dataToBeReturned.push({
      errors: bInfo.errors,
      borrowerId: bInfo.borrowerId,
      payload: {
        borrowerExperience:
          loan?.fesBorrowerInformation?.borrowerExperience ||
          borrowerExp ||
          bInfo?.payload?.experience,
        borrowerGUCExperience:
          loan?.fesBorrowerInformation?.borrowerGUCExperience ||
          loan?.loanUserMap?.[0]?.borrowerGUCExperience ||
          bInfo?.payload?.borrowerGUCExperience,
        heavyRehabExperience:
          loan?.fesBorrowerInformation?.heavyRehabExperience ||
          loan?.loanUserMap?.[0]?.heavyRehabExperience ||
          bInfo?.payload?.borrowerGUCExperience,
        loanUserMapId:
          loan?.fesBorrowerInformation?.loanUserMapId ||
          (loan?.loanUserMap?.length
            ? loan.loanUserMap[0].loanUserMapId
            : null),
        borrowerCreditScore:
          loan?.fesBorrowerInformation?.borrowerCreditScore ||
          (loan?.loanUserMap?.length
            ? loan.loanUserMap[0].originalCreditScoreMedian
            : bInfo?.payload?.originalCreditScoreMedian),
        foreignNationalString:
          loan?.fesBorrowerInformation?.foreignNationalString ||
          loan?.loanUserMap?.[0]?.customer?.foreignNationalString ||
          bInfo?.payload?.foreignNationalString,
        borrowerType: loan?.fesBorrowerInformation?.borrowerType || partyType,
        inState:
          loan?.fesBorrowerInformation?.inState ||
          loan?.loanUserMap?.[0]?.inState ||
          bInfo?.payload?.inState,
        partyId:
          loan?.fesBorrowerInformation?.partyId ||
          loan?.loanUserMap?.[0]?.customer?.partyId
      }
    });
  });
  return dataToBeReturned;
}

export function formatFesLoanDetailsForRedux(loanData: any) {
  const loan: any = JSON.parse(JSON.stringify(loanData));
  let borrowerExp: null | number = null;
  let partyType: string = "";
  if (
    loan?.loanUserMap &&
    loan?.loanUserMap?.length &&
    !isEmptyValue(loan.loanUserMap[0]?.experience)
  ) {
    if (typeof loan.loanUserMap[0].experience === "object") {
      if (Object.keys(loan.loanUserMap[0]?.experience).length === 0)
        borrowerExp = 0;
      else borrowerExp = loan.loanUserMap[0]?.experience?.years || "";
    } else {
      borrowerExp =
        loan.loanUserMap[0]?.experience >= 0
          ? loan.loanUserMap[0]?.experience
          : "";
    }
  }

  partyType =
    loan?.loanUserMap?.length &&
    loan?.loanUserMap?.[0]?.customer?.partyType === "account"
      ? "Entity"
      : "Individual";

  let selectedCrossValue = "N";
  if (loan?.loanInfo?.crossCollaterlization) {
    selectedCrossValue = "Y";
  }

  let selectedFundingChannelValue;
  let isWarehouseFunded = false;
  if (loan.loanDetailId.isWarehouseFunded === true) {
    selectedFundingChannelValue = "Warehouse Funding";
    isWarehouseFunded = true;
  } else {
    selectedFundingChannelValue = getfundingChannelValue(
      loan.loanDetailId.fundingType
    );
  }

  const returnObject = {
    loanDetails: {
      loanState: loan.loanState,
      loanSizerType: loan.loanSizerType || LoanSizerEnum.oneFourFamily,
      fesBorrowerInformation: {
        borrowerExperience: borrowerExp,
        borrowerGUCExperience: loan?.loanUserMap?.length
          ? loan?.loanUserMap[0]?.borrowerGUCExperience
          : "",
        heavyRehabExperience: loan?.loanUserMap?.length
          ? loan?.loanUserMap[0]?.heavyRehabExperience
          : "",
        loanUserMapId: loan?.loanUserMap?.length
          ? loan.loanUserMap[0].loanUserMapId
          : "",
        borrowerCreditScore: loan?.loanUserMap?.length
          ? loan.loanUserMap[0].originalCreditScoreMedian
          : "",
        foreignNationalString: loan?.loanUserMap?.length
          ? loan?.loanUserMap[0]?.customer?.foreignNationalString
          : "",
        borrowerType: partyType,
        inState: loan?.loanUserMap?.length ? loan?.loanUserMap[0]?.inState : "",
        partyId: loan?.loanUserMap?.length
          ? loan?.loanUserMap[0]?.customer?.partyId
          : "",
        loanUserSequence : loan?.loanUserMap?.length ? loan?.loanUserMap[0]?.loanUserSequence : ""
      },
      fesLoanEconomics: {
        totalOriginationAndDiscountPoints: loan?.loanEconomics
          ?.totalOriginationAndDiscountPoints
          ? formatValueByType(
              loan?.loanEconomics?.totalOriginationAndDiscountPoints,
              "percentageWithout100x"
            )
          : null,
        existDebt: loan?.loanEconomics?.existDebt
          ? formatValueByType(loan?.loanEconomics?.existDebt, "currency")
          : null,
        hardCost: loan?.loanEconomics?.hardCost
          ? formatValueByType(loan?.loanEconomics?.hardCost, "currency")
          : null,
        softCost: loan?.loanEconomics?.softCost
          ? formatValueByType(loan?.loanEconomics?.softCost, "currency")
          : null,
        originalLoanAmount: loan?.loanEconomics?.originalLoanAmount
          ? formatValueByType(
              loan?.loanEconomics?.originalLoanAmount,
              "currency"
            )
          : null,
        interestOnlyPeriod: formatValueByType(
          loan?.loanEconomics?.interestOnlyPeriod,
          "number"
        ),
        interestRate: !isEmptyValue(
          loan?.loanEconomics?.interestRateAsOfCutOffDate
        )
          ? formatValueByType(
              loan?.loanEconomics?.interestRateAsOfCutOffDate,
              "percentageWithout100x"
            )
          : null,
        prepaymentPenalty: loan?.loanEconomics?.prepaymentPenaltyMonths,
        prepayPenaltyType: loan?.loanEconomics?.prepayPenaltyType,
        rateType: loan?.loanEconomics?.rateType || "Fixed",
        rehabAmount: loan?.loanEconomics?.rehabAmount
          ? formatValueByType(loan?.loanEconomics?.rehabAmount, "currency")
          : null,
        rehabBudget: loan?.loanEconomics?.totalBudgetAmount
          ? formatValueByType(
              loan?.loanEconomics?.totalBudgetAmount,
              "currency"
            )
          : null,
        interestReserve: loan?.loanEconomics?.interestReserve
          ? formatValueByType(loan?.loanEconomics?.interestReserve, "currency")
          : null,
        includeOutOfPocketBudgetInARLTV:
          loan?.loanEconomics?.includeOutOfPocketBudgetInARLTV ?? true
      },
      fesLoanInformation: {
        cashoutFlag: loan?.loanInfo?.cashoutFlag || "",
        borrowerProceeds: loan?.loanInfo?.borrowerProceeds,
        expectedClosingDate: loan?.loanInfo?.expectedClosingDate,
        loanStructure: loan?.loanInfo?.loanStructure || "",
        loanPurpose: loan?.loanInfo?.loanPurpose || "",
        exitStrategy: loan?.loanInfo?.exitStrategy || "",
        primaryLoanId: loan?.loanInfo?.primaryLoanId ?? "",
        numberOfProperties: loan?.loanInfo?.noOfProperties || null,
        pledgeOfEquity: booleanToYorN(loan?.loanInfo?.pledgeOfEquity, true),
        predominantState: loan?.loanInfo?.predominantState,
        recourse: isEmptyValue(loan?.loanInfo?.recourseString)
          ? booleanToRecourseFes(loan?.loanInfo?.recourse)
          : loan?.loanInfo?.recourseString,
        originationDate: loan?.loanInfo?.originationDate,
        predominantPropertyType: loan?.loanInfo?.predominantPropertyType,
        condoEligibility: loan?.loanInfo?.condoEligibility,
        loanTerm:
          (loan.loanType === getLoanType[1].loanCreationName
            ? loan?.loanInfo?.loanTermMonthly || 360
            : loan?.loanInfo?.loanTermMonthly) || loan?.loanInfo?.loanTerm,
        calculatedCondoEligibility: loan?.loanInfo?.calculatedCondoEligibility,
        irFundingSource: loan?.loanInfo?.irFundingSource,
        sizerRateLockPeriod: loan.loanDetailId.loanConfigId.sizerRateLockPeriod,
        fundingType: selectedFundingChannelValue, // required while read
        isPermitApprovalsAndFullPlansInPlace:
          loan?.loanInfo?.isPermitApprovalsAndFullPlansInPlace,
        primaryLoanID: loan?.loanInfo?.primaryLoanId || "",
        takeoutPartnerId: loan?.loanInfo?.takeoutPartner,
        takeoutPartnerStatus: loan?.loanInfo?.takeoutPartnerStatus,
        eligibleTakeoutPartners: loan.eligibleTakeoutPartners,
        crossCollaterlization: selectedCrossValue,
        // selectedCross: selectedCrossValue,
        creditEvent: loan?.loanInfo?.creditEvent || "No"
      },
      fesLoanCondo: {
        monthlyHOAFee: loan?.loanCondominium?.monthlyHOAFee,
        hoaDuesAboveFifteen:
          loan?.loanCondominium?.hoaDuesAboveFifteen !== null
            ? loan?.loanCondominium?.hoaDuesAboveFifteen !== true
              ? "Yes"
              : "No"
            : null,
        hoaDuesAboveTwenty:
          loan?.loanCondominium?.hoaDuesAboveTwenty !== null
            ? loan?.loanCondominium?.hoaDuesAboveTwenty === true
              ? "Yes"
              : "No"
            : null,
        reserveFundsForOneYear:
          loan?.loanCondominium?.reserveFundsForOneYear !== null
            ? loan?.loanCondominium?.reserveFundsForOneYear === true
              ? "Yes"
              : "No"
            : null,
        reserveFundsForTwoYears:
          loan?.loanCondominium?.reserveFundsForTwoYears !== null
            ? loan?.loanCondominium?.reserveFundsForTwoYears === true
              ? "Yes"
              : "No"
            : null,
        isProjectCompleted:
          loan?.loanCondominium?.isProjectCompleted !== null
            ? loan?.loanCondominium?.isProjectCompleted === true
              ? "Yes"
              : "No"
            : null,
        subjectToAdditionalPhasing:
          loan?.loanCondominium?.subjectToAdditionalPhasing !== null
            ? loan?.loanCondominium?.subjectToAdditionalPhasing === true
              ? "Yes"
              : "No"
            : null,
        soldPercentage:
          loan?.loanCondominium?.soldPercentage !== null
            ? JSON.stringify(loan?.loanCondominium?.soldPercentage * 100)
            : null,
        hoaUnderOwnerControl:
          loan?.loanCondominium?.hoaUnderOwnerControl !== null
            ? loan?.loanCondominium?.hoaUnderOwnerControl === true
              ? "Yes"
              : "No"
            : null,
        simpleEstateOwnership:
          loan?.loanCondominium?.simpleEstateOwnership !== null
            ? loan?.loanCondominium?.simpleEstateOwnership === true
              ? "Yes"
              : "No"
            : null,
        ownerOccupiedUnits: loan?.loanCondominium?.ownerOccupiedUnits,
        renterOccupiedUnits: loan?.loanCondominium?.renterOccupiedUnits,
        renterOccupiedPercentage:
          loan?.loanCondominium?.renterOccupiedPercentage !== null
            ? JSON.stringify(
                loan?.loanCondominium?.renterOccupiedPercentage * 100
              )
            : null,
        individualOwnershipAboveTwentyFive:
          loan?.loanCondominium?.individualOwnershipAboveTwentyFive !== null
            ? loan?.loanCondominium?.individualOwnershipAboveTwentyFive === true
              ? "Yes"
              : "No"
            : null,
        masterAssurancePolicy:
          loan?.loanCondominium?.masterAssurancePolicy !== null
            ? loan?.loanCondominium?.masterAssurancePolicy === true
              ? "Yes"
              : "No"
            : null,
        projectHasMobileHomes:
          loan?.loanCondominium?.projectHasMobileHomes !== null
            ? loan?.loanCondominium?.projectHasMobileHomes === true
              ? "Yes"
              : "No"
            : null,
        otherSourcesIncomeMoreThanTwenty:
          loan?.loanCondominium?.otherSourcesIncomeMoreThanTwenty !== null
            ? loan?.loanCondominium?.otherSourcesIncomeMoreThanTwenty === true
              ? "Yes"
              : "No"
            : null,
        commercialPurposesAreaMoreThanForty:
          loan?.loanCondominium?.commercialPurposesAreaMoreThanForty !== null
            ? loan?.loanCondominium?.commercialPurposesAreaMoreThanForty ===
              true
              ? "Yes"
              : "No"
            : null,
        restrictOwnerToRent:
          loan?.loanCondominium?.restrictOwnerToRent !== null
            ? loan?.loanCondominium?.restrictOwnerToRent === true
              ? "Yes"
              : "No"
            : null,
        documentsWithSEC:
          loan?.loanCondominium?.documentsWithSEC !== null
            ? loan?.loanCondominium?.documentsWithSEC === true
              ? "Yes"
              : "No"
            : null,
        hoawithLitOrBankruptcy:
          loan?.loanCondominium?.hoawithLitOrBankruptcy !== null
            ? loan?.loanCondominium?.hoawithLitOrBankruptcy === true
              ? "Yes"
              : "No"
            : null,
        ownerControlStartDate: loan?.loanCondominium?.ownerControlStartDate,
        indivdualOwnedUnits: loan?.loanCondominium?.indivdualOwnedUnits,
        hoawithLitOrBankruptcyDesc:
          loan?.loanCondominium?.hoawithLitOrBankruptcyDesc,
        restrictOwnerToRentOutDesc:
          loan?.loanCondominium?.restrictOwnerToRentOutDesc,
        condoProjectName: loan?.loanCondominium?.condoProjectName,
        streetAddressLine1: loan?.loanCondominium?.streetAddressLine1,
        noOfUnits: loan?.loanCondominium?.noOfUnits,
        state: loan?.loanCondominium?.state,
        city: loan?.loanCondominium?.city,
        postalCode: loan?.loanCondominium?.postalCode
      },
      loanDetailId: {
        ...loan.loanDetailId,
        fundingType: selectedFundingChannelValue,
        isWarehouseFunded
      },
      dueDiligencePartyInfo: loan?.dueDiligencePartyInfo,
      originatorInfo: loan.originatorInfo,
      loanRuleVersions: loan.loanRuleVersions,
      sizerRateLockExpiryDate: loan.sizerRateLockExpiryDate,
      loanLandmarks: loan.loanDetailId?.loanLandmarks
    }
  };
  if (!returnObject.loanDetails.loanRuleVersions) {
    delete returnObject.loanDetails.loanRuleVersions;
  }
  return returnObject;
}
export function generateFesPostPropertyData(
  loanId: string,
  propertyDetails: any,
  loanSizerType: any,
  loanType?: string,
  skipAudit?: boolean
) {
  const properties: any[] = [];
  propertyDetails.forEach((element: any, index: number) => {
    const {
      propertyId,
      propertyLocation,
      fesPropertyInformation,
      fesPropertyEconomics,
      unitsInformation
    } = element;
    const {
      propertyType,
      numberOfUnits,
      changeInUseCase,
      valuationSource,
      squareFootage,
      propertyCondition,
      propertyOriginallyAcquired,
      isPropertyOnDebt,
      propertyValuationDate,
      isPropertyPurchasedLast2Years,
      isEntitledLand
    } = fesPropertyInformation?.payload ?? {};
    const {
      originalAsIsAppraisalValue,
      purchasePrice,
      borrowerPaidCosts,
      grossCondoSelloutValue,
      originalAsRepairedAppraisedValue,
      costBasis,
      closingCost,
      additionalEligibleCost,
      grossPotentialRent,
      appraisalReportedNoiNcf,
      annualPropertyTaxes,
      insurance,
      annualHazardInsurance,
      annualFloodInsurance,
      annualHOAFee,
      thirdPartyValuationStatus,
      thirdPartyValuation,
      marketRentPerMonth,
      assignmentFees,
      decliningMarkets
    } = fesPropertyEconomics?.payload ?? {};
    const { zipCode, state, city, invalidAddress } = propertyLocation.payload;
    let propertyUnits = [];
    if (unitsInformation?.length) {
      propertyUnits = unitsInformation.map(
        (unitItem: {
          errors: number | null;
          unitId: number;
          payload: MultiplUnitInfo;
        }) => {
          const {
            leaseStatus,
            currentLeaseTermMonthly,
            marketRentMonthly,
            inPlaceLeaseRent,
            recentLeaseRent,
            leaseStartDate,
            leaseEndDate,
            monthlyLease,
            leaseDurationGreaterThan12M,
            leaseDurationLessThan12M,
            vacancyStatus,
            unitCategory,
            percentageOccupied
          } = unitItem.payload;
          return {
            propertyUnitId: unitItem.unitId,
            leaseEndDate,
            leaseStartDate,
            monthlyLease,
            leaseDurationMorethan12Mos: leaseDurationGreaterThan12M,
            leaseDurationLessthan12Mos: leaseDurationLessThan12M,
            vacancyStatus,
            leaseStatus,
            unitCategory,
            percentageOccupied: percentageOccupied
              ? percentageOccupied?.replace("%", "").replace(/,/g, "")
              : null,
            marketRentMonthly: marketRentMonthly
              ? marketRentMonthly?.replace("$", "").replace(/,/g, "")
              : null,
            currentLeaseTermMonthly: isEmptyValue(currentLeaseTermMonthly)
              ? null
              : Number(currentLeaseTermMonthly),
            inPlaceLeaseRentMonthly: inPlaceLeaseRent
              ? inPlaceLeaseRent?.replace("$", "").replace(/,/g, "")
              : null,
            mostRecentLeaseRentMonthly: recentLeaseRent
              ? recentLeaseRent?.replace("$", "").replace(/,/g, "")
              : null
          };
        }
      );
    }
    let addressValueToPass = "";
    if (propertyLocation.payload) {
      const { address } = propertyLocation.payload;
      if (address && typeof address === "object") {
        addressValueToPass = address.street_line;
      } else if (address && address !== "") {
        addressValueToPass = address;
      }
    }
    const costBasisValue = costBasisFormula(element, loanSizerType);

    let marketRentPerMonthFormat = marketRentPerMonth
      ?.replace("$", "")
      .replace(/,/g, "");
    try {
      marketRentPerMonthFormat =
        marketRentPerMonthFormat?.trim() === ""
          ? null
          : marketRentPerMonthFormat;
    } catch (e) {
      marketRentPerMonthFormat = null;
    }
    let propertyRequestObject: any = {
      loanId,
      loanPropertyId: +propertyId,
      loanPropertyOrder: index + 1,
      propertyinfo: {
        propertyUnits: !isEmptyValue(numberOfUnits)
          ? getNumFromCommaSeparatedNum(numberOfUnits)
          : null,
        propertyType: propertyType || null,
        changeInUseCase: yesornToBoolean(changeInUseCase),
        valuationSource: valuationSource || null,
        isPropertyTypeCondo: "",
        isPropertyTypeTwoFourUnit: "",
        squareFootage: squareFootage
          ? getNumFromCommaSeparatedNum(squareFootage)
          : null,
        propertyCondition,
        propertyAcquisitionDate: propertyOriginallyAcquired,
        isPurchasedWithDebt: isPropertyOnDebt,
        recentPropertyValuationDate: propertyValuationDate,
        isPropertyPurchasedLast2Years,
        isEntitledLand
      },
      propertyLocation: {
        addressLine1: addressValueToPass,
        addressLine2: "",
        city,
        state,
        postalCode: zipCode,
        country: "",
        locationValidationStatus: invalidAddress ? "NONE" : "COMPLETE"
      },
      propertyEconomics: {
        assignmentFees: assignmentFees?.replace?.("$", "") || null,
        originalAsIsAppraisalValue:
          originalAsIsAppraisalValue?.replace("$", "") || null,
        originalAsRepairedAppraisedValue:
          originalAsRepairedAppraisedValue?.replace("$", "") || null,
        purchasePrice: purchasePrice?.replace("$", "") || null,
        borrowerPaidCosts: borrowerPaidCosts?.replace("$", "") || null,
        grossCondoSelloutValue:
          grossCondoSelloutValue?.replace("$", "") || null,
        costBasis: costBasisValue
          ? costBasisValue?.replace("$", "")
          : costBasis?.toString()?.replace("$", "") || null,
        closingCost: parseFloat(closingCost),
        additionalEligibleCost:
          additionalEligibleCost?.replace("$", "") || null,
        grossPotentialRent: grossPotentialRent?.replace("$", "") || null,
        appraisalReportedNoiNcf:
          appraisalReportedNoiNcf?.replace("$", "") || null,
        annualPropertyTaxes: annualPropertyTaxes?.replace("$", "") || null,
        insurance: insurance?.replace("$", "") || null,
        annualHazardInsurance: annualHazardInsurance?.replace("$", "") || null,
        annualFloodInsurance: annualFloodInsurance?.replace("$", "") || null,
        annualHoaFee: annualHOAFee?.replace("$", "") || null,
        thirdPartyValuation: thirdPartyValuation.replace("$", "") || null,
        thirdPartyValuationStatus,
        marketRentPerMonth:
          marketRentPerMonth?.replace("$", "").replace(/,/g, "") || null,
        decliningMarkets
      },
      propertyUnit: propertyUnits
    };
    if (skipAudit) {
      propertyRequestObject = {
        ...propertyRequestObject,
        auditContext: {
          skipAudit: true
        }
      };
    }
    properties.push(propertyRequestObject);
  });

  return properties;
}
export function postFesPropertyData(
  loanId: any,
  propertyDetails: any,
  loanSizerType: any,
  autoSave?: boolean,
  skipAudit?: boolean
): Promise<any> {
  const properties = generateFesPostPropertyData(
    loanId,
    propertyDetails,
    loanSizerType,
    "",
    skipAudit
  );
  const url = `${
    getConfig().apiUrl
  }/aggregate/loan_property/${loanId}/properties?stage=FES`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, properties, {
        errorConfig: getErrorConfigObject({
          errorPriority: autoSave ? ErrorPriority.low : ErrorPriority.medium,
          customErrorMessage: {
            heading: "Failed to save property.",
            body: autoSave
              ? errorMessageJson.postPropertyAutoSave.message
              : errorMessageJson.postProperty.message
          }
        })
      } as any)
      .then((res: any) => resolve(res))
      .catch((e: any) => reject(e));
  });
}

export function formatFesPropertyDetailsForRedux(propertyData: any[]) {
  const propertyReturnObj: any[] = [];
  propertyData.forEach((item: any) => {
    const unitInfo = item.propertyUnit?.map((ele: any, index: number) => {
      const {
        leaseEndDate,
        leaseStartDate,
        monthlyLease,
        leaseDurationMorethan12Mos,
        leaseDurationLessthan12Mos,
        vacancyStatus,
        leaseStatus,
        unitCategory,
        percentageOccupied,
        propertyUnitId,
        marketRentMonthly,
        currentLeaseTermMonthly,
        inPlaceLeaseRentMonthly,
        mostRecentLeaseRentMonthly
      } = ele;
      return {
        errors: null,
        unitId: propertyUnitId || index + 1,
        payload: {
          leaseEndDate: leaseEndDate || null,
          leaseStartDate: leaseStartDate || null,
          monthlyLease,
          leaseDurationGreaterThan12M: leaseDurationMorethan12Mos,
          leaseDurationLessThan12M: leaseDurationLessthan12Mos,
          vacancyStatus,
          leaseStatus,
          unitCategory,
          percentageOccupied: formatValueByType(
            percentageOccupied,
            "percentageWithout100x"
          ),
          marketRentMonthly:
            formatValueByType(marketRentMonthly, "currency") || "",
          currentLeaseTermMonthly,
          inPlaceLeaseRent: formatValueByType(
            inPlaceLeaseRentMonthly,
            "currency"
          ),
          recentLeaseRent: formatValueByType(
            mostRecentLeaseRentMonthly,
            "currency"
          )
        }
      };
    });
    const propertyObj = {
      propertyId: item.loanPropertyId,
      propertyLocation: getLocationObject(item.propertyLocation),
      fesPropertyInformation: {
        errors: null,
        payload: {
          propertyType: item.propertyinfo.propertyType,
          numberOfUnits:
            formatNumberToCommaSeparated(item.propertyinfo.propertyUnits) || "",
          changeInUseCase: booleanToYorN(
            item.propertyinfo.changeInUseCase,
            true
          ),
          valuationSource: item.propertyinfo.valuationSource,
          squareFootage:
            formatNumberToCommaSeparated(item.propertyinfo.squareFootage) || "",
          isPropertyTypeCondo: "",
          isPropertyTypeTwoFourUnit: "",
          propertyCondition: item.propertyinfo.propertyCondition,
          propertyOriginallyAcquired: item.propertyinfo.propertyAcquisitionDate,
          isPropertyOnDebt: item.propertyinfo.isPurchasedWithDebt,
          propertyValuationDate: item.propertyinfo.recentPropertyValuationDate,
          isPropertyPurchasedLast2Years:
            item?.propertyinfo?.isPropertyPurchasedLast2Years,
          isEntitledLand: item?.propertyinfo?.isEntitledLand,
          percentageOccupied: item?.propertyinfo?.occupiedPercentage
        }
      },

      fesPropertyEconomics: {
        errors: null,
        payload: {
          acquisitionPrice: null,
          assignmentFees: item.propertyEconomics.assignmentFees
            ? formatValueByType(
                item.propertyEconomics.assignmentFees,
                "currency"
              )
            : null,
          appraisalReportedNoiNcf: !isEmptyValue(
            item.propertyEconomics.appraisalReportedNoiNcf
          )
            ? formatValueByType(
                item.propertyEconomics.appraisalReportedNoiNcf,
                "currency"
              )
            : "",
          originalAsIsAppraisalValue: !isEmptyValue(
            item.propertyEconomics.originalAsIsAppraisalValue
          )
            ? formatValueByType(
                item.propertyEconomics.originalAsIsAppraisalValue,
                "currency"
              )
            : "",
          originalAsRepairedAppraisedValue: !isEmptyValue(
            item.propertyEconomics.originalAsRepairedAppraisedValue
          )
            ? formatValueByType(
                item.propertyEconomics.originalAsRepairedAppraisedValue,
                "currency"
              )
            : "",
          purchasePrice: item.propertyEconomics.purchasePrice
            ? formatValueByType(
                item.propertyEconomics.purchasePrice,
                "currency"
              )
            : "",
          borrowerPaidCosts: item.propertyEconomics.borrowerPaidCosts
            ? formatValueByType(
                item.propertyEconomics.borrowerPaidCosts,
                "currency"
              )
            : "",
          grossCondoSelloutValue: item.propertyEconomics.grossCondoSelloutValue
            ? formatValueByType(
                item.propertyEconomics.grossCondoSelloutValue,
                "currency"
              )
            : "",
          costBasis: !isEmptyValue(item.propertyEconomics.costBasis)
            ? formatValueByType(item.propertyEconomics.costBasis, "currency")
            : "",
          closingCost: item?.propertyEconomics?.closingCost ?? "",
          additionalEligibleCost: item.propertyEconomics.additionalEligibleCost
            ? formatValueByType(
                item.propertyEconomics.additionalEligibleCost,
                "currency"
              )
            : "",
          grossPotentialRent: item.propertyEconomics.grossPotentialRent
            ? formatValueByType(
                item.propertyEconomics.grossPotentialRent,
                "currency"
              )
            : "",
          annualPropertyTaxes: item?.propertyEconomics?.annualPropertyTaxes
            ? formatValueByType(
                item?.propertyEconomics?.annualPropertyTaxes,
                "currency"
              )
            : "",
          insurance: item?.propertyEconomics?.insurance
            ? formatValueByType(item?.propertyEconomics?.insurance, "currency")
            : "",
          annualHazardInsurance: item?.propertyEconomics?.annualHazardInsurance
            ? formatValueByType(
                item?.propertyEconomics?.annualHazardInsurance,
                "currency"
              )
            : "",
          annualHOAFee: item?.propertyEconomics?.annualHoaFee
            ? formatValueByType(
                item?.propertyEconomics?.annualHoaFee,
                "currency"
              )
            : "",
          annualFloodInsurance: item.propertyEconomics.annualFloodInsurance
            ? formatValueByType(
                item.propertyEconomics.annualFloodInsurance,
                "currency"
              )
            : "",
          thirdPartyValuation: !isEmptyValue(
            item.propertyEconomics?.thirdPartyValuation
          )
            ? formatValueByType(
                item.propertyEconomics.thirdPartyValuation,
                "currency"
              ) || ""
            : !isEmptyValue(
                item.propertyEconomics?.thirdPartyValuationPropertyValue
              )
            ? formatValueByType(
                `${item.propertyEconomics?.thirdPartyValuationPropertyValue}`,
                "currency"
              )
            : "",
          thirdPartyValuationStatus:
            item.propertyEconomics.thirdPartyValuationStatus,
          marketRentPerMonth: item.propertyEconomics.marketRentPerMonth
            ? formatValueByType(
                item.propertyEconomics.marketRentPerMonth,
                "currency"
              )
            : "",
          decliningMarkets: item?.propertyEconomics?.decliningMarkets || "No"
        }
      },
      unitsInformation: unitInfo,
      propertyInformation: {
        errors: null,
        payload: {
          propertyType: "",
          numberOfUnits: "",
          propertyValuationDate: null,
          propertyAcquisitionDate: null,
          squareFootage: "",
          changeInUseCase: "",
          preRehabSquareFootage: "",
          postRehabSquareFootage: "",
          appraisalReported: "",
          percentageOccupied: ""
        }
      },
      propertyEconomics: {
        errors: null,
        payload: {
          originalAppraisalValue: "",
          originalRepairedAppraisalValue: "",
          purchasePrice: "",
          borrowerPaidCosts: "",
          grossCondoSelloutValue: "",
          costBasis: "",
          grossPotentialRent: "",
          annualPropertyTaxes: "",
          insurance: "",
          financedBudgetAmount: "",
          rentalCashflowRatio: "",
          annualHazardInsurance: "",
          annualFloodInsurance: "",
          annualHoaFee: ""
        }
      }
    };
    propertyReturnObj.push(propertyObj);
  });
  return propertyReturnObj;
}
export function getFesRulePropertyRequest(
  propertyDetails: any,
  loanSizerType: any
) {
  const properties: any = [];
  propertyDetails.forEach((element: any, index: number) => {
    const {
      propertyId,
      propertyLocation,
      fesPropertyInformation,
      fesPropertyEconomics,
      unitsInformation
    } = element;
    const {
      propertyType,
      numberOfUnits,
      changeInUseCase,
      valuationSource,
      squareFootage,
      propertyOriginallyAcquired,
      propertyOriginallyPurchased,
      propertyCondition,
      isPropertyOnDebt,
      propertyValuationDate,
      isPropertyPurchasedLast2Years,
      isEntitledLand
    } = fesPropertyInformation?.payload ?? {};
    const {
      originalAsIsAppraisalValue,
      purchasePrice,
      borrowerPaidCosts,
      grossCondoSelloutValue,
      originalAsRepairedAppraisedValue,
      costBasis,
      closingCost,
      additionalEligibleCost,
      grossPotentialRent,
      appraisalReportedNoiNcf,
      annualPropertyTaxes,
      insurance,
      annualHazardInsurance,
      annualFloodInsurance,
      annualHOAFee,
      thirdPartyValuationStatus,
      thirdPartyValuation,
      acquisitionPrice,
      marketRentPerMonth,
      assignmentFees,
      decliningMarkets
    } = fesPropertyEconomics?.payload ?? {};
    const { zipCode, state, city } = propertyLocation.payload;
    const units = unitsInformation?.map((ele: any) => {
      const propertyUnitId = ele.unitId;
      const {
        leaseStartDate,
        leaseEndDate,
        monthlyLease,
        leaseDurationGreaterThan12M,
        leaseDurationLessThan12M,
        vacancyStatus,
        unitCategory,
        inPlaceLeaseRent,
        marketRentMonthly,
        currentLeaseTermMonthly,
        recentLeaseRent,
        leaseStatus,
        percentageOccupied
      } = ele.payload;
      return {
        propertyUnitId,
        leaseStatus,
        marketRentMonthly: Number.parseFloat(
          marketRentMonthly?.replace("$", "").replace(/,/g, "")
        ),
        currentLeaseTermMonths: isEmptyValue(currentLeaseTermMonthly)
          ? null
          : Number(currentLeaseTermMonthly),
        inPlaceLeaseRentMonthly: Number.parseFloat(
          inPlaceLeaseRent?.replace("$", "").replace(/,/g, "")
        ),
        mostRecentLeaseRentMonthly: Number.parseFloat(
          recentLeaseRent?.replace("$", "").replace(/,/g, "")
        ),
        unitOccupancy: [
          LeaseStatusEnum.unOcupiedLongTerm,
          LeaseStatusEnum.unOccupiedShortTerm,
          rentDescriptionOld[3]
        ].includes(leaseStatus)
          ? 0
          : 1
      };
    });
    const costBasisValue =
      costBasisFormula(element, loanSizerType) || costBasis;
    let marketRentPerMonthFormat = marketRentPerMonth
      ?.replace("$", "")
      .replace(/,/g, "");
    try {
      marketRentPerMonthFormat =
        marketRentPerMonthFormat.trim() === ""
          ? null
          : marketRentPerMonthFormat;
    } catch (e) {
      marketRentPerMonthFormat = null;
    }
    properties.push({
      propertyInfo: {
        numberOfUnits: getNumFromCommaSeparatedNum(numberOfUnits),
        propertyType,
        changeInUseCase: yesOrNoToYorN(changeInUseCase),
        postRehabSquareFootage: 0, // to be sent as 0
        preRehabSquareFootage: getNumFromCommaSeparatedNum(squareFootage), // send square footage here as rule service doesnt have the field for square footage
        propertyCondition,
        squareFootage: getNumFromCommaSeparatedNum(squareFootage),
        propertyOriginallyPurchased,
        propertyAcquisitionDate: splitISODateTillT(propertyOriginallyAcquired),
        mostRecentPropertyValuationDate: splitISODateTillT(
          propertyValuationDate
        ),
        valuationSource,
        isPurchasedWithDebt: yesOrNoToYorN(isPropertyOnDebt),
        appraisalReportedNotOrNcf: Number.parseFloat(
          appraisalReportedNoiNcf?.replace("$", "").replace(/,/g, "")
        ),
        isPropertyPurchasedLast2Years,
        isEntitledLand
      },
      propertyLocation: {
        city,
        postalCode: zipCode,
        state
      },
      propertyEcon: {
        assignmentFees: Number.parseFloat(
          assignmentFees?.replace?.("$", "").replace(/,/g, "")
        ),
        insurance: Number.parseFloat(
          insurance?.replace("$", "").replace(/,/g, "")
        ),
        grossPotentialRent: Number.parseFloat(
          grossPotentialRent?.replace("$", "").replace(/,/g, "")
        ),
        costBasisAfterCostIncurred: Number.parseFloat(
          costBasisValue?.replace("$", "").replace(/,/g, "")
        ),
        closingCost: percentageToFloat(closingCost),
        additionalEligibleCost: Number.parseFloat(
          additionalEligibleCost?.replace("$", "").replace(/,/g, "")
        ),
        originalAsIsAppraisalValue: Number.parseFloat(
          originalAsIsAppraisalValue?.replace("$", "").replace(/,/g, "")
        ),
        originalAsRepairedAppraisedValue: Number.parseFloat(
          originalAsRepairedAppraisedValue?.replace("$", "").replace(/,/g, "")
        ),
        purchasePrice: Number.parseFloat(
          purchasePrice?.replace("$", "").replace(/,/g, "")
        ),
        borrowerPaidCosts: Number.parseFloat(
          borrowerPaidCosts?.replace("$", "").replace(/,/g, "")
        ),
        grossCondoSelloutValue: Number.parseFloat(
          grossCondoSelloutValue?.replace("$", "").replace(/,/g, "")
        ),
        thirdPartyValuationStatus,
        thirdPartyValuation: Number.parseFloat(
          thirdPartyValuation?.replace("$", "").replace(/,/g, "")
        ),
        annualPropertyTaxes: Number.parseFloat(
          annualPropertyTaxes?.replace("$", "").replace(/,/g, "")
        ),
        annualHazardInsurance: Number.parseFloat(
          annualHazardInsurance?.replace("$", "").replace(/,/g, "")
        ),
        annualFloodInsurance: Number.parseFloat(
          annualFloodInsurance?.replace("$", "").replace(/,/g, "")
        ),
        annualHOAFee: Number.parseFloat(
          annualHOAFee?.replace("$", "").replace(/,/g, "")
        ),
        acquisitionPrice: Number.parseFloat(
          purchasePrice?.replace("$", "").replace(/,/g, "")
        ),
        marketRentPerMonth: marketRentPerMonthFormat,
        decliningMarkets
      },
      units,
      propertyId
    });
  });
  return properties;
}
export function getFesRulesRequestObject(
  loanData: LoanDetails,
  loanId: any,
  loanType: string,
  loanStage: LoanStage,
  isSubmitting?: boolean,
  editReEval?: boolean,
  ruleResult?: any,
  changedValueFields?: any
) {
  const {
    fesBorrowerInformation,
    fesLoanInformation,
    fesLoanEconomics,
    propertyDetails,
    loanSizerType,
    loanRuleVersions
  } = loanData;
  const {
    loanStructure,
    loanPurpose,
    exitStrategy,
    primaryLoanId,
    cashoutFlag,
    recourse,
    originationDate,
    expectedClosingDate,
    borrowerProceeds,
    numberOfProperties,
    predominantState,
    pledgeOfEquity,
    predominantPropertyType,
    condoEligibility,
    loanTerm,
    isPermitApprovalsAndFullPlansInPlace,
    irFundingSource,
    sizerRateLockPeriod,
    fundingType,
    toorakProduct,
    takeoutPartnerId,
    crossCollaterlization,
    creditEvent
  } = fesLoanInformation;
  const {
    rehabBudget,
    existDebt,
    hardCost,
    softCost,
    originalLoanAmount,
    rateType,
    rehabAmount,
    interestRate,
    totalOriginationAndDiscountPoints,
    prepaymentPenalty,
    prepayPenaltyType,
    interestOnlyPeriod,
    interestReserve,
    armIndex,
    grossArmMargin,
    includeOutOfPocketBudgetInARLTV
  } = fesLoanEconomics;
  const {
    borrowerExperience,
    foreignNationalString,
    borrowerType,
    borrowerCreditScore,
    borrowerGUCExperience,
    heavyRehabExperience,
    inState
  } = fesBorrowerInformation;
  const partyType = borrowerType === "Entity" ? "account" : "person";
  const totalMaxLoanAmount = addCurrencyValues([
    originalLoanAmount,
    rehabAmount
  ]);
  const loanTypeValue =
    loanType === getLoanType[0].displayValue ? "BRIDGE" : "30Year";
  const properties = getFesRulePropertyRequest(propertyDetails, loanSizerType);
  const rehabBudgetValue =
    loanSizerType === LoanSizerEnum.multifamilyMixedUse
      ? totalRehabBudgeFormula({ loanDetails: loanData })
      : rehabBudget;
  const rehabBlr = LoanSizerEnum.groundUp
    ? totalRehabBudgeFormula({ loanDetails: loanData })
    : rehabAmount;
  let isWarehouseFunded = false;
  const selectedFundingChannelValue =
    getFundingChannelValueInSnakeCase(fundingType);
  if (fundingType === "Warehouse Funding") isWarehouseFunded = true;
  const ruleRequestObject: any = {
    loanFact: {
      loan: {
        loanDetail: {
          originatorPartyId:
            sessionStorage.getItem("originatorPartyIdNew") || "",
          fundingType: selectedFundingChannelValue,
          isWarehouseFunded
        },
        loanInfo: {
          loanStructure,
          recourse,
          originationDate:
            originationDate && splitISODateTillT(originationDate),
          product: "RENTAL", // should be sent as hard coded for now
          toorakProduct:
            loanSizerType === LoanSizerEnum.groundUp ? loanSizerType : "Rehab",
          loanType: loanTypeValue,
          loanStage,
          pledgeOfEquity: yesOrNoToYorN(pledgeOfEquity),
          cashOutFlag: cashoutFlag,
          loanPurpose,
          exitStrategy,
          primaryLoanId,
          loanId,
          borrowerProceeds: yesOrNoToYorN(borrowerProceeds),
          predominantState,
          predominantPropertyType,
          originalMaturityDate:
            expectedClosingDate && splitISODateTillT(expectedClosingDate), // Expected to send expectedClosingDate in original maturity date till rules changes are complete.
          expectedClosingDate:
            expectedClosingDate && splitISODateTillT(expectedClosingDate),
          borrowerReceivingProceeds: yesornToBoolean(borrowerProceeds),
          condoEligibility,
          loanTerm,
          isPermitApprovalsAndFullPlansInPlace,
          irFundingSource,
          sizerRateLockPeriod,
          // takeoutPartnerId,
          crossCollaterlization: crossCollaterlization || "N",
          creditEvent: creditEvent || "No"
        },
        loanUserMap: [
          {
            isPrimary: true,
            loanUserType: "Borrower", // will be always of this type in fes
            originalCreditScoreMedian: isEmptyValue(borrowerCreditScore)
              ? null
              : getNumFromCommaSeparatedNum(borrowerCreditScore).toString(),
            experience: getNumFromCommaSeparatedNum(borrowerExperience),
            borrowerGUCExperience: getNumFromCommaSeparatedNum(
              borrowerGUCExperience
            ),
            heavyRehabExperience:
              getNumFromCommaSeparatedNum(heavyRehabExperience),
            inState,
            customer: {
              partyType,
              foreignNationalString
            }
          }
        ],
        loanEcon: {
          rateType,
          originalLoanAmount: Number.parseFloat(
            originalLoanAmount?.replace("$", "").replace(/,/g, "")
          ),
          totalOriginationAndDiscountPoints: percentageToFloat(
            totalOriginationAndDiscountPoints
          ),
          totalMaxLoanAmount,
          existDebt: Number.parseFloat(
            existDebt?.replace("$", "").replace(/,/g, "")
          ),
          hardCost: Number.parseFloat(
            hardCost?.replace("$", "").replace(/,/g, "")
          ),
          softCost: Number.parseFloat(
            softCost?.replace("$", "").replace(/,/g, "")
          ),
          rehabAmountBrl: Number.parseFloat(
            rehabBlr?.replace("$", "").replace(/,/g, "")
          ),
          totalBudgetAmount: Number.parseFloat(
            rehabBudgetValue?.replace("$", "").replace(/,/g, "")
          ),
          prePaymentPenaltyMonths: prepaymentPenalty,
          prepayPenaltyType,
          interestRate: percentageToFloat(interestRate, 6),
          interestOnlyPeriod: getNumFromCommaSeparatedNum(
            interestOnlyPeriod || "0"
          ),
          interestReserve: Number.parseFloat(
            interestReserve?.replace("$", "")?.replace(/,/g, "") ?? ""
          ),
          includeOutOfPocketBudgetInARLTV,
          armIndex: Number.parseFloat(
            armIndex?.toString()?.replace("$", "")?.replace(/,/g, "") ?? "0"
          ),
          grossMarginForArm:
            grossArmMargin?.toString()?.replace("$", "")?.replace(/,/g, "") ??
            "0"
        },
        loanRuleVersions
      },
      properties
    },
    ruleAttributs: {
      ruleField: editReEval ? changedValueFields : [],
      ruleGroup: isSubmitting ? [] : ["loancharacterization", "loanfeatures"],
      ruleResult: []
    },
    resultObject: editReEval ? ruleResult : {}
  };
  return ruleRequestObject;
}

export function addCurrencyValues(values: any[]) {
  let sum: null | number = null;
  values.forEach((item) => {
    const num = Number.parseFloat(item?.replace("$", "").replace(/,/g, ""));
    if (!isEmptyValue(num)) {
      if (sum) {
        sum += num;
      } else {
        sum = num;
      }
    }
  });
  return sum;
}

// export const switchLoanSizerType = (value: any) => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SWITCH_LOAN_SIZER_TYPE,
//       payload: value
//     });
//   };
// };
