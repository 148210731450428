/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CreateLoanParamsBridgeLoan,
  CreateLoanParamsThirtyYearLoan,
  BridgeLoanGuarantorInformation,
  ThirtyYearGuarantorInformation,
  LoanStage,
  WarningModal,
  WarningModalProps,
  FundingTypes,
  ConfirmFormModalProps,
  ConfirmFormModal
} from "@toorak/tc-common-fe-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Button,
  AppBar,
  Grid,
  Theme,
  Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { push } from "connected-react-router";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { LoanStatusEnum, DATE_TIME_UTILS } from "@toorak/tc-common-util-sdk";
import { RootState } from "../stores/rootReducer";

import {
  validateForm,
  postLoan,
  postPropertyData,
  errorsOnSubmit,
  resetAllStores,
  deleteLoan,
  setActiveTabAndProperty,
  getLoanById,
  convertLoan,
  copyLoan,
  deleteMultipleDocs,
  getDocuments,
  fetchAndDispatchLoan,
  getDocsByLoanId,
  keepCreditEvalSync,
  formatLoanDetailsForRedux,
  getPrimaryGuarantorInfo,
  getBorrowerObjectForLoanSave
} from "./create-loan.action";
import submitButtonImage from "../images/submit_icon.svg";
import { evaluateLoan } from "../stores/EvaluationResults/EvaluationResults.action";
import {
  SingleLoanData,
  resetExcelUploadReducer,
  setExcelUploadData,
  toggleDeleteLoan,
  updateSubmitStatus
} from "../stores/ExcelUpload/excel-upload.reducer";
import {
  RetryRequestStore,
  resetRetryRequestReducer
} from "../stores/RetryRequest/retry-request.reducer";
import {
  getExcelDataInSession,
  isILP,
  isLATUser,
  setBackToOriginalOrgId
} from "../utils/AccessManagement";
import {
  submitMultipleLoans
} from "../stores/ExcelUpload/excel-upload.action";
import { getEvaluationRequestBody } from "./evaluation-results/helpers/ruleRequestBody";
import { LoaderAndExceptionsStore } from "../stores/loaderAndException/loaderAndException.reducer";
import {
  postFesLoan,
  postFesPropertyData,
  getFesRulesRequestObject,
  formatFesLoanDetailsForRedux
} from "../stores/FrontEndSizer/fesLoanCreation.action";
import { isEmptyValue, isNullOrUndefined } from "../utils/formatChecks";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { getCookie, removeCookie, setCookie } from "../utils/cookies";
import { getLoanType } from "../config/config";
import {
  updateLoanDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { getOriginatorId } from "./createLoanHeaders/DisplayLoanId";
import { LockInSelectionPopup } from "./createLoanHeaders/LockInSelectionPopup";
import {
  convertSingleToorvalFesLoan,
  convertSingleToorvalLoan,
  getDupliPopupHTML,
  handleToorvalDuplicateLoans,
  showDuplicateDetals,
  updatePropInfoWithToorvalID
} from "./handle-duplicate-loans/DuplicateLoansFunctionality";
import { EvaluationResultStore } from "../stores/EvaluationResults/EvaluationResults.reducer";
import {
  submitAppraisalForm,
  deleteAppraisalForm,
  onlyUpdateAppraisalForm
} from "../stores/appraisal/appraisal.action";
import { useAppraisalForm } from "./appraisal/shared/useAppraisalForm";
import { ObjectType } from "../masterView/common";
import {
  getCriticalTests,
  checkDscrLoanSubmition,
  submitDscrLoan,
  checkBridgeLoanSubmition,
  submitBridgeLoan,
  patchLoanData
} from "../network/apiService";
import momentz from "moment-timezone";
import { formatLockOptions } from "../utils/CommonDataTransformers";
import { PricingAndGuidlinesSectionModal } from "./guidelines-and-pricing-comparision/PricingAndGuidlinesSectionModal/ProgrammeSelection";
import { getOriginatorLabel } from "./guidelines-and-pricing-comparision/ResultsSummaryCard";
import { MessageBody, MessageHeader } from "./createLoanHeaders/InfoPopUp";
import {
  setLoanByIdSuccess,
  setLoading,
  postLoanSuccess,
  updatePropertySuccess,
  // primaryGuarantorOrBorrowerCheck,
  loanTypeFailed,
  // setDocsByIdSuccess,
  updateEligiblePartners
} from "./create-loan.reducer";
import { cancelAutoSavee, showPopUpMessages } from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import { globalGetDataByLoanID } from "./CreateLoan";
import { emptyPropertyBasedOnLoan } from "./create-loan.reducerHelper";
import { PreSubmitStates } from "../utils/constants";
import { calculateFesPropertyError, countLoanDetailError } from "./ErrorCounter";
import { computePropertyError, computePropertyLocationError } from "./property-details/PropertyDetailsHelper";
import { checkIfFESLoan, delay } from "../utils/utils";
import { updateShowLockinPopup } from "../v2/loanDetailStore.reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loanCreationTypography: {
      alignSelf: "center",
      color: "#FFF",
      [theme.breakpoints.only("lg")]: {
        marginLeft: "20px"
      }
    },
    headerButtonWrapper: {
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "flex-end"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        display: "flex"
      }
    },
    fixedHeader: {
      position: "fixed",
      height: 60,
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        padding: "10px 12px 8px 12px"
      }
    },
    headerPrimaryButton: {
      marginRight: "10px !important",
      [theme.breakpoints.down("md")]: {
        width: "36px",
        height: "36px",
        minWidth: "auto",
        padding: "5px",
        justifyContent: "center"
      }
    },
    headerSecondaryButton: {
      [theme.breakpoints.down("sm")]: {
        width: "36px",
        height: "36px",
        minWidth: "auto",
        padding: "5px",
        justifyContent: "center"
      }
    },
    btnLabel: {
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    btnLabelEvaluate: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    }
  })
);

const DSCRLoan = getLoanType[1].displayValue;
const BridgeLoan = getLoanType[0].displayValue;

export const getBridgeLoanGuarantorInformationToPass = (
  bridgeLoanGuarantorInformation: {
    guarantorId: string;
    errors: number | null;
    payload: BridgeLoanGuarantorInformation;
  }[],
  ruleRequest?: boolean
): any[] => {
  const newGuarantorLoan: any = [];
  if (bridgeLoanGuarantorInformation) {
    let latestGuarantorSequence: any = getCookie("latestGuarantorSequence");
    latestGuarantorSequence = latestGuarantorSequence
      ? Number(latestGuarantorSequence) ?? -1
      : -1;
    const loanUserSequenceMap = new Map();
    bridgeLoanGuarantorInformation.forEach(
      (element: {
        guarantorId: string;
        errors: number | null;
        payload: Partial<BridgeLoanGuarantorInformation>;
      }) => {
        const {
          pOEntity,
          foreignNationalString,
          guarantorEmail,
          guarantorExperience,
          creditScore,
          guarantorFirstName,
          guarantorLastName,
          isPrimaryGuarantor,
          guarantorLoanUserSequence,
          eiNumber,
          loanUserMapId,
          ssNumber,
          passportExpirationDate,
          partyId
        } = element.payload;
        let originalCreditReportDate: any =
          element.payload.originalCreditReportDate;

        const ownershipOfEntity = pOEntity
          ? Number(pOEntity?.replace("%", ""))
          : null;
        let foreignNational = foreignNationalString || null;
        const contactList = guarantorEmail
          ? [
            {
              partyContactId: 1,
              contactNumber: "",
              email: guarantorEmail
            }
          ]
          : [];
        let creditDateValue = null;
        if (
          originalCreditReportDate &&
          !isNaN(new Date(originalCreditReportDate).getTime())
        ) {
          try {
            if (typeof originalCreditReportDate === "string") {
              if (
                originalCreditReportDate?.charAt(
                  originalCreditReportDate.length - 1
                ) !== "Z" &&
                !originalCreditReportDate.includes("+00:00")
              ) {
                originalCreditReportDate += "Z";
              }
              creditDateValue = new Date(
                originalCreditReportDate
              ).toISOString();
            } else creditDateValue = originalCreditReportDate.toISOString();
          } catch (error) {
            creditDateValue = new Date(originalCreditReportDate).toISOString();
          }
        }
        if (creditDateValue) creditDateValue = creditDateValue.split("T")[0];
        if (ruleRequest && !foreignNational) foreignNational = "No";
        let primaryGuarantor = isPrimaryGuarantor;
        if (ruleRequest && !isPrimaryGuarantor) primaryGuarantor = false;
        let loanUserSequence = "";
        if (!guarantorLoanUserSequence) latestGuarantorSequence++;
        loanUserSequence =
          guarantorLoanUserSequence || latestGuarantorSequence.toString();
        let currVal = Number(loanUserSequence);
        if (loanUserSequenceMap.get(loanUserSequence) != null) {
          while (loanUserSequenceMap.get(loanUserSequence) != null) {
            currVal++;
            loanUserSequence = currVal.toString();
          }
        }
        loanUserSequenceMap.set(loanUserSequence, loanUserSequence);

        const obj = {
          loanUserType: "Guarantor",
          loanUserSequence,
          isPrimary: primaryGuarantor,
          originalCreditScoreMedian: isEmptyValue(creditScore)
            ? null
            : creditScore?.toString(),
          originalCreditScoreReportDate: creditDateValue,
          experience: isNullOrUndefined(guarantorExperience)
            ? null
            : Number(guarantorExperience),
          ownershipOfEntity: ownershipOfEntity,
          ...(partyId && {
            partyId
          }),
          ...(loanUserMapId && {
            loanUserMapId
          }),
          customer: {
            addressList: [
              {
                partyAddressId: 1,
                addressNumber: "",
                addressType: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                country: "",
                pincode: ""
              }
            ],
            contactList: contactList,
            partyType: "person",
            accountType: "person",
            firstName: guarantorFirstName || "",
            lastName: guarantorLastName || "",
            foreignNationalString: foreignNational,
            ...(eiNumber && {
              eiNumber
            }),
            ...(ssNumber && {
              ssNumber
            }),
            ...(passportExpirationDate && {
              passportExpirationDate
            })
          }
        };
        newGuarantorLoan.push(obj);
      }
    );
  }
  return newGuarantorLoan;
};

export const getThirtyYearLoanGuarantorInformationToPass = (
  thirtyYearGuarantorInformation: {
    guarantorId: string;
    errors: number | null;
    payload: ThirtyYearGuarantorInformation;
  }[],
  ruleRequest?: boolean
): any[] => {
  const newGuarantorLoan: any = [];
  thirtyYearGuarantorInformation.forEach(
    (
      element: {
        guarantorId: string;
        errors: number | null;
        payload: Partial<ThirtyYearGuarantorInformation>;
      },
      index: number
    ) => {
      const {
        pOEntity,
        foreignNationalString,
        guarantorEmail,
        originalCreditReportDate,
        creditScore,
        guarantorFirstName,
        guarantorLastName,
        isPrimaryGuarantor
      } = element.payload;
      const foreignNational = foreignNationalString === "Yes";
      const contactList = guarantorEmail
        ? [
          {
            contactNumber: "",
            email: guarantorEmail
          }
        ]
        : [];
      let creditDateValue = null;
      if (
        originalCreditReportDate &&
        !isNaN(new Date(originalCreditReportDate).getTime())
      ) {
        try {
          if (typeof originalCreditReportDate === "string") {
            creditDateValue = new Date(originalCreditReportDate).toISOString();
          } else creditDateValue = originalCreditReportDate.toISOString();
        } catch (error) {
          creditDateValue = new Date(originalCreditReportDate).toISOString();
        }
      }
      if (ruleRequest && creditDateValue)
        creditDateValue = creditDateValue.slice(0, 10);
      const obj = {
        loanUserType: "Guarantor",
        loanUserSequence: index + 2,
        isPrimary: isPrimaryGuarantor,
        originalCreditScoreMedian: creditScore,
        originalCreditScoreReportDate: creditDateValue,
        experience: null,
        ownershipOfEntity: pOEntity,
        customer: {
          addressList: [
            {
              addressNumber: "",
              addressType: "",
              addressLine1: "",
              addressLine2: "",
              addressLine3: "",
              city: "",
              state: "",
              country: "",
              pincode: ""
            }
          ],
          contactList: contactList,
          partyType: "person",
          accountType: "person",
          firstName: guarantorFirstName || "",
          lastName: guarantorLastName || "",
          foreignNationalString: foreignNational
        }
      };
      newGuarantorLoan.push(obj);
    }
  );
  return newGuarantorLoan;
};

// export function getDuplicateCheckBody(loanId: string, loanStage: LoanStage, primaryId?:string):Promise<any>{
//   const promise1 = getLoanById(loanId, loanStage);
//   const promise2 = getPropertyOriginalValuesByLoanId(loanId, loanStage);
//   return Promise.all([promise1, promise2])
//   .then(async (response: any) => {
//     const responseData = response[0].data;
//     if(primaryId){
//       responseData.loanInfo["primaryLoanId"] = primaryId
//     }
//     const loanInfo = {
//       loanInfo: responseData.loanInfo,
//       loanStage: responseData.loanStage,
//       loanState: responseData.loanState,
//       loanType: responseData.loanType,
//       loanId: responseData.loanDetailId.loanId
//     }
//     const requestBody = [{
//       loanObject: loanInfo,
//       propertiesArray: response[1].data
//     }];
//     return Promise.resolve(requestBody);
//   }).catch(e=>
//     Promise.reject(e));
// }
const { DataEntry, Submit, ExcelError, ExcelSuccess } = LoanStatusEnum;
export function CreateLoanHeader(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLockinPopup } = useSelector<RootState, any>((state) => state.loanDetailsStore);
  const {
    creditLines,
    lockinValues,
    setLockInValues,
    rateSheetTypeData,
    takeoutPartnerStatus,
    takeoutPartnerId,
    hideLoanHeader
  } = props;
  const { loanId, loanType, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const classes = useStyles();
  const excelData = getExcelDataInSession();
  const { loanID: appraisalOriginatorLoanId } = useAppraisalForm();
  const appraisalForm = useAppraisalForm();
  const { loading, loanDetails, editHistory, autoSaveCount } = useSelector<
    RootState,
    any
  >(state => state.createLoanStore);
  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    propertyDetails,
    loanState,
    loanSizerType,
    thirtyYearBorrowerInformation,
    thirtyYearGuarantorInformation,
    thirtyYearLoanInformation,
    thirtyYearLoanEconomics,
    loanRuleVersions,
    loanSummary,
    loanDetailId
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = loanDetails;
  const { loaderCount } = useSelector<RootState, LoaderAndExceptionsStore>(
    (state) => state.LoaderAndExceptionsStore
  );

  const activeTab = useSelector<RootState, string>(
    (state) => state.createLoanStore.activeTab
  );

  const { loanEvaluationResult, overriddenValues, aggregateRuleVersions } =
    useSelector<RootState, EvaluationResultStore>(
      (state) => state.evaluationResultsStore
    );

  const uploadExcelStoreData = useSelector<
    RootState,
    { [key: string]: SingleLoanData }
  >((state) => state.excelUploadStore.data);
  const retryRequestStore = useSelector<
    RootState,
    { [key: string]: RetryRequestStore }
  >((state) => state.retryRequestStore);
  const submitForm = useSelector<
    RootState,
    {
      loanErrors: number;
      propertyErrors: number;
      // docsErrors: number;
      clicked: boolean;
    }
  >((state) => state.createLoanStore.submitForm);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [loansWithoutAssignedPartner, setLoansWithoutAssignedPartner] =
    useState<any[]>([]);
  const [loansResult, setLoansResult] = useState<ObjectType>({});
  const [criticalTests, setCriticalTests] = useState<ObjectType>({});
  const [eligibleTakeOutPartnerResult, setEligibleTakeOutPartnerResult] =
    useState<any>({});
  const [currentSelectedPartner, setCurrentSelectedPartner] = useState<any>({
    assignedPartner: "",
    partnerId: ""
  });
  const [partnerWiseRateLockOptions, setPartnerWiseRateLockOptions] = useState<any>(lockinValues);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  const [openConfirmation, setOpenConfirmation] = useState<ObjectType | null>(
    null
  );
  const [takeOutPartnersList, setTakeOutPartnersList] = useState<ObjectType[]>(
    []
  );
  const [rateLockOptions, setRateLockOptions] = useState<any>(null);

  const [isDocsImported, setIsDocsImported] = useState<boolean>(false);
  const [docsApiCalled, setDocsApiCalled] = useState<boolean>(false);
  const [multiDocsDelBody, setMultiDocsDelBody] = useState<any>({});
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(!!excelData);
  const [openLockInSelection, setOpenLockInSelection] = useState<boolean>(false);
  const [noLocks, setNoLocks] = useState<boolean>(false);

  const orgPartyId = sessionStorage.getItem("originatorPartyIdNew") || "";

  const { allowRateLockSelection, isSizerRateLockActive } = useMemo(() => {
    const currentDate = momentz().tz(DATE_TIME_UTILS?.nyTimeZone)?.valueOf();
    const isRateLockActive: boolean =
      loanDetails?.rateLockExpiryDate &&
      new Date(loanDetails.rateLockExpiryDate).getTime() > currentDate;
    const isSizerRateLockActive: boolean =
      loanDetails?.sizerRateLockExpiryDate &&
      new Date(loanDetails.sizerRateLockExpiryDate).getTime() > currentDate;
    return {
      allowRateLockSelection:
        loanType === DSCRLoan && // DSCR loan type
        !loanDetails?.loanConfig?.rateLockPeriod && // check if first time lock
        !(isRateLockActive), // rate lock should not be active
      isSizerRateLockActive
    };
  }, [loanDetails, loanStage, loanType, lockinValues]);

  const locks = useMemo(() => {
    if (eligibleTakeOutPartnerResult?.eligiblePartners?.length >= 1 && rateLockOptions[loanId]?.lockValues.length > 0) {
      return rateLockOptions;
    }
    return lockinValues;
  }, [rateLockOptions, lockinValues, eligibleTakeOutPartnerResult]);

  const handleOpenRateLockPostSubmit = () => {
    setOpenWarning(null);
    setOpenConfirmation(null);
    dispatch(showLoader(true));
    checkDscrLoanLockAndSubmit(false);
  };

  useEffect(() => {
    if (showLockinPopup) {
      handleOpenRateLockPostSubmit();
      dispatch(updateShowLockinPopup(false));
    }
  }, [showLockinPopup]);

  useEffect(() => {
    if (isLATUser()) return
    if (!loanEvaluationResult.loanResult?.[loanId] || !takeoutPartnerId) return;
    if (
      loanType !== DSCRLoan ||
      loanDetails.loanConfig?.rateLockPeriod ||
      [DataEntry, ExcelError, ExcelSuccess].includes(loanState) ||
      takeoutPartnerStatus === "PENDING"
    )
      return;
    if (loanDetailId.fundingType === FundingTypes.TableFunding && loanDetailId?.rateLocks?.[0]?.rateLockStatus === "REJECTED") return;
    if (
      lockinValues &&
      lockinValues[loanId]?.lockValues &&
      Object.keys(lockinValues[loanId]?.lockValues)?.length > 0
    ) {
      setOpenConfirmation({
        isOpen: loanEvaluationResult?.loanResult?.[loanId]?.finalCalculation?.criticalTestLoanEligibility === "PASS",
        header: "Warning",
        primaryBtnName: "Proceed",
        body: (
          <>
            <Grid container direction="column" style={{ padding: "1rem 5rem 1rem 1rem" }}>
              <Grid item xs={12}>
                <div>
                  This loan requires a rate lock. Please lock this loan.
                </div>
                <br />
              </Grid>
            </Grid>
          </>
        ),
        handleClose: () => {
          setOpenConfirmation(null);
        },
        successHandler: () => {
          handleOpenRateLockPostSubmit();
        },
        confirmCondition: false,
        footerStyles: { marginTop: "0", padding: "0px 20px 20px" },
        hideSecondaryBtn: true
      });
    }
  }, [
    loanDetails.loanConfig,
    takeoutPartnerId,
    loanEvaluationResult,
    takeoutPartnerStatus
  ]);

  const createLoanData = (overRideRateLock?: number, excelData?: any) => {
    let data: any = {};
    if (!excelData) {
      if (loanType === BridgeLoan) {
        data = {
          borrowerInformation: bridgeLoanBorrowerInformation,
          guarantorInformation: bridgeLoanGuarantorInformation,
          loanInformation: bridgeLoanInformation.payload,
          loanEconomics: bridgeLoanEconomics.payload,
          loanRuleVersions,
          loanSummary
        };
      } else {
        let loanInfo = thirtyYearLoanInformation.payload;
        if (!isEmptyValue(overRideRateLock)) {
          loanInfo = { ...loanInfo, rateLockPeriod: overRideRateLock };
        }
        data = {
          borrowerInformation: bridgeLoanBorrowerInformation,
          guarantorInformation: bridgeLoanGuarantorInformation,
          loanInformation: loanInfo,
          loanEconomics: thirtyYearLoanEconomics.payload,
          loanRuleVersions,
          loanSummary
        };
      }
    } else {
      data = excelData;
    }
    return data;
  };

  const getRateLockOptions = (
    takeoutPartnerId: string,
    loanId: string,
    loanStage: string,
    results?: any,
    rateLocks?: any
  ) => {
    const pricingtype =
      loanStage === LoanStage.fes
        ? "RATE_LOCK_ADJUSTMENT_FES"
        : "RATE_LOCK_ADJUSTMENT";
    let lockValues = aggregateRuleVersions?.[takeoutPartnerId]?.["RATE_ADJUSTMENTS"]
      ?.lookupData;
    lockValues = lockValues?.filter(
      (it: ObjectType) => it?.pricingType === pricingtype
    );
    let partnerMinPrice = aggregateRuleVersions?.[takeoutPartnerId]?.[
      "RATE_SHEET"
    ]?.lookupData?.find(
      (it: any) => it?.pricingType === "MINIMUM_PRICE"
    )?.value;
    lockValues = rateLocks ? rateLocks : formatLockOptions(lockValues, "ALL");
    const loanFinalPrice =
      loanEvaluationResult?.loanResult?.[loanId]?.loanPricing
        ?.finalToorakPrice ??
      results?.[loanStage]?.[takeoutPartnerId]?.loanResult?.[loanId]
        ?.loanPricing?.finalToorakPrice ??
      "0";
    lockValues = lockValues.map((lockData: any) => {
      return {
        ...lockData,
        disabled:
          parseFloat(lockData.charge) + parseFloat(loanFinalPrice) <
          partnerMinPrice && parseFloat(lockData.charge) !== 0
      };
    });
    return lockValues;
  };

  const findAndShowRateLock = (takeoutPartnerId: string, results?: any, formattedLockOptions?: any) => {
    const lockValues = getRateLockOptions(
      takeoutPartnerId,
      loanId,
      loanStage,
      results,
      formattedLockOptions
    );
    let selectedPartner = creditLines?.find(
      (item: any) => takeoutPartnerId === item?.id
    );
    const multitakeoutPartner = isLATUser()
      ? selectedPartner?.shortName ?? ""
      : selectedPartner?.programName ?? "";

    setLockInValues({
      [loanId]: {
        lockValues,
        assignedPartner: multitakeoutPartner,
        partnerId: selectedPartner?.id
      }
    });
    setOpenLockInSelection(true);
  };

  /** Function just to run eval results
   *
   * @param isSubmitting true, if submitting. false for save
   * @param excelSubmit excel details, if submitting excel
   * @param convertedLoanId loanId after conversion of duplicate loan, for manual
   */
  function submitCallback(res?: any) {
    if (res) {
      const exceldata = getExcelDataInSession();
      dispatch(updateSubmitStatus());
      let updatedSessionObj = {};
      const stateToLoanId: any = [[], [], []];
      dispatch(resetAllStores());
      dispatch(resetExcelUploadReducer());
      Object.keys(res?.data ?? {}).forEach((item: any) => {
        const { loanState } = res?.data[item]?.loan;
        const loanId = item;

        if (
          [...PreSubmitStates, LoanStatusEnum.Submit].includes(loanState)
        ) {
          stateToLoanId[0].push(loanId);
        } else if (loanState === LoanStatusEnum.InitialReview) {
          stateToLoanId[1].push(loanId);
        } else {
          stateToLoanId[2].push(loanId);
        }
        updatedSessionObj = {
          ...updatedSessionObj,
          ...{
            [loanId]: {
              loanId,
              excelError: false,
              loanStage: loanState,
              rulesExecuted: true,
              // missingDocs: hasMissingDocs || false,
              // failedTests: hasFailingTests || false,
              primaryLoanId: exceldata?.loanIdList[loanId]?.primaryLoanId
            }
          }
        };
      });
      sessionStorage.setItem(
        "excelUpload",
        JSON.stringify({
          ...exceldata,
          ...{ loanIdList: updatedSessionObj },
          ...{ isSubmitted: true, submitClicked: true }
        })
      );
      const firstLoanId =
        stateToLoanId[0][0] || stateToLoanId[1][0] || stateToLoanId[2][0];
      setCookie("excelSubmitPressed", true);
      navigate(`/loan/createLoan/${loanType}/${loanStage}/${firstLoanId}`);
    }
    // Show info message during submission as per LPP-7161
    if (loanType === getLoanType[1].displayValue) {
      dispatch(
        showPopUpMessages({
          message: MessageBody(lockinValues),
          header: MessageHeader()
        })
      );
    }
  }

  useEffect(() => {
    const hasFailedApis =
      Object.keys(retryRequestStore?.loanSaveRequest).length > 0 ||
      Object.keys(retryRequestStore?.propertySaveRequest).length > 0;
    const loanIdsWithFieldError = Object.keys(uploadExcelStoreData).filter(
      (key) => {
        if (uploadExcelStoreData[key].createLoanStore?.errors) {
          const { loanLevelErrors, propertyLevelErrors } =
            uploadExcelStoreData[key].createLoanStore.errors;
          return loanLevelErrors + propertyLevelErrors > 0;
        }
      }
    );
    let fieldErrorsInLoanInSession: any[] = [];
    if (excelData?.loanIdList) {
      fieldErrorsInLoanInSession = Object.keys(excelData.loanIdList).filter(
        (key: string) => {
          if (key !== loanId && excelData.loanIdList[key].excelError) {
            return key;
          }
        }
      );
    }
    const isDisabled =
      hasFailedApis ||
      loanIdsWithFieldError.length > 0 ||
      fieldErrorsInLoanInSession.length > 0;
    setSubmitDisabled(isDisabled);
  }, [retryRequestStore, uploadExcelStoreData]);

  useEffect(() => {
    if (loanStage === LoanStage.post) {
      const obj =
        editHistory[`data.docs.documentTabs.${loanId}.isImportedTo.POST`];
      if (obj?.length) {
        setIsDocsImported(() => obj[0].value === "imported");
      } else {
        setIsDocsImported(false);
      }
    }
  }, [editHistory, loanStage, loanId]);

  const setDocsApiCalledToTrue = () => {
    setDocsApiCalled(true);
  };

  useEffect(() => {
    if (isDocsImported && !docsApiCalled) {
      const docIds = loanDetails.documentDetails.files.map(
        (item: any) => item?.docsId
      );
      if (docIds.length) {
        setMultiDocsDelBody({
          identifierName: "LOAN",
          identifierValue: loanId,
          docIds: docIds
        });
      } else {
        dispatch(
          getDocsByLoanId(
            loanId,
            `${loanStage}-postClose`,
            `${loanStage}-postClose`,
            loanState,
            setDocsApiCalledToTrue
          )
        );
      }
    }
  }, [loanDetails, isDocsImported]);

  useEffect(() => {
    if (hideLoanHeader) return
    if (
      submitForm.loanErrors === 0 &&
      submitForm.propertyErrors === 0 &&
      submitForm.clicked
    ) {
      dispatch(
        errorsOnSubmit({
          loanErrors: 0,
          propertyErrors: 0,
          clicked: false
        })
      );
    }
    dispatch(hideLoader(true));
  }, [
    submitForm.loanErrors,
    submitForm.propertyErrors,
    submitForm.clicked
  ]);

  const handleSave = async () => {
    if (loanStage === LoanStage.tv) {
      return dispatch(
        submitAppraisalForm({
          toorakLoanID: loanId,
          form: appraisalForm,
          save: true
        })
      );
    }
    dispatch(validateForm());
    saveLoanAndRunRuleEvaluation();
    // dispatch(hideLoader(true));
    // if (isDocsImported) {
    //   await deleteMultipleDocs(multiDocsDelBody);
    // }
    // await deleteLoan(loanId, loanStage);
    // dispatch(hideLoader(true));

    // setOpenWarning(null);
    // const excelUploadData = getExcelDataInSession();
    // if (excelUploadData?.loanIdList) {
    //   const { loanIdList } = excelUploadData;
    //   const keys = Object.keys(loanIdList);
    //   const indexOfLoan = keys.indexOf(loanId);
    //   if (indexOfLoan >= 0) {
    //     if (keys.length > 2) {
    //       delete loanIdList[loanId];
    //       deleteAndUpdate(loanIdList);
    //       // const dataToStoreInSession = {
    //       //   ...excelUploadData,
    //       //   ...{ loanIdList }
    //       // };
    //       // sessionStorage.setItem(
    //       //   "excelUpload",
    //       //   JSON.stringify(dataToStoreInSession)
    //       // );
    //       // dispatch(setDeleteLoan());
    //       // dispatch(resetAllStores());
    //       // const newUploadExcelStoreData = { ...uploadExcelStoreData };
    //       // delete newUploadExcelStoreData[loanId];
    //       // dispatch(setExcelUploadData(newUploadExcelStoreData));
    //     } else {
    //       sessionStorage.removeItem("excelUpload");
    //       dispatch(resetAllStores());
    //       if (keys.length === 1 || !excelUploadData?.maintainExcelBundle) {
    //         dispatch(resetExcelUploadReducer());
    //         dispatch(resetRetryRequestReducer());
    //         setBackToOriginalOrgId();
    //         navigate(`/loans/list?tab=1`);
    //       } else if (excelUploadData?.maintainExcelBundle) {
    //         const id = indexOfLoan ? keys[0] : keys[1];
    //         if (isILP()) {
    //           navigate(
    //             `/internal/loan/createLoan/${loanType}/${loanStage}/${id}`
    //           );
    //         } else {
    //           navigate(`/loan/createLoan/${loanType}/${loanStage}/${id}`);
    //         }
    //         window.location.reload();
    //       }
    //     }
    //   }
    // }
    // if (!excelUploadData?.maintainExcelBundle) {
    //   dispatch(resetAllStores());
    //   setBackToOriginalOrgId();
    //   dispatch(resetExcelUploadReducer());
    //   dispatch(resetRetryRequestReducer());
    //   navigate(`/loans/list?tab=1`);
    // }
  };
  const handleConfirmDelete = async () => {
    if (loanStage === LoanStage.tv) dispatch(deleteAppraisalForm());
    dispatch(showLoader(true));
    if (isDocsImported) {
      await deleteMultipleDocs(multiDocsDelBody);
    }
    await deleteLoan(loanId, loanStage);
    dispatch(hideLoader(true));

    setOpenWarning(null);
    const excelUploadData = getExcelDataInSession();
    if (excelUploadData?.loanIdList) {
      const { loanIdList } = excelUploadData;
      const keys = Object.keys(loanIdList);
      const indexOfLoan = keys.indexOf(loanId);
      if (indexOfLoan >= 0) {
        if (keys.length > 2) {
          delete loanIdList[loanId];
          deleteAndUpdate(loanIdList);
          // const dataToStoreInSession = {
          //   ...excelUploadData,
          //   ...{ loanIdList }
          // };
          // sessionStorage.setItem(
          //   "excelUpload",
          //   JSON.stringify(dataToStoreInSession)
          // );
          // dispatch(setDeleteLoan());
          // dispatch(resetAllStores());
          // const newUploadExcelStoreData = { ...uploadExcelStoreData };
          // delete newUploadExcelStoreData[loanId];
          // dispatch(setExcelUploadData(newUploadExcelStoreData));
        } else {
          sessionStorage.removeItem("excelUpload");
          dispatch(resetAllStores());
          if (keys.length === 1 || !excelUploadData?.maintainExcelBundle) {
            dispatch(resetExcelUploadReducer());
            dispatch(resetRetryRequestReducer());
            setBackToOriginalOrgId();
            navigate(`/loans/list?tab=1`);
          } else if (excelUploadData?.maintainExcelBundle) {
            const id = indexOfLoan ? keys[0] : keys[1];
            if (isILP()) {
              navigate(
                `/internal/loan/createLoan/${loanType}/${loanStage}/${id}`
              );
            } else {
              navigate(`/loan/createLoan/${loanType}/${loanStage}/${id}`);
            }
            window.location.reload();
          }
        }
      }
    }
    if (!excelUploadData?.maintainExcelBundle) {
      dispatch(resetAllStores());
      setBackToOriginalOrgId();
      dispatch(resetExcelUploadReducer());
      dispatch(resetRetryRequestReducer());
      navigate(`/loans/list?tab=1`);
    }
  };
  const handleDelete = async () => {
    if ([DataEntry, Submit, ExcelError, ExcelSuccess].includes(loanState)) {
      setOpenWarning({
        isOpen: true,
        handleClose: handleConfirmDelete,
        header: "Delete Loan",
        primaryBtnName: "Yes",
        body: `Are you sure you want to delete the loan?`,
        handleOptional: () => {
          setOpenWarning(null);
        }
      });
    }
  };

  function RunRuleEvaluation(
    isSubmitting?: boolean,
    convertedLoanId?: string,
    excelSubmit?:
      | {
        excelData: any;
        loanType: string;
        loanStage: LoanStage;
        orgId: string | null | any;
      }
      | boolean,
    overRideRateLock?: number
  ) {
    let data;
    if (!excelSubmit) {
      data = createLoanData(overRideRateLock);
      console.error("NEW function...just for eval run.. manual flow");

      getEvaluationRequestBody(
        convertedLoanId || loanId,
        loanStage,
        data,
        loanEvaluationResult,
        propertyDetails,
        loanType
      ).then(async ({ evaluate, loanEvaluationRequest }) => {
        if (evaluate && loanEvaluationRequest) {
          loanEvaluationRequest.ruleAttributs = {
            ruleGroup: [],
            ruleField: [],
            ruleResult: []
          };
          if (isSubmitting || overRideRateLock) {
            loanEvaluationRequest.resultObject = overriddenValues;
            // console.error("before eval...",loanDetails.documentDetails);
            if (loanDetails.documentDetails?.files?.length) {
              await dispatch(
                evaluateLoan(
                  loanEvaluationRequest,
                  convertedLoanId || loanId,
                  loanStage,
                  true,
                  isSubmitting,
                  propertyDetails,
                  false,
                  loanDetails.documentDetails.files
                )
              );
            } else {
              const docsDestails: any = await getDocuments(
                loanId,
                `${loanStage}-postClose`,
                `${loanStage}-postClose`,
                loanState
              );
              await dispatch(
                evaluateLoan(
                  loanEvaluationRequest,
                  convertedLoanId || loanId,
                  loanStage,
                  true,
                  isSubmitting,
                  propertyDetails,
                  false,
                  docsDestails.docs
                )
              );
            }

            if (convertedLoanId) {
              if (isILP()) {
                dispatch(
                  push(
                    `/internal/loan/createLoan/${loanType}/${loanStage}/${convertedLoanId}`
                  )
                );
              } else {
                dispatch(
                  push(
                    `/loan/createLoan/${loanType}/${loanStage}/${convertedLoanId}`
                  )
                );
              }
            }
          } else {
            dispatch(
              evaluateLoan(
                loanEvaluationRequest,
                convertedLoanId || loanId,
                loanStage
              )
            );
          }
        }
        if (isSubmitting) dispatch(hideLoader(true));
      });
    } else {
      if (isSubmitting) dispatch(hideLoader(true));
      dispatch(
        submitMultipleLoans(
          excelData,
          loanType,
          loanStage,
          getCookie("org_id"),
          submitCallback
        )
      );
    }
  }

  function saveLoanAndRunRuleEvaluation(
    isSubmitting?: boolean,
    excelSubmit?: {
      excelData: any;
      loanType: string;
      loanStage: LoanStage;
      orgId: string | null | any;
    },
    overRideRateLock?: number,
    noRulesRun?: boolean,
    saveCallBack?: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (isSubmitting || overRideRateLock) dispatch(showLoader(true));
      let postLoanObj;
      // let data;
      const bridgeLoanGuarantorInformationToPass =
        getBridgeLoanGuarantorInformationToPass(bridgeLoanGuarantorInformation);
      if (loanType === BridgeLoan) {
        // Bridge loan
        postLoanObj = {
          loanId: loanId,
          loanType: loanType,
          loanConfig: loanDetails.loanConfig,
          borrowerInformation: bridgeLoanBorrowerInformation,
          guarantorInfo: bridgeLoanGuarantorInformationToPass,
          ...bridgeLoanInformation.payload,
          ...bridgeLoanEconomics.payload
        };
      } else {
        const errorCount =
          thirtyYearBorrowerInformation.errors +
          thirtyYearGuarantorInformation.errors +
          thirtyYearLoanInformation.errors +
          thirtyYearLoanEconomics.errors;
        // setErrors(errorCount);
        postLoanObj = {
          loanId: loanId,
          loanType: loanType,
          loanConfig: {
            ...loanDetails.loanConfig,
            rateLockPeriod:
              overRideRateLock ||
              (loanDetails?.loanConfig?.rateLockPeriod ?? null)
          },
          borrowerInformation: bridgeLoanBorrowerInformation, // using same guarantor object as bridge loan
          guarantorInfo: bridgeLoanGuarantorInformationToPass, // using same guarantor object as bridge loan
          ...thirtyYearLoanInformation.payload,
          ...thirtyYearLoanEconomics.payload
        };
      }
      if (!loading) {
        // dispatch({ type: POST_LOAN_LOADING });
        dispatch(setLoading());
        let formattedLoanData: any = null;
        if (isSubmitting || overRideRateLock) dispatch(showLoader(true));
        if (loanType === BridgeLoan) {
          const borrowerInfo = getBorrowerObjectForLoanSave(
            postLoanObj.borrowerInformation,
            true
          );
          const loanUserMap = [...borrowerInfo, ...postLoanObj.guarantorInfo];
          let gResp: any = {};
          if (postLoanObj?.selectedToorakProduct) {
            gResp = await getPrimaryGuarantorInfo(
              loanUserMap,
              "bridge",
              postLoanObj?.selectedToorakProduct
            );
          }
          postLoanObj = {
            ...postLoanObj,
            guarantorInfo: postLoanObj.guarantorInfo.map((item: any) => {
              if (gResp?.primaryGuarantorSequence === item?.loanUserSequence) {
                return {
                  ...item,
                  isPrimary: true
                };
              }
              return { ...item, isPrimary: false };
            })
          };
        }

        const promise1 = postLoan(postLoanObj, false, isSubmitting); // Manual save api call
        promise1
          .then(
            async (
              response:
                | CreateLoanParamsBridgeLoan
                | CreateLoanParamsThirtyYearLoan
            ) => {
              if (isSubmitting) {
                // fetch new details again.
                // TO-DO: ask node team to make post loan response same as GET loan call response. Summary, loan config etc are missing in post call response.
                const newLoanData = await getLoanById(loanId, loanStage);
                updateLoanDetailsInRedux(newLoanData.data, dispatch);
                formattedLoanData =
                  loanStage === LoanStage.fes
                    ? formatFesLoanDetailsForRedux(newLoanData.data)
                    : formatLoanDetailsForRedux(newLoanData.data, "");
                dispatch(
                  setLoanByIdSuccess({
                    loan: formattedLoanData,
                    keepTab: true
                  })
                );
              }
              dispatch(
                postLoanSuccess({
                  reservation: response
                })
              );
              // dispatch({
              //   type: POST_LOAN_SUCCESS,
              //   payload: {
              //     reservation: response
              //   }
              // });
              if (isSubmitting) {
                // fetch new details again.
                // TO-DO: ask node team to make post loan response same as GET loan call response. Summary, loan config etc are missing in post call response.
                // dispatch(hideLoader(true));
                dispatch(fetchAndDispatchLoan(loanId, loanStage));
              }
            }
          )
          .catch((error) => {
            console.error("err", error);
            dispatch(
              loanTypeFailed({
                error
              })
            );
            if (isSubmitting || overRideRateLock) dispatch(hideLoader(true));
          });
        let propData = propertyDetails;
        if (propertyDetails.length === 0) {
          propData = [emptyPropertyBasedOnLoan(String(loanType || ""), loanStage)];
        }
        const promise2 = postPropertyData(
          loanId,
          loanStage,
          propData,
          null,
          false,
          isSubmitting,
          true
        );
        promise2
          .then((response: CreateLoanParamsBridgeLoan) => {
            // dispatch({
            //   type: POST_PROPERTY_SUCCESS,
            //   payload: {
            //     reservation: response
            //   }
            // });
            dispatch(updatePropertySuccess());
          })
          .catch((error) => {
            console.error("err", error);
            // dispatch({ type: POST_PROPERTY_FAILED, error });
            dispatch(
              loanTypeFailed({
                error
              })
            );
            if (isSubmitting || overRideRateLock) {
              dispatch(hideLoader(true));
              dispatch(keepCreditEvalSync(true));
            }
          });
        if (!noRulesRun) {
          if (!excelSubmit) {
            await Promise.all([promise1, promise2])
              .then((values) => {
                if (values[1].status === 200) {
                  RunRuleEvaluation(
                    isSubmitting,
                    "",
                    excelSubmit,
                    overRideRateLock
                  );
                  setBackToOriginalOrgId();
                  return resolve(true);
                }
              })
              .catch((e) => {
                console.error(e);
                return reject();
              });
          } else {
            Promise.all([promise1, promise2])
              .then((values) => {
                if (values[1].status === 200) {
                  RunRuleEvaluation(isSubmitting, "", excelSubmit);
                }
                setBackToOriginalOrgId();
                return resolve(true);
              })
              .catch((e) => {
                console.error(e);
                return reject();
              });
          }
        } else {
          await Promise.all([promise1, promise2])
            .then((values) => {
              if (values[1].status === 200) {
                if (saveCallBack) saveCallBack();
                return resolve(true);
              }
            })
            .catch((e) => {
              console.error(e);
              return reject();
            });
        }
        if (isSubmitting || overRideRateLock) dispatch(hideLoader(true));
      }
    dispatch(hideLoader(true));
    });
  }

  function saveAndRunGuideLineEvaluation(isSubmitting?: boolean, cb?: any, newLoanDetails?: any, noRulesRun?: boolean, saveCallBack?: any) {
    dispatch(cancelAutoSavee()); // if we dont cancel here, auto save call will happen post submission api calls and loans will be corrupted.//lpp 6432
    if (!isSubmitting) dispatch(validateForm());
    if (isSubmitting) dispatch(showLoader());
    const updatedLoanDetails: any = { ...loanDetails };

    if (newLoanDetails) {
      updatedLoanDetails.loanInfo = {
        ...updatedLoanDetails.loanInfo,
        ...newLoanDetails.loanInfo
      }
    }
    const promise1 = postFesLoan(updatedLoanDetails, loanId, loanType, loanStage);
    const promise2 = postFesPropertyData(
      loanId,
      propertyDetails,
      loanSizerType,
      undefined,
      true
    );
    Promise.all([promise1, promise2])
      .then((values) => {
        try {
          const formattedLoanData = formatFesLoanDetailsForRedux(values[0]);
          const activeTabBeforeReduxAction = activeTab;
          // dispatch({
          //   type: GET_LOAN_BY_ID_SUCCESS,
          //   payload: {
          //     loan: formattedLoanData
          //   }
          // });
          dispatch(
            setLoanByIdSuccess({
              loan: formattedLoanData,
              keepTab: true
            })
          );
          dispatch(setActiveTabAndProperty(activeTabBeforeReduxAction));
        } catch (error) {
          console.error(error);
        }
        saveCallBack?.();
        if (values[1].status === 200 && !noRulesRun) {
          const payload = getFesRulesRequestObject(
            loanDetails,
            loanId,
            loanType,
            loanStage,
            isSubmitting
          );
          dispatch(
            evaluateLoan(
              payload,
              loanId,
              loanStage,
              isSubmitting,
              isSubmitting,
              propertyDetails,
              false,
              false,
              false,
              null,
              cb
            )
          );
          if (isSubmitting) dispatch(hideLoader());
        }
        if (isSubmitting) dispatch(keepCreditEvalSync(true));
      })
      .catch((e) => {
        console.error(e);
        if (isSubmitting) dispatch(hideLoader());
      });
  }

  const showLoanConvertError = () => {
    setOpenWarning({
      isOpen: true,
      header: "Error",
      body: (
        <>
          <Grid container direction="column">
            <Grid item xs={12} style={{ padding: 20 }}>
              <div>
                Sorry could not convert the loan. There is a data issue with the
                loan{" "}
                <b>
                  {getOriginatorId(
                    excelData,
                    loanId,
                    loanType,
                    loanDetails,
                    appraisalOriginatorLoanId
                  )}
                </b>{" "}
                in the previous stage. Please check.
              </div>
              <br />
            </Grid>
          </Grid>
        </>
      ),
      handleClose: async () => {
        setOpenWarning(null);
      }
    });
  };

  const convertSingleLoan = async (
    dupliLoansList: any[],
    overRideRateLock?: number,
    extraData?: any[]
  ) => {
    dispatch(showLoader(true));
    const PropObj = await postPropertyData(
      dupliLoansList[0].loanId,
      loanStage,
      propertyDetails,
      null,
      true,
      false,
      true
    );
    let postLoanObj;
    const bridgeLoanGuarantorInformationToPass =
      getBridgeLoanGuarantorInformationToPass(bridgeLoanGuarantorInformation);
    if (loanType === BridgeLoan) {
      // Bridge loan
      postLoanObj = {
        loanId: dupliLoansList[0].loanId,
        loanType: loanType,
        loanConfig: loanDetails.loanConfig,
        borrowerInformation: bridgeLoanBorrowerInformation,
        guarantorInfo: bridgeLoanGuarantorInformationToPass,
        ...bridgeLoanInformation.payload,
        ...bridgeLoanEconomics.payload
      };
    } else {
      let newLoanConfig = loanDetails.loanConfig;
      if (overRideRateLock) {
        newLoanConfig = {
          ...loanDetails.loanConfig,
          rateLockPeriod:
            overRideRateLock ||
            (loanDetails?.loanConfig?.rateLockPeriod ?? null)
        };
      }
      postLoanObj = {
        loanId: dupliLoansList[0].loanId,
        loanType: loanType,
        loanConfig: newLoanConfig,
        borrowerInformation: bridgeLoanBorrowerInformation, // using same guarantor object as bridge loan
        guarantorInfo: bridgeLoanGuarantorInformationToPass, // using same guarantor object as bridge loan
        ...thirtyYearLoanInformation.payload,
        ...thirtyYearLoanEconomics.payload
      };
    }
    const loanObj = await postLoan(postLoanObj, true, false);
    dispatch(keepCreditEvalSync(true));
    try {
      try {
        await convertLoan(
          dupliLoansList[0].loanId,
          dupliLoansList[0].loanStage,
          loanStage,
          true,
          false,
          true,
          { loan: loanObj, property: PropObj }
        );
      } catch (e) {
        const err: any = e;
        if (err?.response?.status === 404) {
          dispatch(hideLoader(true));
          showLoanConvertError();
          return;
        }
      }
      try {
        if (isDocsImported) {
          await deleteMultipleDocs(multiDocsDelBody);
        }
        await deleteLoan(loanId, loanStage);
      } catch (e) {
        console.error("Error while deleting loan", e);
      }
      if (extraData && extraData?.length) {
        await updatePropInfoWithToorvalID(
          loanId,
          loanStage,
          extraData[0].loanId,
          dispatch
        );
      }
      RunRuleEvaluation(
        true,
        dupliLoansList[0].loanId,
        false,
        overRideRateLock
      );
      dispatch(hideLoader(true));
    } catch (e) {
      dispatch(hideLoader(true));
      console.error("loan conversion didnot happened", e);
    }
  };

  const handleConvertToorvalManualLoan = async (duplicateProperties: any[]) => {
    dispatch(showLoader());
    try {
      if (loanDetails.loanLandmarks.some(checkIfFESLoan)) {
        // loan was originally created as FES
        await convertSingleToorvalFesLoan(
          duplicateProperties,
          loanStage,
          loanId,
          dispatch
        );
        // submitLoan();

        dispatch(hideLoader());
        return;
      } else {
        await convertSingleToorvalLoan(duplicateProperties, loanStage, loanId);
        sessionStorage.setItem("submitNow", "true");
      }
    } catch (e) {
      const err: any = e;
      console.error(err);
      if (err.response.status === 404) {
        showLoanConvertError();
      }
      dispatch(hideLoader());
    }
    dispatch(hideLoader());
    navigate(
      `/loan/createLoan/${loanType}/${loanStage}/${duplicateProperties[0].loanId}`
    );
    // dispatch(
    //   push(
    //     `/loan/createLoan/${loanType}/${loanStage}/${duplicateProperties[0].loanId}`
    //   )
    // );
  };

  const convertExcelLoan = async (
    duplicateProperties: any[],
    isToorval?: boolean,
    overRideRateLock?: number,
    extraData?: any[]
  ) => {
    const excelUploadData = getExcelDataInSession();
    if (!excelUploadData?.loanIdList) return;
    const deleteLoanIds: any[] = [];
    dispatch(showLoader(true));
    await Promise.all(
      duplicateProperties.map(async (convLoan: any) => {
        dispatch(showLoader(true));
        let excelLoanId = convLoan.newLoanId;
        if (isToorval) {
          excelLoanId = convLoan.newToorvalLoanId;
        }

        try {
          await convertLoan(
            convLoan.loanId,
            convLoan.loanStage,
            loanStage,
            true
          );
        } catch (e) {
          const err: any = e;
          if (err?.response?.status === 404) {
            dispatch(hideLoader(true));
            showLoanConvertError();
            return;
          }
        }
        const { loanIdList } = excelUploadData;
        const keys = Object.keys(loanIdList);
        const indexOfLoan = keys.indexOf(excelLoanId);
        if (indexOfLoan >= 0) {
          loanIdList[convLoan.loanId] = {
            ...{},
            ...loanIdList[excelLoanId]
          };
          loanIdList[convLoan.loanId].loanId = convLoan.loanId;
          delete loanIdList[excelLoanId];
          deleteLoanIds.push({
            newLoanId: excelLoanId,
            loanId: convLoan.loanId
          });
        }
        await copyLoan(excelLoanId, convLoan.loanId, loanStage);
        if (isToorval) {
          await onlyUpdateAppraisalForm({
            toorakLoanID: convLoan.loanId,
            appraisalID: convLoan.appraisalId,
            form: {
              convertedTo: loanStage
            }
          });
        }
        await deleteLoan(excelLoanId, convLoan.loanStage);
        if (extraData?.length) {
          let toorvalData: any = extraData.filter(
            (item) =>
              item.primaryLoanID === convLoan.primaryLoanID ||
              (item.properties[0].address ===
                convLoan.properties[0].propertyLocation.addressLine1 &&
                item.properties[0].postalCode ===
                convLoan.properties[0].propertyLocation.postalCode)
          )[0];
          await onlyUpdateAppraisalForm({
            toorakLoanID: toorvalData.loanId,
            appraisalID: toorvalData.appraisalId,
            form: {
              convertedTo: loanStage
            }
          });
          await updatePropInfoWithToorvalID(
            convLoan.loanId,
            loanStage,
            toorvalData.loanId,
            dispatch
          );
        }
        dispatch(hideLoader(true));
      })
    );
    dispatch(hideLoader(true));
    deleteAndUpdate(
      excelUploadData.loanIdList,
      deleteLoanIds,
      overRideRateLock
    );
  };

  function showErrorPopup(
    duplicateProperties: any[],
    topic: string,
    extraData?: any[]
  ) {
    switch (topic) {
      case "TOORVAL_ONLY":
        // saving here so that loan can be directly submitted after conversion
        saveLoanAndRunRuleEvaluation();
        setOpenWarning({
          isOpen: true,
          handleOptional: () => {
            setOpenWarning(null);
          },
          header: "TOORVAL Loan Conversion",
          primaryBtnName: "Proceed",
          body: getDupliPopupHTML(topic, duplicateProperties, []),
          handleClose: async () => {
            setOpenWarning(null);
            if (excelData?.maintainExcelBundle) {
              // For Excel Flow

              convertExcelLoan(duplicateProperties, true);
              // updateConvertedToorvalLoans(
              //   duplicateProperties,
              //   loanStage,
              //   dispatch
              // );
            }
            else {
              // For Manual Flow
              handleConvertToorvalManualLoan(duplicateProperties);
            }
          }
        });
        break;
      case "TOORVAL_FES":
        // saving here so that loan can be directly submitted after conversion
        saveLoanAndRunRuleEvaluation();
        setOpenWarning({
          isOpen: true,
          handleOptional: () => {
            setOpenWarning(null);
          },
          header: "TOORVAL-FES Loan Conversion",
          primaryBtnName: "Proceed",
          body: getDupliPopupHTML(topic, duplicateProperties, extraData || []),
          handleClose: async () => {
            setOpenWarning(null);
            if (excelData?.maintainExcelBundle) {
              // For Excel Flow
              convertExcelLoan(duplicateProperties, false, 0, extraData);
            } else {
              // For Manual Flow
              if (extraData && extraData?.length) {
                await onlyUpdateAppraisalForm({
                  toorakLoanID: extraData[0].loanId,
                  appraisalID: extraData[0].appraisalId,
                  form: {
                    convertedTo: loanStage
                  }
                });

                convertSingleLoan(duplicateProperties, 0, extraData);
              }
            }
          }
        });
        break;
      case "CONVERT":
        // saving here so that loan can be directly submitted after convertion
        saveLoanAndRunRuleEvaluation();
        setOpenWarning({
          isOpen: true,
          handleOptional: () => {
            setOpenWarning(null);
          },
          header: "Duplicate Loan/Loan Conversion",
          primaryBtnName: "Ok",
          body: (
            <>
              <Grid container direction="column">
                <Grid item xs={12} style={{ padding: 20 }}>
                  <div>
                    There are existing loans in the system with the same
                    property address and originator loan id in the previous
                    stage that are submitted.
                  </div>
                  <br />
                  <div>{showDuplicateDetals(duplicateProperties)}</div>
                  <br />
                  {/* <div>
                    The existing loans will be converted and merged with the
                    current loan. In case of multiple duplicate loans for a new
                    loan, the latest one to be considered. Do you want to
                    proceed?
                  </div> */}
                  <div>Please reach out to your Toorak LAT representative.</div>
                  <br />
                  <br />
                </Grid>
              </Grid>
            </>
          ),
          handleClose: async () => {
            setOpenWarning(null);
          }
        });

        break;
      case "AND":
      case "OR":
        setOpenWarning({
          isOpen: true,
          header: "Duplicate Loan",
          body: (
            <>
              <Grid container direction="column">
                <Grid item xs={12} style={{ padding: 20 }}>
                  <div>
                    Sorry the loan{" "}
                    <b>
                      {getOriginatorId(
                        excelData,
                        loanId,
                        loanType,
                        loanDetails,
                        appraisalOriginatorLoanId
                      )}
                    </b>{" "}
                    cannot be added.
                  </div>
                  <br />
                  <div>
                    There are existing loans in the system with the same
                    property address {topic.toLowerCase()} originator loan id in
                    the current/next stage that are submitted.
                  </div>
                  <br />
                  <div>{showDuplicateDetals(duplicateProperties)}</div>
                </Grid>
              </Grid>
            </>
          ),
          handleClose: async () => {
            setOpenWarning(null);
          }
        });
        break;
      default:
        return;
    }
  }

  function showMissingPrimaryGuarantorOrBorrower() {
    setOpenWarning({
      isOpen: true,
      handleClose: () => {
        setOpenWarning(null);
      },
      header: "Warning",
      primaryBtnName: "Ok",
      body: "Loan should contain atleast one individual borrower or combination of entity borrower and a guarantor."
    });
  }
  function showDocsMissingError(loansWithMissingDocs: string[]) {
    const loanIdsString = loansWithMissingDocs?.join(",") ?? "";
    setOpenWarning({
      isOpen: true,
      handleClose: () => {
        setOpenWarning(null);
      },
      header: "Document Missing",
      primaryBtnName: "Ok",
      body: `Please upload atleast one document for the loan${loansWithMissingDocs?.length > 1 ? "s" : ""
        } ${loanIdsString} to submit`
    });
  };

  function validateResponse(response: any) {
    const { duplResultsDetails, metaData } = response;
    if (
      metaData.type === LoanStage.tv &&
      !(
        duplResultsDetails.andArray.length || duplResultsDetails.orArray.length
      ) &&
      metaData.ToorvalLoans[0]?.loanId !== loanId
    ) {
      handleToorvalDuplicateLoans(metaData, duplResultsDetails, showErrorPopup);
      return;
    }
    const dupliLoansList = duplResultsDetails?.andArray?.length
      ? duplResultsDetails.andArray
      : duplResultsDetails?.orArray?.length
        ? duplResultsDetails.orArray
        : duplResultsDetails?.convertArray;
    // setDupliCheckResp({ dupliLoansList, metaData: metaData.duplResultsType });
    // based on priority AND>OR>convert

    if (
      duplResultsDetails?.andArray?.length > 0 ||
      duplResultsDetails?.orArray?.length > 0 ||
      duplResultsDetails?.convertArray?.length > 0
    ) {
      showErrorPopup(dupliLoansList, metaData?.duplResultsType);
    }
  }

  const handleBridgeError = (e: any) => {
    const err: any = e;
    if (err?.response?.status === 400) {
      const stacktrace = err.response.data?.stacktrace || {};
      if (err.response.data?.message === "Duplicate Primary LoanID or Property data") {
        validateResponse(stacktrace);
      }
      if (err.response.data?.message === "Atleast one document needs to be uploaded") {
        showDocsMissingError(err.response.data?.stacktrace?.loanIds ?? []);
      }
      if (err.response.data?.message === "Atleast one guarantor required for entity borrower") {
        showMissingPrimaryGuarantorOrBorrower();
      }
      // if (err.response.data?.message === "Rules execution failed") {
      //   showMissingPrimaryGuarantorOrBorrower();
      // }
    }
  };

  const handleDscrError = (e: any) => {
    const err: any = e;
    if (err?.response?.status === 400) {
      const stacktrace = err.response.data?.stacktrace || {};
      if (err.response.data?.message === "Duplicate Primary LoanID or Property data") {
        validateResponse(stacktrace);
      }
      if (err.response.data?.message === "Atleast one document needs to be uploaded") {
        showDocsMissingError([loanId]);
      }
      if (err.response.data?.message === "Atleast one guarantor required for entity borrower") {
        showMissingPrimaryGuarantorOrBorrower();
      }
      // if (err.response.data?.message === "Rules execution failed") {
      //   showMissingPrimaryGuarantorOrBorrower();
      // }
    }
  };

  const handeDscrLoanSubmit = (reqBody: any = {}) => {
    submitDscrLoan(loanId, loanStage, reqBody).then((res: any) => {
      globalGetDataByLoanID(
        loanId,
        loanStage,
        false,
        () => {
          dispatch(hideLoader(true));
          dispatch(hideLoader());
          dispatch(setActiveTabAndProperty(isLATUser() && loanStage !== LoanStage.fes ? "summary" : "loanDetails"));
          submitCallback();
        }
      );
    }).catch((e: any) => {
      console.error(e);
      dispatch(hideLoader(true));
      dispatch(hideLoader());
    })
  }

  const checkDscrLoanLockAndSubmit = (isSubmitAllowed: boolean) => {
    checkDscrLoanSubmition(loanId, loanStage, orgPartyId).then((res: any) => {
      const { tpResult, isLoanConverted, takeoutPartnerData, rateLocks } = res?.data ?? {};
      const { eligiblePartners = [], assignedPartner = "", dailyCreditLimit } = takeoutPartnerData || {};
      const lockKeys = Object.keys(rateLocks || {});
      setNoLocks(lockKeys?.every((key) => Object.keys(rateLocks?.[key]?.rateLocks ?? {})?.length === 0));
      const partnerLocks = rateLocks?.[assignedPartner]?.rateLocks;
      const formattedRateLocks = Object.keys(partnerLocks || {}).map((key) => {
        const hasNoCharges = !parseFloat(partnerLocks?.[key]);
        let charges = partnerLocks?.[key] ?? "";
        charges = charges.includes("-")
          ? `${charges.replace("-", "-$")}`
          : `${charges}`;
        const chargesText = `${key} days at ${hasNoCharges ? `` : `${charges} of `
          }current rate`;
        return {
          "value": key,
          label: chargesText,
          charge: partnerLocks?.[key]
        };
      });
      const tpIds = Object.keys(tpResult?.[loanId]?.[loanStage] || {});
      const tempCriticalTests: ObjectType = {};
      const tpList: ObjectType[] = [];
      tpIds.forEach((tpId: string) => {
        const ruleVersion: string =
          tpResult?.[loanId]?.[loanStage]?.[tpId]?.loanResult?.[loanId]
            ?.ruleVersion?.RULE_VERSION || "";
        tpList.push(creditLines.find((it: any) => it.id === tpId));
        getCriticalTests(ruleVersion)
          .then((criticalResponse: any) => {
            tempCriticalTests[tpId] = [
              ...criticalResponse?.data?.criticalTestLoanEligibility
            ];
          })
          .catch((err: any) => {
            console.error(err);
          });
      });
      setTakeOutPartnersList(tpList);
      setCriticalTests(tempCriticalTests);
      dispatch(updateEligiblePartners(eligiblePartners));
      if (eligiblePartners?.length === 1) {
        let isWithinDailyLimit = true;
        isWithinDailyLimit =
          dailyCreditLimit[eligiblePartners?.[0]]?.isWithinLimit;

        if (!isWithinDailyLimit) {
          dispatch(hideLoader());
          setOpenWarning({
            isOpen: true,
            handleClose: () => {
              setOpenWarning(null);
            },
            header: "CreditLine Limit",
            primaryBtnName: "Ok",
            body: `Eligible partner credit limit has reached. Please contact Lat for support`,
            handleOptional: () => {
              setOpenWarning(null);
            }
          });
          return;
        }
      } else if (eligiblePartners?.length > 1) {
        // when all partner daily credit limit reached
        let isWithinDailyLimit = false;
        isWithinDailyLimit = eligiblePartners?.every(
          (eligiblePartnerId: string) =>
            dailyCreditLimit[eligiblePartnerId]?.isWithinLimit === false
        );
        if (isWithinDailyLimit) {
          dispatch(hideLoader());
          setOpenWarning({
            isOpen: true,
            handleClose: () => {
              setOpenWarning(null);
            },
            header: "CreditLine Limit",
            primaryBtnName: "Ok",
            body: `All eligible partner credit limit has reached. Please contact Lat for support`,
            handleOptional: () => {
              setOpenWarning(null);
            }
          });
          return;
        }
      }
      setEligibleTakeOutPartnerResult(takeoutPartnerData);
      setPartnerWiseRateLockOptions(rateLocks);
      setLoansResult(tpResult);
      if (isLoanConverted && !allowRateLockSelection) {
        if (isSubmitAllowed) {
          handeDscrLoanSubmit();
        } else {
          dispatch(hideLoader(true));
        }
      } else {
        const results = tpResult?.[loanId]?.[loanStage]?.[eligiblePartners?.[0]];
        findAndShowRateLock(assignedPartner || eligiblePartners?.[0], results, formattedRateLocks);
        dispatch(hideLoader(true));
      }
    }).catch((e: any) => {
      handleDscrError(e);
      dispatch(hideLoader(true));
    });
  }

  const validateAndSubmitManualLoan = async () => {
    if (loanStage === LoanStage.tv) {
      return dispatch(
        submitAppraisalForm({
          toorakLoanID: loanId,
          form: appraisalForm,
          save: false
        })
      );
    }
    if (loanType === getLoanType[0].displayValue) {
      checkBridgeLoanSubmition(loanStage, orgPartyId, [loanId]).then((res: any) => {
        if (res) {
          submitBridgeLoan(loanStage, [loanId]).then(() => {
            globalGetDataByLoanID(loanId, loanStage, false, () => {
              dispatch(hideLoader(true));
              dispatch(setActiveTabAndProperty(isLATUser() && loanStage !== LoanStage.fes ? "summary" : "loanDetails"));
              submitCallback();
            });
          }).catch((e: any) => {
            console.error(e);
            dispatch(hideLoader(true));
          })
        }
      }).catch((e: any) => {
        dispatch(hideLoader(true));
        handleBridgeError(e);
      });
    }
    if (loanType === getLoanType[1].displayValue) {
      checkDscrLoanLockAndSubmit(true);
    }
  };

  async function validateAndSubmitExcelLoan() {
    if (!submitDisabled) {
      const data = getExcelDataInSession();
      const orgPartyId = sessionStorage.getItem("originatorPartyIdNew") || "";
      const loanIds = Object.keys(data?.loanIdList ?? {});
      if (loanIds.length > 0) {
        checkBridgeLoanSubmition(loanStage, orgPartyId, loanIds).then((res: any) => {
          submitBridgeLoan(loanStage, loanIds).then((res: any) => {
            globalGetDataByLoanID(loanId, loanStage, false, () => {
              dispatch(hideLoader(true));
              dispatch(setActiveTabAndProperty(isLATUser() && loanStage !== LoanStage.fes ? "summary" : "loanDetails"));
              submitCallback(res);
            });
          }).catch((e: any) => {
            console.error(e);
            dispatch(hideLoader(true));
          })
        }).catch((e: any) => {
          handleBridgeError(e);
          dispatch(hideLoader(true));
        });
      }
      dispatch(hideLoader(true));
    }
    dispatch(hideLoader(true));
    // showErrorPopup(dupliLoansList, metaData.duplResultsType);
  }

  /**
   *
   * @param loanIdList
   * @param isUpdate
   */
  const deleteAndUpdate = (
    loanIdList: any,
    isUpdate?: any[],
    rateLockPeriod?: any
  ) => {
    const excelUploadData = getExcelDataInSession();
    const dataToStoreInSession = {
      ...excelUploadData,
      ...{ loanIdList }
    };
    sessionStorage.setItem("excelUpload", JSON.stringify(dataToStoreInSession));
    dispatch(toggleDeleteLoan());

    // not required for duplicate loan conversion to  reset all stores
    if (!isUpdate?.length) {
      dispatch(resetAllStores());
    }

    const newUploadExcelStoreData = { ...uploadExcelStoreData };
    if (isUpdate?.length) {
      isUpdate.forEach((id) => {
        // newUploadExcelStoreData[id.loanId] = {...newUploadExcelStoreData[id.newLoanId]}
        delete newUploadExcelStoreData[id.newLoanId];
      });
    } else {
      delete newUploadExcelStoreData[loanId];
    }

    dispatch(setExcelUploadData(newUploadExcelStoreData));
    if (isUpdate?.length) {
      const newexcelData = getExcelDataInSession();
      if (rateLockPeriod) {
        saveLoanAndRunRuleEvaluation(
          true,
          {
            excelData: newexcelData,
            loanType,
            loanStage,
            orgId: getCookie("org_id")
          },
          rateLockPeriod
        );
      } else {
        dispatch(
          submitMultipleLoans(
            newexcelData,
            loanType,
            loanStage,
            getCookie("org_id"),
            submitCallback
          )
        );
      }
    }
  };

  /**
   *
   * This function checks if it is an Excel upload or Manual and then submits the loan
   * Before submitting a loan atleast one document is required per loan : TA-2951
   */

  async function validateAndSubmit() {
    await delay(500);
    dispatch(validateForm());
    setIsSubmitClicked(true);
  }

  function getErrorCount() {
    const { loanErrorCount: loanDetailsErrorCount, areEmailsValid, areExperienceValid } = countLoanDetailError(loanStage, loanType, loanDetails, {}, loanEvaluationResult, loanId);
    let propertyDetailsErrorCount = 0;
    loanDetails?.propertyDetails?.map((propData: any) => {
      propertyDetailsErrorCount += Object.values(
        computePropertyLocationError(
          propData.propertyLocation.payload,
          loanDetails?.propertyDetails.map((item: any) => {
            if (item.propertyId === propData.propertyId) {
              return null;
            }
            if (
              typeof item.propertyLocation?.payload?.address === "string"
            ) {
              return {
                city: item.propertyLocation.payload.city,
                state: item.propertyLocation.payload.state,
                // eslint-disable-next-line @typescript-eslint/camelcase
                street_line: item.propertyLocation.payload.address,
                zipcode: item.propertyLocation.payload.zipCode
              };
            }
            return item.propertyLocation?.payload?.address;
          })
        )
      ).filter(item => item !== "").length;

      if (loanStage !== LoanStage.fes) {
        propertyDetailsErrorCount += computePropertyError(
          { ...propData, loanRuleVersions: loanDetails?.loanRuleVersions },
          loanType,
          loanStage as LoanStage,
          loanDetails?.bridgeIsGroundUpLoan,
          { ...loanDetails, loanEvaluationResult, loanId }
        );
      } else {
        propertyDetailsErrorCount += calculateFesPropertyError(
          propData,
          loanType,
          loanSizerType,
          { loanEvaluationResult, loanId }
        );
      }
    });
    return { errorCount: loanDetailsErrorCount + propertyDetailsErrorCount, areEmailsValid, areExperienceValid };
  }

  useEffect(() => {
    if (autoSaveCount > 0 || !isSubmitClicked) return;
    setIsSubmitClicked(false);
    let actualErrorCount: number = 0;
    let isActualEmailValid: boolean = true;
    if (loanStage !== LoanStage.tv) {
      const { errorCount, areEmailsValid = true } = getErrorCount();
      actualErrorCount = errorCount;
      isActualEmailValid = areEmailsValid;
    }
    if (actualErrorCount > 0 || !isActualEmailValid) return;
    if (loanStage === LoanStage.fes) {
      saveAndRunGuideLineEvaluation(false, () => { }, {}, true, saveLoan);
    } else {
      saveLoanAndRunRuleEvaluation(true, undefined, undefined, true, saveLoan);
    }
    async function saveLoan() {
      setTimeout(() => {
        dispatch(showLoader(true));
        if (excelData?.maintainExcelBundle) {
          validateAndSubmitExcelLoan();  // Excel loan submit flow
        } else {
          validateAndSubmitManualLoan(); // Manual  loan submit flow
        }
      }, 200)
    }
  }, [autoSaveCount, isSubmitClicked]);

  const hideLockinPopup = () => {
    setOpenLockInSelection(false);
    dispatch(
      errorsOnSubmit({
        loanErrors: submitForm.loanErrors,
        propertyErrors: submitForm.propertyErrors,
        clicked: false
      })
    );
  };

  const getRateLockOptionsBasedOnPartnerId = useCallback((currentSelectedPartner: any) => {
    const partnerData = currentSelectedPartner?.partnerId
      ? currentSelectedPartner
      : lockinValues?.[loanDetailId?.toorakLoanId];
    const tpId = partnerData?.partnerId || takeoutPartnerId || eligibleTakeOutPartnerResult?.assignedPartner;
    const rateLocks = partnerWiseRateLockOptions?.[tpId]?.rateLocks;
    let formatedRateLocks: ObjectType[] = [];
    if (rateLocks) {
      formatedRateLocks = Object.keys(rateLocks).map((key) => {
        const hasNoCharges = !parseFloat(rateLocks?.[key]);
        let charges = rateLocks?.[key] ?? "";
        charges = charges.includes("-")
          ? `${charges.replace("-", "-$")}`
          : `${charges}`;
        const chargesText = `${key} days at ${hasNoCharges ? `` : `${charges} of `
          }current rate`;
        return {
          "value": key,
          label: chargesText,
          charge: rateLocks?.[key]
        };
      });
    }
    const tempLockInValues = {
      [loanId]: {
        partnerId: partnerData?.partnerId,
        assignedPartner: partnerData?.assignedPartner,
        lockValues: getRateLockOptions(
          tpId,
          loanId,
          loanStage,
          eligibleTakeOutPartnerResult?.[loanId],
          formatedRateLocks
        )
      }
    };
    setRateLockOptions(tempLockInValues);
  }, [partnerWiseRateLockOptions, takeoutPartnerId, eligibleTakeOutPartnerResult])

  useEffect(() => {
    getRateLockOptionsBasedOnPartnerId(currentSelectedPartner);
  }, [currentSelectedPartner, loanDetails, eligibleTakeOutPartnerResult]);

  const lockinPopupData = useMemo(() => {
    const originatorLoanId = getOriginatorId(
      {},
      loanId,
      loanType,
      loanDetails,
      appraisalOriginatorLoanId
    );
    const partnerData = currentSelectedPartner?.partnerId
      ? currentSelectedPartner
      : lockinValues?.[loanDetailId?.toorakLoanId];

    const results =
      loansResult?.[loanId]?.[loanStage]?.[
      partnerData?.partnerId || partnerData?.assignedPartner
      ] ?? loanEvaluationResult;
    const loanPricing = results?.loanResult?.[loanId]?.loanPricing;
    const toorakProceeds = results?.loanResult?.[loanId]?.toorakProceeds;
    return [
      {
        toorakLoanId: loanDetailId?.toorakLoanId ?? "",
        originatorLoanId,
        assignedPartner: isLATUser()
          ? partnerData?.assignedPartner
          : getOriginatorLabel(partnerData?.assignedPartner, creditLines) ?? "",
        ...loanPricing,
        toorakProceeds
      }
    ];
  }, [
    loanId,
    loanType,
    loanDetails,
    lockinValues,
    loanDetailId,
    loansResult,
    rateLockOptions
  ]);

  useEffect(() => {
    if(currentSelectedPartner?.partnerId) {
      setCurrentSelectedPartner(currentSelectedPartner)
    }
    const partnerData = currentSelectedPartner?.partnerId
      ? currentSelectedPartner
      : lockinValues?.[loanDetailId?.toorakLoanId];
    if (
      !currentSelectedPartner?.partnerId &&
      lockinValues?.[loanDetailId?.toorakLoanId]
    ) {
      setCurrentSelectedPartner(partnerData);
    }
  }, [lockinValues, currentSelectedPartner]);

  const handlePopupSubmit = (overRidedata: any) => {
    // dscr loan submit from rate lock for below 2 scenarios
    // 1. when at least one partner is eligible for rate lock
    // 2. after loan submition
    let rateLockMap: { [key: string]: string } = {};
    let partnerMap: { [key: string]: string } = {};
    let rateLockApprovalRequiredMap: { [key: string]: string } = {};
    overRidedata.forEach((it: any) => {
      rateLockMap = {
        ...rateLockMap,
        [it.loanId]:
          it.loanDetails.loanConfig.rateLockPeriod
      };
      partnerMap = {
        ...partnerMap,
        [it.loanId]: it?.loanDetails?.loanInfo?.takeoutPartnerStatus === "OVERRIDDEN"
          ? it?.loanDetails?.loanInfo?.takeoutPartner : ""
      };
      rateLockApprovalRequiredMap = {
        ...rateLockMap,
        [it.loanId]:
          it.loanDetails.loanConfig.rateLockApprovalRequired
      };
    });
    const rateLockPeriod = parseInt(rateLockMap?.[loanId], 10);
    const rateLockApprovalRequired = rateLockApprovalRequiredMap?.[loanId];
    const takeoutPartner = partnerMap?.[loanId];
    const requestBody: ObjectType = {
      rateLocks: {
        rateLockStatus: rateLockApprovalRequired,
        rateLockPeriod: isNaN(rateLockPeriod) ? null : rateLockPeriod
      }
    }
    if (takeoutPartner) {
      requestBody.rateLocks.takeoutPartner = takeoutPartner;
    }
    if (!hideLoanHeader) {
      handeDscrLoanSubmit(requestBody);
    } else {
      const payload: ObjectType = {
        "op": "replace",
        "loanState": loanState,
        "loanData": {
          loanId,
          "loanTypeId": 1,
          "loanType": "InvestorDSCR",
          rateLocks: requestBody.rateLocks
        }
      };
      patchLoanData(payload, "dscr", loanId, loanStage)
        .then(async (resp: any) => {
          globalGetDataByLoanID(loanId, loanStage, false, () => {
            dispatch(hideLoader(true));
            dispatch(hideLoader());
            dispatch(setActiveTabAndProperty(isLATUser() && loanStage !== LoanStage.fes ? "summary" : "loanDetails"));
          });
        })
        .catch((e: any) => {
          dispatch(hideLoader(true));
          dispatch(hideLoader());
          console.error(e);
        });
    }
    hideLockinPopup();
  };

  return (
    <>
      <AppBar color="default" position="static" className={classes.fixedHeader}>
        {(openLockInSelection) && (
          <LockInSelectionPopup
            showPopup={openLockInSelection}
            hidePopup={hideLockinPopup}
            onSubmit={(overRidedata: any) => handlePopupSubmit(overRidedata)}
            loansArray={lockinPopupData}
            loanStage={loanStage}
            lockinValues={locks}
            loansResult={loansResult}
            loanDetails={loanDetails}
            loanId={loanId}
            isPostSubmit={hideLoanHeader}
            runFesEval={saveAndRunGuideLineEvaluation}
            rateSheetType={rateSheetTypeData}
            eligibleTakeOutPartnerResult={eligibleTakeOutPartnerResult}
            setCurrentSelectedPartner={setCurrentSelectedPartner}
            currentSelectedPartner={currentSelectedPartner}
            loansWithoutAssignedPartner={loansWithoutAssignedPartner}
            takeOutPartnerList={takeOutPartnersList}
            rateLockOptions={rateLockOptions}
            takeoutPartnerId={takeoutPartnerId}
            noLocks={noLocks}
            criticalTests={criticalTests}
            partnerWiseRateLockOptions={partnerWiseRateLockOptions}
          />
        )}
        {openWarning && (
          <WarningModal
            isOpen={openWarning.isOpen}
            handleClose={openWarning.handleClose}
            handleOptional={openWarning.handleOptional}
            primaryBtnName={openWarning.primaryBtnName}
            header={openWarning.header}
            body={openWarning.body}
          />
        )}
        {
          openConfirmation && (
            <ConfirmFormModal 
              isOpen={openConfirmation.isOpen}
              handleClose={openConfirmation.handleClose}
              primaryBtnName={openConfirmation.primaryBtnName}
              header={openConfirmation.header}
              body={openConfirmation.body}
              confirmCondition={openConfirmation.confirmCondition}
              footerStyles={openConfirmation.footerStyles}
              hideSecondaryBtn={openConfirmation.hideSecondaryBtn}
              successHandler={openConfirmation.successHandler}
              />
          )
        }
        {!hideLoanHeader && (
          <Grid container>
            <Grid
              container
              item
              xs={7}
              sm={6}
              md={5}
              lg={8}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={3} sm={2} md={3} lg={1}>
                <Button
                  variant="contained"
                  data-testid="delete-button"
                  color="primary"
                  startIcon={<KeyboardArrowLeftIcon />}
                  onClick={() => {
                    dispatch(resetAllStores());
                    dispatch(resetExcelUploadReducer());
                    dispatch(resetRetryRequestReducer());
                    setBackToOriginalOrgId();
                    if (excelData?.maintainExcelBundle) {
                      const newData = {
                        ...excelData,
                        ...{ maintainExcelBundle: false }
                      };

                      sessionStorage.setItem(
                        "excelUpload",
                        JSON.stringify(newData)
                      );
                      navigate("/createLoan");
                    } else if (getCookie("loanConverted")) {
                      removeCookie("loanConverted");
                      navigate(`/loans/list?tab=1`);
                    } else {
                      // history.goBack();
                      history.back();
                    }
                  }}
                  className={`${classes.headerPrimaryButton}`}
                >
                  <span className={classes.btnLabel}>Back</span>
                </Button>
              </Grid>
              <Grid
                item
                xs={9}
                sm={10}
                md={9}
                lg={8}
                className={classes.loanCreationTypography}
              >
                <Typography className="button-typography-wrapper-left">
                  {loanStage === LoanStage.fes
                    ? "Evaluate Toorak Guidelines"
                    : excelData?.maintainExcelBundle
                      ? "Loan Creation - Excel Upload"
                      : "Loan Creation - Manual"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={5}
              sm={6}
              md={7}
              lg={4}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.headerButtonWrapper}
            >
              <Button
                variant="contained"
                data-testid="delete-button"
                color="primary"
                startIcon={<DeleteOutlineRoundedIcon />}
                onClick={() => {
                  handleDelete();
                  // eslint-disable-next-line no-console
                }}
                className={`${classes.headerPrimaryButton}`}
              >
                <span className={classes.btnLabel}>Delete</span>
              </Button>
              <Button
                variant="contained"
                data-testid="save-button"
                color="primary"
                startIcon={<SaveOutlinedIcon />}
                data-id={loanType}
                onClick={() => {
                  if (loanStage === LoanStage.fes) {
                    saveAndRunGuideLineEvaluation();
                  } else {
                    handleSave();
                  }
                }}
                className={`${classes.headerPrimaryButton}`}
              >
                <span className={classes.btnLabel}>Save</span>
              </Button>
              <Tooltip
                title={
                  submitDisabled
                    ? "All the loan errors should be fixed before the loans can be submitted."
                    : ""
                }
              >
                <span>
                  <Button
                    variant="contained"
                    data-testid="submit-button"
                    color="secondary"
                    startIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <img alt="submit" src={submitButtonImage} />
                    }
                    onClick={() => {
                      validateAndSubmit();
                    }}
                    style={
                      submitDisabled || loaderCount > 0
                        ? { cursor: "not-allowed" }
                        : {}
                    }
                    className={`${classes.headerSecondaryButton}`}
                  >
                    <span className={classes.btnLabelEvaluate}>
                      {loanStage === LoanStage.fes
                        ? "Evaluate Guideline"
                        : "Submit"}
                    </span>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </AppBar>
      {/* {getLoan.loading ? <LinearProgressBar /> : null} */}
    </>
  );
};

