import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useBudgetStyles } from "../BudgetReview.style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { convertUSDToNumber, sanitizeCurrency } from "../../utils/formatChecks";
import {
  BudgetInfo,
  BudgetReviewStore
} from "../../stores/budgetReview/budgetReview.interface";
import { updateBudgetButtonInfo } from "../../stores/budgetReview/budgetReview.reducer";
// import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import { Grid } from "@mui/material";

export function BudgetMetaData() {
  const classes = useBudgetStyles();
  const dispatch = useDispatch();
  const [rehabBudget, setRehabBudget] = useState<string>("");
  const [totalBudget, setTotalBudget] = useState<string>("$0.00");
  const [financedBudgetAmount, setFinancedBudgetAmount] = useState<string>("");
  const [financedBudgetPercentage, setFinancedBudgetPercentage] =
    useState<number>(0);
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { BulkBudgetSum } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );
  useEffect(() => {
    if (!BulkBudgetSum.grandRehabBudget) {
      return setTotalBudget("$0.00");
    }
    setTotalBudget(sanitizeCurrency(`${BulkBudgetSum.grandRehabBudget}`));
  }, [BulkBudgetSum.grandRehabBudget]);

  useEffect(() => {
    let budgetAmount = loanDetails.bridgeLoanEconomics.payload.budgetAmount;
    let financedBudgetAmount =
      loanDetails.bridgeLoanEconomics.payload.financedBudgetAmount;
    if (!(budgetAmount && financedBudgetAmount)) return;
    let FinancedPercent: number =
      (convertUSDToNumber(financedBudgetAmount) /
        convertUSDToNumber(budgetAmount)) *
      100;
    setFinancedBudgetPercentage(FinancedPercent);
    setRehabBudget(budgetAmount);
    setFinancedBudgetAmount(financedBudgetAmount);
    let budgetButtonInfo: BudgetInfo = {
      rehabBudget: budgetAmount,
      financedBudgetAmount,
      financedBudgetPercentage: FinancedPercent,
      loanDetailsBudgetAmount: budgetAmount
    };
    dispatch(updateBudgetButtonInfo(budgetButtonInfo));
  }, [loanDetails, dispatch]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12} sm={8}>
        <Button
          variant="contained"
          data-testid="rehab-btn"
          className={classes.budgetButtons}
          color="info"
        >
          Submitted Total Rehab Budget ($)
          <br />
          {rehabBudget}
        </Button>
        <Button
          variant="contained"
          data-testid="split-btn"
          className={classes.budgetButtons}
          color="info"
        >
          Submitted Financed Budget ($)
          <br />
          {financedBudgetAmount}
        </Button>
        <Button
          variant="contained"
          data-testid="split-btn"
          className={classes.budgetButtons}
          color="info"
        >
          Submitted Financed Budget (%)
          <br />
          {`${parseFloat(financedBudgetPercentage.toFixed(4))}%`}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          data-testid="split-btn"
          className={classes.budgetButtons}
          color="info"
        >
          Calculated Total Budget ($)
          <br />
          {totalBudget}
        </Button>
        {/* {totalBudget !== rehabBudget && (
          <HtmlWhiteTooltip
            arrow
            placement="left"
            title={
              "Total Calculated Budget doesn't match with Total Rehab Budget"
            }
          >
            <WarningIcon
              style={{
                color: "orange",
                height: 24
              }}
            />
          </HtmlWhiteTooltip>
        )} */}
      </Grid>
    </Grid>
  );
}
