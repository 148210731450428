import { DrawStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";
import { getCookie } from "../../utils/cookies";
import { downloadExportedTape } from "../../create-loan/create-loan.action";

import { sanitizeCurrency } from "../../utils/formatChecks";
import { getRoles, isOrigUser, isRole } from "../../utils/AccessManagement";
import { DrawInternalNotesInterface, DrawObj } from "./drawRequest.interface";
import {
  getUsername,
  sanitizeStringToNum
} from "../../assetManagement/AssetManagement.utils";
import { apiUrlHost } from "../servicerOnboarding/servicerOnboarding.action";
import {
  getDocsByDrawId,
  initBankObj,
  setAdditionalLoanEconomicsData,
  setFieldsHistory,
  setRiskFactor,
  updateAssigneeList,
  updateBankList,
  updateBudgetCommentsRedux,
  updateBudgetReconcileRedux,
  updateBudgetReconcileReportsRedux,
  updateBudgetReviewDetails,
  updateDrawAvalBank,
  updateDrawCount,
  updateDrawData,
  updateDrawDiscussions,
  updateDrawHistory,
  updateDrawInternalNotes,
  updateDrawObj,
  updateDrawPropOptions,
  updateDrawSelectedProp,
  updateDrawStageHistory,
  updateElasticSearchBody,
  updateFinalApproval,
  updateInspectCommentsRedux,
  updateInspectionReviewRedux,
  updateLoanIds,
  updateProvisionData,
  updateReconcileItemHistoryRedux,
  updateSelectedBankObj
} from "./drawRequest.reducer";
import { getChatMeta } from "../commentsSection/comment.action";
import { getPartyId } from "../WaiverRequest/WaiverRequest.action";
import { postLineComment } from "../budgetReview/budgetReview.action";
import { createCommentThread1 } from "../Discussions/Discussions.action";
import { sortArrayByUpdatedOn } from "../../budget-review/BudgetReview.utils";
import { tabStatus } from "../../create-loan/ttf-review/constants";
import Cookies from "js-cookie";
import { taskNames } from "../tasks/Tasks.action";
import { getTaskDetails } from "../../network/apiService";
import { updatePurchaseFlags } from "../purchasedLoansDocReview/purchasedLoansDocReview.action";

export function getDrawRequestData(
  filterObj: any,
  isOriginator: boolean,
  page: number,
  size: number,
  cb?: any
): any {
  return async (dispatch: any) => {
    try {
      filterObj["offsetTime"] = new Date().getTimezoneOffset();
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/all?owner=${owner}&isOriginator=${isOriginator}&page=${page}&size=${size}&isTestParty=${getCookie(
        "isTestParty"
      )}`;
      const response = (await publicClient.post(url, filterObj)).data;
      // let formattedData = [...response.data];
      if (cb) {
        cb(response.totalDraws);
      } else {
        const UpdatedResponse = response.draws.map((item: any) => {
          if (!item.progress) {
            item.progress = " ";
          }
          return item;
        });

        dispatch(
          updateDrawData(UpdatedResponse.length > 0 ? UpdatedResponse : [])
        );
        dispatch(
          updateDrawCount(UpdatedResponse.length > 0 ? response.totalDraws : 0)
        );
      }

      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function getBudgetData(
  loanId: string,
  cb?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/ocr/budget-loan/${loanId}`;
      const response: any = (await publicClient.get(url)).data;
      console.log("toorakLoanId response",response);
      dispatch(updateBudgetReviewDetails(response));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      console.error(err);
    }
  };
}

export function getAllAssignee(): any {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/drawservice/assetmanagement/ammembers`;
      let response = (await publicClient.get(url)).data;
      response = response.filter(
        (item: any) => item.amMemberName.trim() !== ""
      );
      dispatch(updateAssigneeList(response?.length > 0 ? response : []));
    } catch (err) {
      console.error(err);
    }
  };
}

export async function updateAssigneTo(drawIds: any, value: any) {
  try {
    const owner = getCookie("org_id");
    const url = `${apiUrlHost}/drawservice/assetmanagement/assign?owner=${owner}`;
    const reqBody = {
      drawList: drawIds,
      assignedTo: value
    };
    const response = await publicClient.patch(url, reqBody);
    if (response.status === 200) {
      return Promise.resolve();
    }
  } catch (err) {
    console.error(err);
    return Promise.reject();
  }
  // };
}

export function bulkUpdateStatus(
  status: DrawStage,
  drawId: any,
  role: string,
  comment: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/update/stage?isOriginator=false&owner=${owner}`;
      const reqBody = {
        stage: status,
        comment: comment,
        role: role,
        username: getUsername(),
        drawId: drawId.join().split(",")
      };
      const response = await (await publicClient.put(url, reqBody)).data;
      if (response.status === 200) {
        return Promise.resolve();
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
    }
  };
}

export async function updateDrawDocTagStatus(
  drawId: any,
  dispatch: any,
  statuses: any
) {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/tagstatus`;
    const reqBody: any = {};
    statuses?.forEach((status: any) => {
      if (status.value !== undefined) {
        reqBody[status.key] = status.value;
      }
    });
    const response = await publicClient.patch(url, reqBody);
    dispatch(hideLoader());
  } catch (err) {
    dispatch(hideLoader());
  }
}

export function getElasticSearchQuery(val: string) {
  return {
    query: {
      bool: {
        should: [
          {
            multi_match: {
              query: val,
              fields: [
                "loan.loanDetailId.toorakLoanId",
                "loan.loanInfo.primaryLoanId"
              ],
              analyzer: "standard",
              type: "phrase_prefix"
            }
          }
        ]
      }
    },
    _source: ["*"],
    track_total_hits: true,
    sort: [
      {
        updatedOn: "desc"
      },
      "_score"
    ],
    size: 20,
    from: 0
  };
}

export function getProvisionsData(
  filterObj: any,
  isOriginator: boolean,
  page: number,
  size: number
): any {
  return async (dispatch: any) => {
    try {
      filterObj["offsetTime"] = new Date().getTimezoneOffset();
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/provisions/all?owner=${owner}&isOriginator=${isOriginator}&page=${page}&size=${size}`;
      const response = (await publicClient.post(url, filterObj)).data;
      dispatch(updateProvisionData(response));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function getAdditionalLoanEconomicsData(
  primaryLoanId: string,
  originatorId: string
) {
  if (!(originatorId && primaryLoanId)) return;
  return async (dispatch: any) => {
    try {
      // dispatch(showLoader());
      if (primaryLoanId.endsWith("#")) {
        // Replace '#' with '%23'
        primaryLoanId = primaryLoanId.slice(0, -1) + "%23";
      }
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${primaryLoanId}/loanEconomics?owner=${originatorId}&isOriginator=false`;
      const response = (await publicClient.get(url)).data;

      dispatch(setAdditionalLoanEconomicsData(response));
      // dispatch(hideLoader());
    } catch (err) {
      // dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function addProvision(
  originatorLoanId: string,
  toorakLoanId: string,
  provisionval: string,
  provisionId?: any,
  callback?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/drawservice/assetmanagement/provisions`;

      let reqBody: any = [];
      if (provisionId) {
        const editProvisionObj = {
          primaryLoanId: originatorLoanId,
          provision: provisionval,
          addedBy: getUsername(),
          toorakLoanId: toorakLoanId,
          provisionId: provisionId
        };
        reqBody.push(editProvisionObj);
      } else {
        const addProvisionObj = {
          primaryLoanId: originatorLoanId,
          provision: provisionval,
          addedBy: getUsername(),
          toorakLoanId: toorakLoanId
        };
        reqBody.push(addProvisionObj);
      }

      await publicClient.post(url, reqBody);
      if (callback) {
        callback(originatorLoanId);
      }
      dispatch(hideLoader());
    } catch (err) {
      console.log("error occured ", err);
      dispatch(hideLoader());
    }
  };
}

export function deleteProvision(
  provisionId: string,
  callBack: any,
  primaryLoanId?: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/drawservice/assetmanagement/provisions/${provisionId}`;
      await publicClient.delete(url);
      if (callBack) {
        callBack(primaryLoanId);
      }
      dispatch(hideLoader());
    } catch (err) {
      console.log("error occured ", err);
      dispatch(hideLoader());
    }
  };
}

export function getProvisionHistory(primaryLoanId: string, callback: any): any {
  return async (dispatch: any) => {
    try {
      // dispatch(showLoader());
      if (!primaryLoanId) {
        console.error("error occured ", "No proper primaryLoanId");
        return;
      }
      const url = `${apiUrlHost}/drawservice/assetmanagement/provisions/${primaryLoanId}`;
      const response = await publicClient.get(url);
      if (response && response?.data) {
        callback(response.data, primaryLoanId);
      }
      // dispatch(hideLoader());
    } catch (err) {
      console.error("error occured ", err);
      // dispatch(hideLoader());
    }
  };
}

export function updateProvision(primaryLoanId: string, value: string): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/update/provision?owner=${owner}&isOriginator=false`;
      const reqBody = {
        primaryLoanId: primaryLoanId,
        provision: value
      };
      await publicClient.put(url, reqBody);
      dispatch(hideLoader());
    } catch (err) {
      console.log("error occured ", err);
      dispatch(hideLoader());
    }
  };
}

export function updateElasticSearchResponse(val: any, callback?: any): any {
  return async (dispatch: any) => {
    try {
      if (val.trim() === "") {
        dispatch(updateElasticSearchBody([]));
        return;
      }
      const url = `${getConfig().apiUrl}/search/${
        getConfig().prefix
      }_loan_aggregate/_search`;
      const reqBody = getElasticSearchQuery(val);
      const response = await publicClient.post(url, reqBody);
      const resArray =
        response?.data?.response?.hits?.hits?.length > 0
          ? response.data.response.hits.hits
          : [];
      dispatch(updateElasticSearchBody(resArray));
      if (callback) {
        callback(resArray);
      }
    } catch (err) {
      console.error(err);
    }
  };
}

export const updateDrawProgressField = (
  drawIds: any,
  progress: string,
  callBack?: any
): any => {
  return async (dispatch: any) => {
    if (progress === "Select") return;
    dispatch(showLoader());
    try {
      const owner = getCookie("org_id");
      const originatorName = getCookie("organizationName");
      const personId = getCookie("person_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/update/progress?owner=${owner}&isOriginator=false&originatorName=${originatorName}&personId=${personId}`;
      const reqBody = {
        progress,
        drawIds
      };
      const response = (await publicClient.put(url, reqBody)).data;
      if (response) {
        callBack && callBack();
        dispatch(hideLoader());
      }
    } catch (e) {
      console.log(e);
      dispatch(hideLoader());
    }
  };
};

export function getAvailableLoanIds(isOriginator: boolean): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/loans?owner=${owner}&isOriginator=${isOriginator}`;
      const response = await publicClient.get(url);
      dispatch(updateLoanIds(response.data));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function getDrawDetailsbyLoanId(
  isOriginator: boolean,
  loanId: string,
  isChanged: boolean = true,
  drawAmountDetails: any = {},
  drawObj?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      if (loanId.endsWith("#")) {
        // Replace '#' with '%23'
        loanId = loanId.slice(0, -1) + "%23";
      }
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/loans/${loanId}?owner=${owner}&isOriginator=${isOriginator}`;
      const response = (await publicClient.get(url)).data;
      if (isChanged) {
        if (drawAmountDetails) {
          response.drawAmountDetails = drawAmountDetails;
        }
        if (drawObj) {
          response.gracePeriod = drawObj.gracePeriod;
          response.payoffRequestDate = drawObj.payoffRequestDate;
          response.loanStatus = drawObj.loanStatus;
        }
        dispatch(updateDrawObj(response));
        dispatch(updateDrawSelectedProp([]));
      } else {
        dispatch(updateDrawAvalBank(response));
      }
      dispatch(updateDrawPropOptions(response.propertyDetails));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function getDocsListbyDrawId(
  isOriginator: boolean,
  drawId: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/documents/getAll?owner=${owner}&isOriginator=${isOriginator}`;
      const response = (await publicClient.get(url)).data;
      let docList = response?.DocumentsList;
      if (docList && docList?.length > 0)
      {
        let isPrimaryAPITriggered = false;
        let primaryDocId:any;
        let inspectionDocPrimary = false;
        response.DocumentsList.forEach((item: any) => {
        const tagsArr = item?.tags;
        const tagCode = tagsArr?.[0]?.tag?.code;
        const confidenceScore = tagsArr?.[0]?.confidence_score;
        if (tagCode === "DDDC" && item.isPurchasePrimary === false && item?.assets?.length > 0 && confidenceScore > 0.9) {
          isPrimaryAPITriggered = true;
          primaryDocId = item.documentId;
        } else if (tagCode === "DDDC" && item.isPurchasePrimary === true) {
          inspectionDocPrimary = true;
        }
      });
      if (isPrimaryAPITriggered && !inspectionDocPrimary){
        try
        {
        const payload = {
          id: primaryDocId,
          isPurchasePrimary: true
        };
        updatePurchaseFlags([payload]);
        docList = docList?.map((item:any)=>{
          if (item.documentId === primaryDocId){
            item.isPurchasePrimary = true;
          }
          return item;
        });
       }catch(err){
        console.error(err);
       }
      }
     }
      dispatch(getDocsByDrawId(docList));
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export async function getInspectionReviewDetails(
  loanId: string,
  drawId: string,
  dispatch: any
) {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/loan/${loanId}/inspectionreview/draw/${drawId}`;
    const response = (await publicClient.get(url)).data;
    dispatch(updateInspectionReviewRedux(response));
    dispatch(hideLoader());
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
  }
}

export async function getAllBudgetInspectionComments(
  chatIds: any,
  lineItemsList: any,
  dispatch: any
) {
  try {
    dispatch(showLoader());
    const payloadChatIds = [...chatIds.budget, ...chatIds.inspection];
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/budgetreconcile/comments?limit=200&offset=0`;
    const reqBody = {
      chatIds: payloadChatIds
    };
    const response: any = (await publicClient.post(url, reqBody)).data || [];
    let budgetComments: any = [];
    let inspectionComments: any = [];
    response?.forEach((item: any) => {
      const { chatLines: chatRes, customers } = item;
      const userMap = new Map();
      customers?.forEach((cus: any) => {
        userMap.set(cus.partyId, cus);
      });
      chatRes?.forEach((key: any) => {
        const userObj = userMap.get(key.partyId);
        let userName = "";
        if (userObj?.firstName) {
          userName += userObj?.firstName + " ";
        }
        if (userObj?.middleName) {
          userName += userObj?.middleName + " ";
        }
        if (userObj?.lastName) {
          userName += userObj?.lastName;
        }
        key.createdBy = userName;
        const lineItem = lineItemsList.filter(
          (line: any) =>
            key.chatId === line.reconcileCommentDiscussionId ||
            key.chatId === line.commentDiscussionId
        )[0];
        key.lineItem = lineItem.item;
        if (chatIds.budget?.includes(key.chatId)) {
          budgetComments.push(key);
        }
        if (chatIds.inspection?.includes(key.chatId)) {
          inspectionComments.push(key);
        }
      });
    });
    inspectionComments?.forEach((item: any) => {
      item.type = "Inspection";
    });
    budgetComments?.forEach((item: any) => {
      item.type = "budget";
    });
    dispatch(updateInspectCommentsRedux(inspectionComments));
    dispatch(updateBudgetCommentsRedux(budgetComments));
    dispatch(hideLoader());
    return response;
  } catch (err) {
    dispatch(hideLoader());
    throw err;
  }
}

export async function getBudgetReconcileDetails(
  drawId: any,
  dispatch: any,
  isShowLoader: boolean = true
) {
  try {
    if (isShowLoader) {
      dispatch(showLoader());
    }
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/${drawId}/budgetreconcile`;
    const response = (await publicClient.get(url)).data;
    dispatch(updateBudgetReconcileRedux(response));
    if (isShowLoader) {
      dispatch(hideLoader());
    }
    return response;
  } catch (err) {
    if (isShowLoader) {
      dispatch(hideLoader());
    }
    console.error(err);
    return [];
  }
}

export async function redoReconcileHandler(drawId: any, dispatch: any) {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/budgetreconcile/redo/inspectionReview`;
    const payload = {
      drawId
    };
    const response = await publicClient.put(url, payload);
    dispatch(hideLoader());
    return response;
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
    return [];
  }
}

export async function getBudgetReconcileReports(
  drawId: any,
  toorakLoanId: any,
  dispatch: any
) {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/aggregate/${toorakLoanId}/reports/${drawId}`;
    const response = (await publicClient.get(url)).data;
    dispatch(updateBudgetReconcileReportsRedux(response));
    dispatch(hideLoader());
    return response;
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
    return {};
  }
}

export async function getReconcileItemHistory(
  reconcileItemsId: any,
  dispatch: any
) {
  try {
    dispatch(showLoader());
    // const url = `${apiUrlHost}/draw/budgetreconcile/${reconcileItemsId}`;
    // const response = (await publicClient.get(url)).data;
    // console.log(response);
    dispatch(
      updateReconcileItemHistoryRedux([
        {
          drawNumber: 2,
          amount: 750,
          completion: `50 ${reconcileItemsId}`,
          createdAt: "25-06-2024"
        },
        {
          drawNumber: 1,
          amount: 760,
          completion: "20",
          createdAt: "24-06-2024"
        }
      ])
    );
    dispatch(hideLoader());
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
  }
}

export async function updateIRPredictionStatus(
  reqBody: any,
  extractionSourceDocumentId: string,
  cb: any
) {
  const url = `${apiUrlHost}/ocr/extraction/${extractionSourceDocumentId}`;
  const resp = (await publicClient.patch(url, reqBody)).data;
  // dispatch(updateInspectionReviewRedux(resp));
  cb && cb(resp);
}

export const getBudgetReconcileCommentsList = async (drawId: string) => {
  const comments: any = (
    await getChatMeta("DRAW", drawId, "CHAT_TOPIC", "INSPECTION_REVIEW")
  ).data;
  return comments;
};

export async function updateBudgetReconcileButtonAction(
  drawId: string,
  payload: any,
  dispatch: any
) {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/budgetreconcile/${drawId}/comments`;
    const response = await publicClient.put(url, payload);
    dispatch(hideLoader());
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
  }
}

export async function updateBudgetApprovedAmount(
  drawId: string,
  payload: any,
  dispatch: any
) {
  try {
    // dispatch(showLoader());
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/budgetreconcile/${drawId}/updateApprovedLatestCompletion`;
    const response = await publicClient.patch(url, payload);
    // dispatch(hideLoader());
  } catch (err) {
    // dispatch(hideLoader());
    console.error(err);
  }
}

export async function startReconcileAPI(drawId: string) {
  try {
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/${drawId}/startreconcile`;
    const response = (await publicClient.put(url)).data;
    return Promise.resolve(response);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

export const getIRandBRHistory = async (reqBody: any) => {
  // return async (dispatch: any) => {
  try {
    const url = `${apiUrlHost}/taskservice/tasks/audit/fetch`;
    const response = (await publicClient.post(url, reqBody)).data;
    const sortedInspectionHistory = sortArrayByUpdatedOn(
      response[0]?.budgetReconcile
    );
    let returnObj: any = {
      sortedInspectionHistory,
      sortedBudgetReconcileHistory: []
    };
    if (response[0] && response[0].inspectionReview) {
      const sortedBudgetReconcileHistory = sortArrayByUpdatedOn(
        response[0].inspectionReview
      );
      returnObj = {
        ...returnObj,
        ...{ sortedBudgetReconcileHistory: sortedBudgetReconcileHistory }
      };
      // dispatch(updateBudgetApprovalHistory(sortedBudgetApprovalHistory));
    }

    return returnObj;
    // else {
    //   getTasks(
    //     dispatch,
    //     {
    //       resourceTypes: ["LOAN"],
    //       resourceIds: [loanId],
    //       taskNames: [
    //         taskNames.TAG,
    //         taskNames.SPLIT,
    //         taskNames.REVIEW,
    //         taskNames.RECONCILE,
    //         taskNames.BUDGET_REVIEW,
    //         taskNames.BUDGET_APPROVE
    //       ]
    //     },
    //     false,
    //     true
    //   );
    // }
    // dispatch(updateBudgetStatus(sortedBudgetHistory[0].taskStatus));
    // if (
    //   [
    //     budgetReviewStatusEnum.ReadyForBudgetReview,
    //     budgetReviewStatusEnum.InProgress
    //   ].includes(
    //     sortedBudgetHistory[0].taskStatus as budgetReviewStatusEnum
    //   ) &&
    //   ["UnAssigned", "", null].includes(
    //     sortedBudgetHistory[0].assigneePartyId
    //   )
    // ) {
    //   //assign to self
    //   let newTaskObj = await selfAssigneTask(
    //     sortedBudgetHistory,
    //     response[0]
    //   );
    //   sortedBudgetHistory.unshift(newTaskObj);
    // }
    // dispatch(updateBudgetHistory(sortedBudgetHistory));
  } catch (e) {
    console.error(e);
  }
  // };
};

export const createAndUpdateIRComments = async (
  commentId: string,
  drawId: string,
  commentText: string,
  title: string
) => {
  const { partyId } = getPartyId();
  const payload = {
    partyId,
    lineText: commentText,
    hasDocuments: false
  };
  let commentThreadId;
  try {
    if (commentId) {
      commentThreadId = commentId;
    } else {
      let res: any = await createCommentThread1(
        "DRAW",
        drawId,
        "CHAT_TOPIC",
        "INSPECTION_REVIEW",
        title
      );
      commentThreadId = res.id;
    }
    return await postLineComment(commentThreadId, payload);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

const taskNameToStatus: any = {
  budgetReconcile: "budgetReconcileStatus",
  inspectionReview: "inspectionReviewStatus"
};

export async function saveReviewAndReconcileStatus(
  drawId: string,
  payload: any,
  dispatch: any,
  operation?: any
) {
  try {
    dispatch(showLoader());
    const payloadData: any[] = [];
    const { partyId } = getPartyId();
    const firstName = Cookies.get("firstName");
    const lastName = Cookies.get("lastName");
    const statusesArr: any = [];
    payload?.forEach((item: any) => {
      const statusPayload: any = {};
      statusPayload.key = taskNameToStatus[item.name];
      statusPayload.value = item.status;
      statusesArr.push(statusPayload);
      payloadData.push({
        resourceId: drawId,
        resourceType: "DRAW",
        assignedTasks: [
          {
            taskStatus: item.status,
            assignedBy: firstName + " " + lastName,
            taskName: item.name,
            resourceId: item.resourceId || drawId,
            taskId: operation !== "add" && item.taskId,
            op: operation ? operation : "replace",
            updatedBy: partyId,
            comment: item.comment
          }
        ]
      });
    });
    updateDrawDocTagStatus(drawId, dispatch, statusesArr);
    const url = `${apiUrlHost}/taskservice/tasks`;
    const response = (await publicClient.patch(url, payloadData)).data;
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
    return Promise.reject(err);
  }
}

export async function updateInspectionReview(
  drawId: string,
  reqBody: any,
  dispatch: any
) {
  try {
    const url = `${apiUrlHost}/drawservice/assetmanagement/draw/${drawId}/inspectionreview`;
    const response = (await publicClient.patch(url, reqBody)).data;
    dispatch(updateInspectionReviewRedux(reqBody));
    return Promise.resolve(response);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

export const postDrawStageHistory: any = (
  drawId: string,
  stage: DrawStage,
  role: string,
  createdBy: string,
  comment: string = ""
) => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    try {
      const owner = getCookie("org_id");
      const originatorName = getCookie("organizationName");
      const personId = getCookie("person_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/update/stage?owner=${owner}&isOriginator=false&originatorName=${originatorName}&personId=${personId}`;

      const reqBody = {
        stage,
        comment,
        role,
        username: getUsername(),
        createdBy
      };
      const response = (await publicClient.put(url, reqBody)).data;
      if (response) {
        dispatch(updateDrawStageHistory(response.payload?.drawHistory));
        dispatch(hideLoader());
        return Promise.resolve("success");
      }
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      return Promise.reject("fail");
    }
  };
};

export const updateDrawDetails = (
  drawObj: any,
  selectedBankDetails: any,
  drawId: string,
  isOrig: boolean,
  Stage: DrawStage,
  createdBy: string,
  showL = true,
  isInspection?: boolean
): any => {
  return async (dispatch: any) => {
    if (showL) {
      dispatch(showLoader());
    }
    const {
      toorakLoanId,
      servicerName,
      servicerId,
      propertyDetails,
      prePurchaseDrawAmount,
      originatorId,
      loanType,
      drawType,
      financedBudgetAmount,
      drawNumber,
      drawAmountDetails,
      balanceToFinish,
      borrowerAddress,
      borrowerEntityName,
      primaryLoanId,
      // originalMaturityDate,
      // currentMaturityDate,
      maturityDate,
      nextPaymentDueDate,
      lastUpdatedDate,
      originatorName: origName
    } = drawObj;
    const {
      costOfInspection,
      drawAmount,
      edited,
      netFundsToBorrower,
      titleServiceFee,
      wireFee,
      wireReturnFee,
      latestApprovedAmount,
      comments
    } = drawAmountDetails;

    const owner = getCookie("org_id");
    // const originatorName = getCookie("organizationName");
    const personId = getCookie("person_id");
    let reviewStatus  = '';
    if (drawObj.budgetReviewStatus === ''){
      const payload = {
        resourceTypes: ["LOAN"],
        resourceIds: [toorakLoanId],
        taskNames: [
          taskNames.BUDGET_REVIEW
        ]
      }
      const response:any = await getTaskDetails(payload);
      reviewStatus = response?.data?.[0]?.assignedTasks?.[0]?.taskStatus;
    }
    const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}?isOriginator=${isOrig}&owner=${owner}&originatorName=${origName}&personId=${personId}&createdBy=${createdBy}`;

    const drawSaveObject: any = {
      balanceToFinish,
      toorakLoanId,
      propertyDetails,
      primaryLoanId,
      originatorId,
      originatorName: origName,
      loanExcelJson: "",
      maturityDate,
      // originalMaturityDate,
      // currentMaturityDate,
      nextPaymentDueDate,
      // loanState: "in-progress",
      drawAmountDetails: {
        costOfInspection: sanitizeStringToNum(costOfInspection),
        drawAmount: sanitizeStringToNum(drawAmount),
        edited: edited,
        netFundsToBorrower: sanitizeStringToNum(netFundsToBorrower),
        titleServiceFee: sanitizeStringToNum(titleServiceFee),
        wireFee: sanitizeStringToNum(wireFee),
        wireReturnFee: sanitizeStringToNum(wireReturnFee),
        latestApprovedAmount: sanitizeStringToNum(latestApprovedAmount),
        comments: comments
      },
      bankDetails: selectedBankDetails || initBankObj,

      servicerId,
      servicerName,
      drawType,
      loanType,
      stage: Stage,
      drawNumber,
      borrowerEntityName,

      borrowerAddress,
      financedBudgetAmount,
      prePurchaseDrawAmount,
      availableFinancedBudgetAmount: null,
      lastUpdatedDate
    };
    if (!latestApprovedAmount || latestApprovedAmount === "0.00") {
      drawSaveObject.drawAmountDetails.latestApprovedAmount =
        sanitizeStringToNum(drawAmount);
    } else {
      drawSaveObject.drawAmountDetails.latestApprovedAmount =
        sanitizeStringToNum(latestApprovedAmount);
    }
    if (drawObj.budgetReviewStatus === ''){
      drawSaveObject.budgetReviewStatus = reviewStatus;
    }
    if (
      Stage !== DrawStage.draft &&
      !(
        drawSaveObject.drawAmountDetails?.netFundsToBorrower &&
        drawSaveObject.drawAmountDetails?.drawAmount
      )
    ) {
      console.error(
        "netFundsToBorrower is missing",
        drawSaveObject.drawAmountDetails,
        netFundsToBorrower
      );
      dispatch(hideLoader());
      return Promise.reject("fail");
    }
    if (!isOrig) {
      drawSaveObject["username"] = getUsername();
    }
    if (Stage !== DrawStage.draft) {
      drawSaveObject["role"] = getRoles();
    }
    try {
      const response = (await publicClient.put(url, drawSaveObject)).data;
      if (Stage !== DrawStage.draft) {
        dispatch(
          updateDrawDetailsRedux(response.payload?.drawDetails, isInspection)
        );

        dispatch(formatFieldHistoryRedux(response.payload?.drawHistory));
      }
      sessionStorage.removeItem("newDrawCreated");
      dispatch(hideLoader());
      return Promise.resolve("success");
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      return Promise.reject("fail");
    }
  };
};

export function generateDrawId() {
  return new Promise((resolve, reject) => {
    const owner = getCookie("org_id");
    const username = getUsername();
    const url = `${apiUrlHost}/drawservice/assetmanagement/draws/create?owner=${owner}&isOriginator=true&username=${username}`;
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function deleteDrawById(drawId: string) {
  return new Promise((resolve, reject) => {
    const owner = getCookie("org_id");
    const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/delete?owner=${owner}&isOriginator=true`;
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function exportDrawDetails(
  selectedLoans: any,
  isOrig: boolean,
  createdBy: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const tempObj = {
        drawId: selectedLoans,
        offsetTime: new Date().getTimezoneOffset()
      };
      const owner = getCookie("org_id");
      const personId = getCookie("person_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/download?owner=${owner}&isOriginator=${isOrig}&personId=${personId}&createdBy=${createdBy}`;
      const response = await publicClient.post(url, tempObj);
      if (response) {
        downloadExportedTape(response.data);
        setTimeout(() => {
          dispatch(hideLoader());
        }, 300);
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

export function exportDiscussions(
  drawDiscussions: any,
  drawId: any,
  isOrig: boolean,
  tempDiscussion: any[],
  createdBy: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const personId = getCookie("person_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/discussions?owner=${owner}&isOriginator=${isOrig}&personId=${personId}&createdBy=${createdBy}`;
      const response = (await publicClient.post(url, drawDiscussions)).data;
      if (response) {
        tempDiscussion[drawId].discussions.unshift(response);
        // console.log(tempDiscussion);
        dispatch(updateDrawDiscussions(tempDiscussion));
        dispatch(hideLoader());
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}

export const postInternalNotes = (
  noteChat: any,
  drawId: any,
  tempInternalNotes: DrawInternalNotesInterface[]
): any => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      // const owner = getCookie("org_id");
      // const personId = getCookie("person_id");
      const reqBody = {
        text: noteChat.text,
        role: noteChat.role
      };
      const url = `${apiUrlHost}/drawservice/assetmanagement/${drawId}/internalnote?userName=${noteChat.userName}`;
      const response = (await publicClient.post(url, reqBody)).data;

      if (response) {
        tempInternalNotes.unshift(response);
        dispatch(updateDrawInternalNotes(tempInternalNotes));
      }
      dispatch(hideLoader());
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
};

export const getInternalNotes: any = (drawId: string) => {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/drawservice/assetmanagement/internalnote?drawId=${drawId}`;
      const response = (await publicClient.get(url)).data;
      let responseRev = [...response].reverse();

      if (response) {
        dispatch(updateDrawInternalNotes(responseRev));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const insertDrawDocs = (
  body: any,
  drawId: string,
  isOrig: boolean,
  createdBy: string
): any => {
  return async (dispatch: any) => {
    const owner = getCookie("org_id");
    const personId = getCookie("person_id");
    let url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/documents?owner=${owner}&isOriginator=${isOrig}&personId=${personId}&createdBy=${createdBy}`;
    try {
      const response = await publicClient.post(url, body);
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
      //upload is not success
    }
  };
};

const formatFieldHistoryRedux = (resp: any, isStage: boolean = false) => {
  return async (dispatch: any) => {
    let newds = {};
    Object.keys(resp).forEach((section: any) => {
      if (
        ["dramAmountFieldEditHistory", "bankDetailsFieldEditHistory"].includes(
          section
        )
      ) {
        resp[section].forEach((item: any) => {
          let fieldName = item.key;
          let fields =
            item.fields.length > 3 ? item.fields.slice(0, 3) : item.fields;
          fields.forEach((field: any) => {
            if (section === "dramAmountFieldEditHistory") {
              field.preValue = sanitizeCurrency(field.preValue);
              field.value = sanitizeCurrency(field.value);
            }
          });
          fields = fields.filter((field: any) => {
            return field?.preValue !== "" && field?.preValue !== "0";
          });
          let foo = { [fieldName]: fields };
          newds = { ...newds, ...foo };
        });
      }
    });
    dispatch(setFieldsHistory(newds));
    if (isStage) {
      dispatch(updateDrawStageHistory(resp.stageHistory));
    }
  };
};

export const getFieldHistory = (drawId: string, isOrig: boolean): any => {
  return async (dispatch: any) => {
    const owner = getCookie("org_id");

    let url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/history/all?owner=${owner}&isOriginator=${isOrig}`;
    try {
      const resp = (await publicClient.get(url)).data;
      //do mapping here, if required
      // let newds = {};
      dispatch(formatFieldHistoryRedux(resp, true));
    } catch (e) {
      console.error(e);
    }

    // .then((res: any) => resolve(res))
    // .catch(reject);
  };
};

export const downloadDrawDocs = (
  docIds: string[],
  drawId: string,
  isOrig: boolean,
  createdBy: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!docIds.length) return reject("No DocIds provided");
    const owner = getCookie("org_id");
    const personId = getCookie("person_id");
    let url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/documents/download?owner=${owner}&isOriginator=${isOrig}&personId=${personId}&createdBy=${createdBy}`;

    const body = {
      docId: docIds
    };

    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
};

export const deleteDrawDocs = (
  docIds: string[],
  drawId: string,
  isOrig: boolean,
  dontCallGetDocs?: boolean
): any => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const owner = getCookie("org_id");

    let url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/document?owner=${owner}&isOriginator=${isOrig}`;

    try {
      const response = await publicClient.delete(url, {
        data: { docId: docIds }
      });
      !dontCallGetDocs &&
        dispatch(getDocsByDrawId(response.data.payload?.drawDocuments));
      dispatch(hideLoader());
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
      //upload is not success
    }
  };
};

const updateDrawDetailsRedux = (response: any, isInspection?: boolean) => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    dispatch(updateDrawObj(response));
    dispatch(updateSelectedBankObj(response.bankDetails));

    // Dispatch action to set risk factor
    dispatch(setRiskFactor(response.riskFactor));

    // Check condition and dispatch action to update final approval
    if (response.finalApprovalNotRequired) {
      dispatch(
        updateFinalApproval(response.finalApprovalNotRequired.toLowerCase())
      );
    }
    let filDocsList = response.drawDocuments;
    if (isInspection) {
      filDocsList = response.drawDocuments?.filter(
        (item: any) =>
          item.isPurchasePrimary &&
          item.tags.some((tag: any) => tag.tag.code === "DDDC")
      );
    }
    dispatch(getDocsByDrawId(filDocsList));
    // const discussions =
    //   Object.keys(response?.drawDiscussions)?.length ||
    //   Array.isArray(response?.drawDiscussions)
    //     ? response?.drawDiscussions
    //     : [];
    // const drawDiscussionsRev = [...discussions]?.reverse();
    dispatch(updateDrawDiscussions(response.drawDiscussions));
    dispatch(hideLoader());
  };
};

export function getDrawDetailsByDrawId(
  drawId: string,
  isOrig: boolean,
  isInspection?: boolean
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}?owner=${owner}&isOriginator=${isOrig}`;
      const response = (await publicClient.get(url)).data;
      if (response) {
        dispatch(updateDrawDetailsRedux(response, isInspection));
        dispatch(hideLoader());
      }
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
    }
  };
}
export const getDrawHistory = (loanId: string): any => {
  const isOrig: boolean = isOrigUser();
  if (isOrig) return;
  return async (dispatch: any) => {
    //clearning previous data; AM-276
    dispatch(updateDrawHistory([]));
    const owner = getCookie("org_id");
    if (loanId.endsWith("#")) {
      // Replace '#' with '%23'
      loanId = loanId.slice(0, -1) + "%23";
    }
    const url = `${apiUrlHost}/drawservice/assetmanagement/draws/drawHistory/${loanId}?owner=${owner}&isOriginator=${isOrig}`;

    try {
      // Fetch data
      const resp = await publicClient.get(url);

      // Dispatch action to update draw history with fetched data
      dispatch(updateDrawHistory(resp.data));
    } catch (error) {
      // Handle error, if needed
      console.error("Error fetching draw history:", error);
    }
  };
};

export function updateIsFinalApprovalRequired(
  flag: string,
  drawId: string,
  isOrig: boolean
): any {
  return async (dispatch: any) => {
    const owner = getCookie("org_id");
    dispatch(showLoader());
    if (flag === "yes") {
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/setFinalApprovalNotRequired?owner=${owner}&isOriginator=${isOrig}`;
      // eslint-disable-next-line
      const response = await publicClient.get(url);
      dispatch(hideLoader());
    } else {
      const url = `${apiUrlHost}/drawservice/assetmanagement/draws/${drawId}/unsetFinalApprovalNotRequired?owner=${owner}&isOriginator=${isOrig}`;
      // eslint-disable-next-line
      const response = await publicClient.get(url);
      dispatch(hideLoader());
    }
    dispatch(updateFinalApproval(flag));
  };
}

export function createDrawFromExcel(cloudFilePathBucket: any): Promise<any> {
  const username = getUsername();
  let requestBody: any = {
    owner: getCookie("org_id"),
    cloudFilePath: {
      BucketName: cloudFilePathBucket.cloudFilePathBucket.BucketName,
      Bucketkey: cloudFilePathBucket.cloudFilePathBucket.Bucketkey
    },
    username: username
  };
  const url = `${getConfig().apiUrl}/aggregate/extractdraw/BulkDrawUpload`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getFilterOption(key: string) {
  const owner = getCookie("org_id");
  const isOriginator = isOrigUser();
  let url = `${
    getConfig().apiUrl
  }/drawservice/assetmanagement/draws/getValuesForColumn/${key}?owner=${owner}&isOriginator=${isOriginator}&isTestParty=${getCookie(
    "isTestParty"
  )}`;
  return publicClient.get(url);
}

export function deleteBankDetailsById(
  bankDetailId: number,
  drawObj: DrawObj
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const { borrowerEntityName, originatorId, primaryLoanId } = drawObj;
      const owner = getCookie("org_id");
      const url = `${apiUrlHost}/drawservice/assetmanagement/bankdetails/${bankDetailId}?owner=${owner}&isOriginator=true`;
      const response: any = bankDetailId
        ? await publicClient.delete(url, {
            data: { borrowerEntityName, originatorId, loanId: primaryLoanId }
          })
        : [];
      dispatch(updateBankList(response.data));
      // if (response?.data?.bankList?.length)
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}
