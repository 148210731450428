import {
  borrowerTypeDropDownValues,
  borrowerTypes,
  getLoanType
} from "../../constants";
import {
  foreignNationalOptions,
  yesNoDropdown
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { LoanSizerEnum } from "../../../frontend-sizer/FrontendSizerTemplate";
import { bridgeLoanFields } from "../../../create-loan/constants/loanFieldType";

const isRequired = (
  fieldName: any,
  borrowerInfo: any,
  borrowersData: any,
  loanStage: any
) => {
  const hasIndividualBorrower = borrowersData?.find(
    (ele: any) => ele.payload.isPrimaryBorrower
  );
  const firstEntityBorrowerId = borrowersData?.find(
    (ele: any) => ele.payload?.borrowerType === "Entity"
  )?.borrowerId;

  let required =
    (borrowerInfo?.isPrimaryBorrower &&
      !bridgeLoanFields[fieldName]?.optionalIn?.includes(loanStage)) ||
    (!hasIndividualBorrower &&
      !bridgeLoanFields[fieldName]?.optionalIn?.includes(loanStage));

  if (required && fieldName === "originalCreditScoreMedian") {
    if (borrowerInfo?.foreignNationalString === "Yes") {
      required = false;
    }
  }
  return required;
};

const borrowerType = {
  fieldName: "borrowerType",
  fieldLabel: "Borrower Type",
  backendKey: "loanUserType",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: borrowerTypeDropDownValues,
  colSpan: 4,
  isVisible: () => true,
  commentField: "loanUserType",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const isPrimaryBorrower = {
  fieldName: "isPrimaryBorrower",
  fieldLabel: "Primary Borrower",
  backendKey: "isPrimary",
  required: () => true,
  fieldType: () => "radioGroup",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Individual",
  commentField: "borrowerType",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const firstName = {
  fieldName: "firstName",
  backendKey: "firstName",
  fieldLabel: "First Name",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Individual",
  commentField: "customer.firstName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const lastName = {
  fieldName: "lastName",
  fieldLabel: "Last Name",
  backendKey: "lastName",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Individual",
  commentField: "customer.lastName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const einNumber = {
  fieldType: () => "string",
  fieldName: "eiNumber",
  fieldLabel: "EIN",
  required: () => true,
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Entity",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const ssNumber = {
  fieldType: () => "string",
  fieldName: "ssNumber",
  fieldLabel: "SSN",
  required: () => true,
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Entity",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const foreignNational = {
  fieldName: "foreignNationalString",
  fieldLabel: "Foreign National",
  backendKey: "foreignNationalString",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: yesNoDropdown.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Individual",
  commentField: "customer.foreignNationalString",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditScoreMedian = {
  fieldName: "originalCreditScoreMedian",
  backendKey: "originalCreditScoreMedian",
  fieldLabel: "Original Credit Score (Median)",
  required: (borrowerInfo: any) => {
    if (borrowerInfo?.foreignNationalString && borrowerInfo?.foreignNationalString !== "Yes") {
      return true;
    }
    return false;
  },
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType !== "Entity",
  commentField: "originalCreditScoreMedian",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditReportDate = {
  fieldName: "originalCreditReportDate",
  backendKey: "originalCreditScoreReportDate",
  fieldLabel: "Original Credit Report Date",
  required: (borrowerInfo: any, borrowersData: any, loanStage: any) =>
    isRequired("originalCreditReportDate", borrowerInfo, borrowersData, loanStage),
  fieldType: () => "date",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType !== "Entity",
  commentField: "originalCreditScoreReportDate",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowingEntityName = {
  fieldName: "borrowingEntityName",
  fieldLabel: "Borrower Entity Name",
  backendKey: "accountName",
  required: (borrowerInfo: any, borrowersData: any, loanStage: any) =>
    isRequired("borrowingEntityName", borrowerInfo, borrowersData, loanStage),
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Entity",
  commentField: "customer.accountName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrBorrowingEntityName = {
  fieldName: "borrowingEntityName",
  fieldLabel: "Borrower Entity Name",
  backendKey: "accountName",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType === "Entity",
  commentField: "customer.accountName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerEmail = {
  fieldName: "borrowerEmail",
  fieldLabel: "Borrower Email",
  backendKey: "email",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: `customer.contactList[0].email`,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const liquidityCurrency = {
  fieldName: "liquidityCurrency",
  fieldLabel: "Liquidity Currency",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const billingAddress = {
  fieldName: "billingAddress",
  fieldLabel: "Borrower Full Billing Address",
  backendKey: "addressLine1",
  required: (borrowerInfo: any, borrowersData: any, loanStage: any) =>
    isRequired("billingAddress", borrowerInfo, borrowersData, loanStage),
  fieldType: (loanType: string) => "autocomplete",
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: `customer.addressList[0].addressLine1`,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const billingPhoneNumber = {
  fieldName: "billingPhoneNumber",
  fieldLabel: "Borrower Billing Phone Number",
  backendKey: "contactNumber",
  required: () => true,
  fieldType: (loanType: string) => "phone",
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: `customer.contactList[0].contactNumber`,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerExperience = {
  fieldName: "borrowerExperience",
  fieldLabel: "Borrower Experience",
  backendKey: "experience",
  required: (borrowerInfo: any, borrowersData: any, loanStage: any) =>
    isRequired("borrowerExperience", borrowerInfo, borrowersData, loanStage),
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  getTypoErrorMsg: () => "Please enter a valid number",
  commentField: "experience",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerGUCExperience = {
  fieldName: "borrowerGUCExperience",
  fieldLabel: "Borrower GUC Experience",
  required: () => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: () => true,
  commentField: "borrowerGUCExperience",
  getTypoErrorMsg: () => "Please enter a valid number",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const heavyRehabExperience = {
  fieldName: "heavyRehabExperience",
  fieldLabel: "Heavy Rehab Experience",
  getTypoErrorMsg: () => "Please enter a valid number",
  required: () => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: () => true,
  commentField: "heavyRehabExperience",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerCreditScore = {
  fieldName: "borrowerCreditScore",
  backendKey: "originalCreditScoreMedian",
  fieldLabel: "Credit score- Actual",
  fieldId: "fes-CreditscoreActual",
  required: (borrowerInfo: any) => {
    if (borrowerInfo?.foreignNationalString && borrowerInfo?.foreignNationalString !== "Yes") {
      return true;
    }
    return false;
  },
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: "originalCreditScoreMedian",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeForeignNationString = {
  fieldName: "foreignNationalString",
  fieldLabel: "Foreign National Flag(Borrower or Guarantor)",
  fieldId: "fes-foreignNationalFlag",
  ruleFieldName: "foreignNationalString",
  fieldType: () => "dropDown",
  extraData: foreignNationalOptions.map((item: any) => ({
    value: item,
    label: item
  })),
  isVisible: () => true,
  commentField: "customer.foreignNationalString",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrForeignNationString = {
  fieldName: "foreignNationalString",
  fieldLabel: "Foreign National Flag",
  backendKey: "foreignNationalString",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: foreignNationalOptions.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType !== "Entity",
  commentField: "customer.foreignNationalString",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrFirstTimeHomeBuyer = {
  fieldName: "firstTimeHomeBuyer",
  fieldLabel: "First Time Home Buyer",
  backendKey: "firstTimeHomeBuyer",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: yesNoDropdown.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: (data: any) => data?.borrowerType !== "Entity",
  commentField: "customer.firstTimeHomeBuyer",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const inState = {
  fieldName: "inState",
  fieldLabel: "In State",
  backendKey: "inState",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: yesNoDropdown.map((item: any) => ({
    value: item === "Yes" ? "Y" : "N",
    label: item
  })),
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: "inState",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const fesBorrowerFields = [
  { ...borrowerCreditScore },
  {
    ...dscrForeignNationString,
    isVisible: () => true,
    fieldLabel: "Foreign National Flag(Borrower or Guarantor)",
    required: () => true
  },
  {
    ...borrowerType,
    required: () => false,
    isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
      isEvaluationPanelVisible
  },
  dscrFirstTimeHomeBuyer

];

const BridgeFes14Fields = [
  borrowerExperience,
  {
    ...foreignNational,
    required: () => false,
    dropDownData: yesNoDropdown.map((item: any) => ({
      value: item,
      label: item
    }))
  },
  {
    ...borrowerCreditScore,
    fieldLabel: "Original Credit Score (Median)"
  }
];

const BridgeFesMultifamilyFields = [
  borrowerExperience,
  {
    ...foreignNational,
    required: () => false,
    dropDownData: yesNoDropdown.map((item: any) => ({
      value: item,
      label: item
    }))
  },
  { ...borrowerCreditScore, required: () => false, fieldLabel: "Original Credit Score (Median)" }
];

const DSCRPrePostFields = [
  borrowerType,
  dscrBorrowingEntityName,
  isPrimaryBorrower,
  firstName,
  lastName,
  dscrForeignNationString,
  originalCreditScoreMedian,
  originalCreditReportDate,
  borrowerEmail,
  billingAddress,
  { ...billingPhoneNumber, required: () => false },
  dscrFirstTimeHomeBuyer
  // borrowerExperience
];

const DSCRPostFields = [
  borrowerType,
  dscrBorrowingEntityName,
  isPrimaryBorrower,
  firstName,
  lastName,
  dscrForeignNationString,
  originalCreditScoreMedian,
  originalCreditReportDate,
  { ...borrowerEmail, required: () => true },
  { ...billingAddress, required: () => false },
  billingPhoneNumber,
  dscrFirstTimeHomeBuyer
  // borrowerExperience
];

const bridgePreFields = [
  borrowerType,
  borrowingEntityName,
  isPrimaryBorrower,
  firstName,
  lastName,
  foreignNational,
  originalCreditScoreMedian,
  originalCreditReportDate,
  { ...borrowerEmail, required: () => false },
  billingAddress,
  { ...billingPhoneNumber, required: () => false }
];

const bridgePostFields = [
  borrowerType,
  borrowingEntityName,
  isPrimaryBorrower,
  firstName,
  lastName,
  foreignNational,
  originalCreditScoreMedian,
  originalCreditReportDate,
  { ...borrowerEmail, required: () => true },
  { ...billingAddress, required: () => false },
  billingPhoneNumber
];

const getBridgeFESFields = (toorakProduct = null) => {
  if (toorakProduct === LoanSizerEnum.oneFourFamily) {
    return [...BridgeFes14Fields, inState];
  }
  if (toorakProduct === LoanSizerEnum.multifamilyMixedUse) {
    return BridgeFesMultifamilyFields;
  }
  return [
    borrowerGUCExperience,
    heavyRehabExperience,
    {
      ...foreignNational,
      required: () => false,
      dropDownData: yesNoDropdown.map((item: any) => ({
        value: item,
        label: item
      }))
    },
    { ...borrowerCreditScore, fieldLabel: "Borrower Credit Score (Median)" }
  ];
};

const getBridgePreFields = (toorakProduct = null, loanStage = "") => {
  if (toorakProduct === LoanSizerEnum.groundUp) {
    return [...bridgePreFields, borrowerGUCExperience, heavyRehabExperience];
  } else {
    return [...bridgePreFields, borrowerExperience];
  }
};

const getBridgePostFields = (toorakProduct = null, loanStage = "") => {
  if (toorakProduct === LoanSizerEnum.groundUp) {
    return [...bridgePostFields, borrowerGUCExperience, heavyRehabExperience];
  } else {
    return [...bridgePostFields, borrowerExperience];
  }
};

export const getBorrowerInformationFields = (
  loanStage: string,
  loanType?: string,
  toorakProduct?: any
) => {
  switch (loanStage) {
    case "fes":
      return loanType === "InvestorDSCR"
        ? fesBorrowerFields
        : getBridgeFESFields(toorakProduct);
    case "pre":
      return loanType === "InvestorDSCR"
        ? DSCRPrePostFields
        : getBridgePreFields(toorakProduct, loanStage);
    case "post":
      return loanType === "InvestorDSCR"
        ? DSCRPostFields
        : getBridgePostFields(toorakProduct, loanStage);
    default:
      break;
  }
};
